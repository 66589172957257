import { Box, Typography, Grid, Card, makeStyles, withStyles,Hidden, CardMedia, CardContent } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const useStyles = makeStyles((theme)=>({
	borderRadius: {
		borderRadius: 0,
	},
	paper: {
		padding: '16px',
		border: `1px solid ${theme.palette.border}`,
		height: '100%',
		boxShadow: 'none',
	},
	borderBottom: {
    padding: "0.6rem 1rem",
		borderBottom: `1px solid ${theme.palette.border}`,
		'&:first-child': {
			backgroundColor: '#F7F8FA',
		}
	}
}))

export const StyledSkeleton = withStyles((theme) => ({
  root: {
    background: 'linear-gradient(90deg, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%)',
  },
	text: {
		borderRadius: 0,
	}
}))(Skeleton);

export default function LoadingScreen({view}){
  const classes = useStyles();

	const count = [1,2,3,4,5,6];

	const Paginate_Button_Card_Content = () => {
    return (
      <Box style={{position:'relative',height:'100%'}}>
				<StyledSkeleton width='6rem' height='3rem' animation='wave' />
        <StyledSkeleton width='70%' animation='wave'/>
        <StyledSkeleton width='80%' animation='wave'/>
				<Box style={{height:'1rem'}}/>
        <StyledSkeleton width='90%' animation='wave' style={{borderRadius:'5px',height:'10px'}}/>
				<Box style={{height:'1rem'}}/>
				<StyledSkeleton width='5rem' height='3rem' animation='wave' style={{position:'absolute',bottom:0}} />
      </Box>
    )
  }
	const OCR_Button_Card_Content = () => {
    return (
      <Box style={{position:'relative',height:'100%'}}>
				<StyledSkeleton width='6rem' height='3rem' animation='wave' />
        <StyledSkeleton width='70%' animation='wave'/>
				<Box style={{height:'1rem'}}/>
        <StyledSkeleton width='90%' animation='wave' style={{borderRadius:'5px',height:'10px'}}/>
				<Box style={{height:'1rem'}}/>
				<StyledSkeleton width='5rem' height='3rem' animation='wave' style={{position:'absolute',bottom:0}} />
      </Box>
    )
  }
	const Index_Button_Card_Content = () => {
    return (
      <Box style={{position:'relative',height:'100%'}}>
				<StyledSkeleton width='6rem' height='3rem' animation='wave' />
				<StyledSkeleton width='80%' animation='wave'/>
				<StyledSkeleton width='80%' animation='wave'/>
				<StyledSkeleton width='80%' animation='wave'/>
				<StyledSkeleton width='80%' animation='wave'/>
				<Box style={{height:'1rem'}}/>
				<StyledSkeleton width='5rem' height='3rem' animation='wave' style={{position:'absolute',bottom:0}} />
      </Box>
    )
  }
	const Add_Collaborator_Button_Content = () => {
		const collaborator = [1,2,3,4];
		return (
      <Box>
				<Grid container spacing={1}>
					<Grid item lg={3} md={3} sm={3} xs={3}>
						<Skeleton variant="circle" width={40} height={40} animation='wave' />
					</Grid>
					<Grid item lg={9} md={9} sm={9} xs={9}>
						<StyledSkeleton width='5rem' height='2rem' animation='wave'/>
						<StyledSkeleton width='2rem' height='1rem' animation='wave'/>
					</Grid>
				</Grid>
				<Box style={{height:'0.6rem'}}/>
				<StyledSkeleton width='6rem' height='1.5rem' animation='wave'/>
				<Box style={{height:'0.6rem'}}/>
				<div style={{display: 'flex'}}>
					{
						collaborator.map((num)=>{
							return (
								<Skeleton variant="circle" width={30} height={30} animation='wave' key={num} style={{border: '1.5px solid white'}} />
							)
						})
					}
						<Skeleton variant="circle" width={30} height={30} animation='wave' style={{border: '1.5px solid white', marginLeft:'8px'}} />
				</div>
				
      </Box>
    )
	}
	const ButtonCardsSkeleton = [<Paginate_Button_Card_Content/>, <OCR_Button_Card_Content/>, <Index_Button_Card_Content/>, <Add_Collaborator_Button_Content/>	];

	return(
		<>
			<Typography variant="h2">
				<StyledSkeleton width='8rem' animation='wave' />
			</Typography>
			<Box style={{height:'2rem'}}></Box>
			<Grid container spacing={2}>
        {
          ButtonCardsSkeleton.map((card,index)=>{ 
            return (
              <Grid item lg={3} md={3} sm={3} xs={6} key={index}>
                <Card className={classes.paper}>
									{card}
								</Card>
              </Grid>
            )
          })
        }
      </Grid>
			<Box style={{height:'4rem'}}/>
			<div style={{display:'flex',gap:'10px'}}>
				<StyledSkeleton width='4rem' height='2.5rem' animation='wave' />
				<StyledSkeleton width='4rem' height='2.5rem' animation='wave' />
				<StyledSkeleton width='4rem' height='2.5rem' animation='wave' />
			</div>
			<Box style={{height:'1rem'}}/>
			<div style={{display:'flex',justifyContent:'space-between'}}>
				<div>
					<StyledSkeleton width='9rem' height='4rem' animation='wave' />
				</div>
				<div style={{display:'flex',gap:'15px'}}>
					<StyledSkeleton width='7rem' height='4rem' animation='wave' />
					<StyledSkeleton width='4rem' height='4rem' animation='wave' />
					<StyledSkeleton width='4rem' height='4rem' animation='wave' />
				</div>
			</div>
			<Box style={{height:'1rem'}}/>
			{
				view === 'list' ? (
					<Card style={{border: `1px solid #E5E5E5`,boxShadow:'none'}}>
						{
							count.map((num)=>{
								return (
									<Grid container className={classes.borderBottom} key={num} spacing={2}>
										<Grid item lg={5} md={5} sm={5} xs={7}>
											<Typography component='div'><StyledSkeleton width='80%' animation='wave' /></Typography>
										</Grid>
										<Hidden xsDown>
											<Grid item lg={1} md={1} sm={1} xs={1}>
												<Typography component='div'><StyledSkeleton width='80%' animation='wave' /></Typography>
											</Grid>
											<Grid item lg={1} md={1} sm={1} xs={1}>
												<Typography component='div'><StyledSkeleton width='80%' animation='wave' /></Typography>
											</Grid>
										</Hidden>
										<Grid item lg={2} md={2} sm={2} xs={4}>
											<Typography component='div'><StyledSkeleton width='80%' animation='wave' /></Typography>
										</Grid>
										<Hidden xsDown>
											<Grid item lg={2} md={2} sm={2} xs={3}>
												<Typography component='div'><StyledSkeleton width='80%' animation='wave' /></Typography>
											</Grid>
										</Hidden>
										<Grid item lg={1} md={1} sm={1} xs={1}>
											<Typography component='div'><StyledSkeleton width='80%' animation='wave' /></Typography>
										</Grid>
									</Grid>
								)
							})
						}
					</Card>

				) : (
					<Grid container spacing={3}>
						{
							count.map((num)=>{
								return (
									<Grid item lg={2} md={3} sm={3} xs={4} key={num}>
										<Card>
											<CardMedia
												style={{
													width: "50%",
													margin: "auto",
													padding: '12%'
												}}
											>
												<StyledSkeleton variant="rect" width='100%' height='80px' animation='wave' />
											</CardMedia>
											<CardContent
												style={{ padding: "15px", borderTop: "1px solid #eeeeee" }}
											>
												<Grid container style={{height:'100%'}}>
													<Grid item lg={10} md={10} sm={10} xs={10}>
														<Typography>
															<StyledSkeleton width='80%' animation='wave' />
														</Typography>
													</Grid>
													<Grid item lg={2} md={2} sm={2} xs={2}>
														<Typography>
															<StyledSkeleton width='80%' animation='wave' />
														</Typography>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
								)
							})
						}
        	</Grid>
				)
			}
		</>
	)
}