import React, { useEffect, useRef, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: '10px',
    backgroundColor: '#2a21ff17',
  },
  page: {
    border: '2px solid #DBDBDB',
  },
  openedPage: {
    border: `2px solid ${theme.palette.primary.main}`
  }
}));

export const Page = ({ page, dimensions, updateDimensions, scale, isPageOpen, index }) => {
  const classes = useStyles();
  const canvasRef = useRef(null);
  const [width, setWidth] = useState((dimensions && dimensions.width) || 0);
  const [height, setHeight] = useState((dimensions && dimensions.height) || 0);

  useEffect(() => {
    const renderPage = async (p) => {
      const _page = await p;
      if (_page) {
        const context = canvasRef.current?.getContext('2d');
        const viewport = _page.getViewport({ scale });

        setWidth(viewport.width);
        setHeight(viewport.height);

        if (context) {
          await _page.render({
            canvasContext: canvasRef.current?.getContext('2d'),
            viewport,
          }).promise;

          const newDimensions = {
            width: viewport.width,
            height: viewport.height,
          };
          
          updateDimensions(newDimensions);
        }
      }
    };

    renderPage(page);
  }, [page, updateDimensions]);

  return (
    <div className={isPageOpen ? classes.pageContainer : null} style={!(scale === 1) ? {padding:'10px',width:'fit-content',margin:'auto'}:null} >
      <canvas ref={canvasRef} width={width} height={height} className={!(scale === 1) ? isPageOpen?classes.openedPage:classes.page : null} />
      { !(scale === 1) && (
        <Typography style={!isPageOpen?{color:'#B4B4B4'}:{color:'#4A3BFF'}}>{`Page ${index+1}`}</Typography>
      )}
    </div>
  );
};
