import React from "react";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormattedMessage from "features/common/TranslatedMessage";
import isEmpty from "lodash/isEmpty";

function Invite({ profiles, setStep, setSelectedProfile, selectedProfile }) {
  const onSubmit = () => {
    setStep(2);
  };

  return (
    <Box p="2rem 0" textAlign="center">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={12} md={6}>
          <Box p="0rem 2rem">
            <Typography variant="h4" gutterBottom>
              Find Collaborators
            </Typography>

            <Autocomplete
              id="combo-box-demo"
              fullWidth
              autoComplete={true}
              options={profiles}
              onChange={(event, value) => {
                if(value){
                  setSelectedProfile(value);
                }
                else{
                  setSelectedProfile({})
                }
              }}
              onInputChange={(event) => {}}
              getOptionLabel={(option) => option.user.first_name}
              renderOption={(option, state) => {
                return (
                  <React.Fragment>
                    {option.user.first_name} {option.user.last_name}
                    <Box display="inline-block" width="1rem"></Box>
                    <Typography variant="caption">
                      {option.phone_number}
                    </Typography>
                  </React.Fragment>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={<FormattedMessage id="pages.intro.search" />}
                  variant="outlined"
                />
              )}
            />

            <Box height="2rem"></Box>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onSubmit}
            >
              {isEmpty(selectedProfile) ? (
                <FormattedMessage id="buttons.create" />
              ) : (
                <FormattedMessage id="buttons.search" />
              )}
            </Button>
          </Box>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </Box>
  );
}

export default Invite;
