import { combineReducers } from "redux";
import loginReducer from "slices/loginSlice";
import questionReducer from "slices/questionSlice";
import disputeReducer from "slices/disputeSlice";
import profileReducer from "slices/profileSlice";
import answerReducer from "slices/answerSlice";
import userReducer from "slices/userSlice";
import caseReducer from "slices/casesSlice";
import companiesReducer from "slices/companySlice";
import chatReducer from "slices/chatSlice";
import groupReducer from "slices/groupSlice";
import chatGroupReducer from "slices/chatGroupsSlice";
import companyFlowReducer from "slices/companyFlowsSlice";
import documentSetReducer from "slices/documentSetSlice";

const appReducer = combineReducers({
  login: loginReducer,
  questions: questionReducer,
  disputes: disputeReducer,
  profile: profileReducer,
  user: userReducer,
  answers: answerReducer,
  cases: caseReducer,
  companies: companiesReducer,
  companyFlows: companyFlowReducer,
  chat: chatReducer,
  groups: groupReducer,
  chatgroup: chatGroupReducer,
  documentSet: documentSetReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
