import { Button, Grid, makeStyles, Typography, TextField, FormControl, Select, MenuItem, InputLabel, InputAdornment } from "@material-ui/core";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import SearchIcon from "@material-ui/icons/Search";
import Files from "./Files";
import { getFiles, getFolder, getFolders, findSearchByFileName, findSearchByPagination, findSearchInFiles, getOCRableFiles, DownloadFile, getNumberofPaginatedFiles, getFolderAndFilesById} from "../api/FileApi";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { debounceSearch } from "utils";
import { useQuery, useInfiniteQuery } from "react-query";
import { getDocumentSet } from "slices/documentSetSlice";
import { PdfViewingModal } from "./editModals/PdfViewingModal";
import FileExplorer from "./FileExplorer";
import LoadingScreen from "features/LoadingScreen/LoadingScreenDashboard";
import { useSnackbar } from "contexts/SnackbarContext";
import { ERROR, ERROR_TITLE } from "constants/appConstants";
import { useWorkspace } from "contexts/WorkspaceContext";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
    padding: '0 3rem',
    zIndex: 1,
    height: '97vh',
    overflowY: 'auto',
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
      padding: '0 2rem',
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
    },
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  searchWrap: {
    display: 'flex',
    flexDirection: 'column',
    width:'500px'
  },
  suggestionWrap: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    width: '500px',
    boxShadow: '0px 0px 8px #ddd',
    borderRadius: '6px',
    overflowY: 'scroll',
    maxHeight: '300px',
    zIndex: '1',
  },
  search: {
    padding: '8px 10px',
    borderBottom: '1px solid #A9A9A9',
    "&:hover": {
      backgroundColor: '#efefef',
      cursor: 'pointer'
    }
  },
  selectWrap: {
    display: 'flex',
    flexDirection:'row',
    alignItems: 'flex-end'
  },
  emsd: {
    color: 'yellow',
  }
}));

export default function NewFileContainer() {
  let { folder_id, workspace_id } = useParams();
  folder_id = parseInt(folder_id);
  workspace_id= parseInt(workspace_id);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  // const suggestionsContainerRef = useRef(null);
  const {showSnackbar} = useSnackbar();
  const { setWorkspaceId, workspaceList } = useWorkspace();

  const profile = useSelector((state) => state.profile, shallowEqual);
  const documentSet = useSelector((state) => state.documentSet, shallowEqual);
  const [workspaceName, setWorkspaceName] = useState('');
  const [documentSetData, setDocumentSetData] = useState();
  // const [documentSetId, setDocumentSetId] = useState(documentSet?.documentSet?.results?.[0]?.id);

  const [parent_folder, setParent_folder] = useState();
  const [allFiles, setAllFiles] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [allRoutes, setAllRoutes] = useState([]);

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);

  let sessionStoragePageNo = parseInt(sessionStorage.getItem("page_no"));
  let sessionStoragePageSize = parseInt(sessionStorage.getItem("page_size"));
  const pageSizeOptions = [10, 50, 100, 250, 500];
  const [currentPage, setcurrentPage] = useState(
    sessionStoragePageNo ? sessionStoragePageNo : 1
  );
  const [totalPagesFiles, setTotalPagesFiles] = useState(1);
  const [totalPagesFolders, setTotalPagesFolders] = useState(1);
  const [hideFiles, setHideFiles] = useState(false);
  const [hideFolders, setHideFolders] = useState(false);
  const [pageSize, setPageSize] = useState(
    sessionStoragePageSize
      ? pageSizeOptions.includes(sessionStoragePageSize)
        ? sessionStoragePageSize
        : 50
      : 50
  );
  const [allPagesList, setAllPagesList] = useState([]);
  const [pagesList, setPagesList] = useState([]);
  const maxPagesShown = 5;
  const [rootFolder,setRootFolder] = useState();
  const [openDonePopup,setOpenDonePopup] = useState(false);
  const [paginationProgress,setPaginationProgress] = useState({});
  const [filesAndFoldersData, setFilesAndFoldersData] = useState({});
  // const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);
  // const [searchText , setSearchText] = useState('');
  // const [searchResults, setSearchResults] = useState([]);
  // const [searchType,setSearchType] = useState('byFileName');
  // const [highlighting, setHighlighting] = useState({});
  // const [startPageNumber , setStartPageNumber ] = useState(0);
  // const [endPageNumber , setEndPageNumber ] = useState(50);
  // const [searchValidtionError, setSearchValidationError] = useState(null);

  // const [pdfUrl, setPdfUrl] = useState('');
  // const [showPdfModal, setShowPdfModal]= useState(false);

  let [OCRableFiles,setOCRableFiles] = useState();

  const handlePageChange = (page) => {
    sessionStorage.setItem("page_no", page);
    setcurrentPage(page);
  };
  const handleSizeChange = (size) => {
    handlePageChange(1);
    sessionStorage.setItem("page_size", size);
    setPageSize(size);
  };

  // const { data, hasNextPage, fetchNextPage } = useInfiniteQuery( ["search-data"], ({ pageParam = 50, pageParam2= 100  }) => findSearchInFiles(searchText, documentSetId, pageParam, pageParam2),
  //   {
  //     getNextPageParam: (lastPage, pages, pageParam2) => {
  //       // console.log(lastPage, pages, 'pagesInTab' , pageParam2)
  //       // setStartPageNumber(pageParam2);
  //       // setEndPageNumber(pageParam2 + 50)
  //       // const maxPages = lastPage?.data?.total_results; // 147-97 = 50
  //       // const nextPage= pages?.length + 1;
  //       // console.log(startPageNumber,endPageNumber, maxPages,nextPage)
  //       // return endPageNumber <= maxPages ? nextPage : undefined;
  //     },
  //     enabled : false,
  //   }
  // );
  useEffect(()=>{
    setWorkspaceId(workspace_id);
    localStorage.setItem('workspaceId',workspace_id);
  },[]);

  useEffect(() => {
    let totalPages =
      totalPagesFiles > totalPagesFolders ? totalPagesFiles : totalPagesFolders;

    var foo = [];
    let middle = Math.floor(maxPagesShown / 2);
    for (let i = currentPage - middle; i < currentPage; i++) {
      if (i > 0) foo.push(i);
    }
    for (let i = currentPage; i <= totalPages; i++) {
      if (foo.length < maxPagesShown) foo.push(i);
      else break;
    }
    setPagesList(foo);
    var bar = [];
    for (var i = 1; i <= totalPages; i++) {
      bar.push(i);
    }
    setAllPagesList(bar);
    if (!bar.includes(currentPage) && allFiles && allFolders) {
      setcurrentPage(1);
      }
    if (totalPages > totalPagesFiles && currentPage > totalPagesFiles) setHideFiles(true);
    else setHideFiles(false);
    if (totalPages > totalPagesFolders && currentPage > totalPagesFolders) setHideFolders(true);
    else setHideFolders(false);
  }, [totalPagesFiles, totalPagesFolders,currentPage]);

  let states= {
    sorting: 'name',
    view: 'list',
    filter: 'allType'
  }

  if(window.sessionStorage.states)
      states= JSON.parse(window.sessionStorage.states);
  else
    window.sessionStorage.setItem('states', JSON.stringify(states));

  const [currentSorting, setcurrentSorting] = useState(states.sorting);
  const [currentView, setcurrentView] = useState(states.view);
  const [currentFilter, setcurrentFilter] = useState(states.filter);
  const [filteredFiles, setfilteredFiles] = useState([]);

  const reload = () => {
    setLoading1(true);
    setLoading2(true);
    setLoading3(true);
    setLoading4(true);
    setAllFiles([]);
    setAllFolders([]);

    getFilesAndFoldersDirectory();
    getFilesandFoldersData(currentFilter,currentSorting);

    getFolder(folder_id).then((response) => {
      setParent_folder(response.data);
      if (Object.keys(response.data).length > 0) {
        let routes = getParent(response.data);
        routes = routes.reverse();
        setAllRoutes(routes);
        setLoading3(false);
      } else {
        setAllRoutes([]);
        setLoading3(false);
      }
    })
    .catch((error)=>{
      if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    })   

    getOCRableFiles(workspace_id)
    .then((response)=>{
      setOCRableFiles(response?.data)
    })
    .catch((err)=>{
      console.log('Error while getting OCRable files: ',err);
    })
    getNumberofPaginatedFiles(workspace_id)
    .then((response)=>{
      setPaginationProgress(response?.data);
    })
    .catch((error)=>{
      console.log('Error while getting Paginated files: ',error);
    })
  };

  const getParent = (parent) => {
    let arr = [];
    if (!parent.is_root)
      arr.push({ nameToShowUser: parent.nameToShowUser, id: parent.id });
    if (parent.parent_folder) {
      let t = getParent(parent.parent_folder);
      t.map((item) => {
        arr.push(item);
        return 0;
      });
    }
    return arr;
  };

  const getRootFolder = (id) => {
    getFolder(id)
    .then((response)=>{
      setRootFolder(response.data);
      setLoading4(false);
    })
    .catch((error)=>{
      if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    })
  }

  function Sorting(files,folders) {
    if (currentSorting === 'name') {
      files.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        return nameA.localeCompare(nameB, "en", { numeric: true });
      });
      folders.sort(function (a, b) {
        var nameA = a.nameToShowUser.toUpperCase();
        var nameB = b.nameToShowUser.toUpperCase();
        return nameA.localeCompare(nameB, "en", { numeric: true });
      });
    } else if (currentSorting === 'size') {
      files.sort((a, b) => {
        return a.size - b.size;
      });
      folders.sort((a, b) => {
        return a.size - b.size;
      });
    } else {
      files.sort((a, b) => {
        return new Date(b.modified) - new Date(a.modified);
      });
      folders.sort((a, b) => {
        return new Date(b.modified) - new Date(a.modified);
      });
    }
  }

  function Filter(files) {
    let tempFiles=[];
    if (currentFilter === "pdf") {
      tempFiles = files.filter((file) => {
        return file.type === "application/pdf";
      });
    } else if (currentFilter === "word") {
      tempFiles = files.filter((file) => {
        return (
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      });
    } else if (currentFilter === "excel") {
      tempFiles = files.filter((file) => {
        return (
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      });
    } else if (currentFilter === "image") {
      tempFiles = files.filter((file) => {
        return (
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/webp"
        );
      });
    } else {
      tempFiles = files.filter(() => true);
    }
    setfilteredFiles(tempFiles);
    Sorting(tempFiles,[]);
  }

  useEffect(()=>{
    Sorting(filteredFiles,allFolders);
    setfilteredFiles([...filteredFiles]);
  },[currentSorting])
  useEffect(()=>{
    Filter(allFiles);
  },[currentFilter])

  useEffect(() => {
    if(allFiles.length === 0)
      dispatch(getDocumentSet());
  }, [allFiles,dispatch])
  // useEffect(() => {
  //   dispatch(getDocumentSet());
  // }, [folder_id,dispatch])
  
  useEffect(()=>{
    if(!documentSet.loading && workspaceList){
      const workspace = workspaceList.filter((workspace)=> workspace.id===workspace_id)?.[0];
      setDocumentSetData(workspace);
      getRootFolder(workspace?.root_folder?.id );
    }  
  },[documentSet,workspaceList])

  useEffect(() => {
    reload();
  }, [folder_id, currentPage, pageSize]);

  const getFilesAndFoldersDirectory = async () => {
    try {
      const response = await getFolderAndFilesById(workspace_id);
      setFilesAndFoldersData(response.data);
      setWorkspaceName(response.data.data.name);
    } catch (error) {
      if (
          error?.response?.status !== 0 &&
          error?.response?.status !== 404 &&
          error?.response?.status !== 500 &&
          error?.response?.status !== 401
      ) {
          showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    }
  };

  const getFilesandFoldersData = (filter,sort) => {
    getFiles(folder_id, currentPage, pageSize, filter, sort).then((res) => {
      let data = res.data.results;
      let totalPages;
      if (res.data.count) totalPages = Math.ceil(res.data.count / pageSize);
      else totalPages = 1;
      setTotalPagesFiles(totalPages);
      setAllFiles(res.data.results);
      setfilteredFiles(res.data.results);
      // Filter(res.data.results);
      setLoading1(false);
    })
    .catch((error)=>{
      if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    })
    getFolders(folder_id, currentPage, pageSize, sort).then((res) => {
      let data = res.data.results;
      let totalPages;
      if (res.data.count) totalPages = Math.ceil(res.data.count / pageSize);
      else totalPages = 1;
      setTotalPagesFolders(totalPages);
      // Sorting([],res.data.results);
      setAllFolders(res.data.results);
      setLoading2(false);
    })
    .catch((error)=>{
      if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    })
  }

//   useEffect(() => {
//     document.addEventListener('click', handleClickOutside);
//     return () => {
//         document.removeEventListener('click', handleClickOutside);
//     }
// }, []);

  // useEffect(() => {
  //   if(data && searchType === 'byFileContent'){
  //     setHighlighting(data?.pages?.[0]?.data?.highlighting);
  //     setSearchResults(data?.pages?.[0]?.data?.results);
  //   }
  // },[data])

// let fetching = false;
// const onScroll = async (event) => {
//   const { scrollHeight, scrollTop, clientHeight } = event?.target;
//   if (!fetching && (scrollHeight - scrollTop <= clientHeight*1.5)) {
//     fetching = true;
//     await fetchNextPage();
//     fetching = false;
//   }
// };

  // function handleClickOutside(e) {
  //   if (suggestionsContainerRef.current && !suggestionsContainerRef.current.contains(e.target)) {
  //       setIsSuggestionsOpen(false);
  //   }
  // }

  const [isRootFolderStamped,setRootFolderStamped] = useState(false);
  useEffect(()=>{
    if(parent_folder?.is_root )
      setRootFolderStamped(parent_folder.is_stamped);
  },[parent_folder])

  // const handleSearch = (value, searchType) => { 
  //   setIsSuggestionsOpen(true);
  //   setSearchText(value);
  //   if(documentSet?.documentSet?.results?.[0]?.id)
  //     fetchSearchData(value, searchType);
  // }

  // const handleSelectSearchType = (event) => {
  //   setSearchType(event.target.value)
  // }

  // useEffect(()=>{
  //   if(documentSetId){
  //     fetchSearchData(searchText,searchType)
  //   }
  // },[searchType, searchText])

  // const fetchSearchData = (value, searchType) => {
  //   let updatedSearchValueByFile = value.replaceAll(/[^\w\s]/gi, "");
  //   if(updatedSearchValueByFile?.trim()?.length === 0) return;

  //   let updatedValue = value.replaceAll(/[^\w\s]/gi, "-");
  //   if(searchType === 'byFileName'){
  //     findSearchByFileName(updatedSearchValueByFile, documentSetId, startPageNumber, endPageNumber)
  //     .then((res)=>{
  //       setSearchResults(res.data.results);
  //     })
  //     .catch((error)=>{
  //       if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
  //         if(error?.response?.status === 204){
  //           setSearchValidationError(error?.response?.data?.error);
  //         }
  //         else{
  //           alert(error?.response?.data?.error);
  //         }
  //       }
  //     })
  //   }else if(searchType === 'byPage'){
  //     findSearchByPagination(updatedValue)
  //     .then((res)=>{
  //       setSearchResults(res.data);
  //     })
  //     .catch((error)=>{
  //       if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
  //         if(error?.response?.status === 400){
  //           setSearchValidationError(error?.response?.data?.error);
  //         }
  //         else{
  //           alert(error?.response?.data?.error);
  //         }
  //       }
  //     })
  //   }else {
  //     findSearchInFiles(updatedSearchValueByFile, documentSetId, startPageNumber, endPageNumber)
  //     .then((res)=>{
  //       setSearchResults(res.data.results);
  //       setHighlighting(res.data.highlighting)
  //     })
  //     .catch((error)=>{
  //       if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
  //         if(error?.response?.status === 204){
  //           setSearchValidationError(error?.response?.data?.error);
  //         }
  //         else{
  //           alert(error?.response?.data?.error);
  //         }
  //       }
  //     })
  //   }
  // }


  // const handlePdfClick = async (fileId, page_number) => {
  //     try{
  //       const res = await DownloadFile(fileId)
  //       setPdfUrl(res.data.url+`#page=${page_number}`);
  //       setShowPdfModal(true);
  //     }catch(err){
  //       console.log(err)
  //     }
  //   };

  // function handleClickOnSearch(fileId, page_number){
  //   if(fileId){
  //     handlePdfClick(fileId, page_number);
  //   }
  // }

  // const handleChangeOnSearch = (event) => {
  //   let searchValue = event.target.value;
  //   setSearchResults([]);
  //   setSearchValidationError(null);
  //   if(searchValue === ''){
  //     setIsSuggestionsOpen(false);
  //   }
  //   else{
  //     optimisedSearchUsingDebounce(searchValue, searchType)
  //   }
  // }

  //usecallback provides memoized callback 
  // const optimisedSearchUsingDebounce = useCallback(debounceSearch(handleSearch), [])
  return (
    <Grid container wrap="nowrap" justify="center">
      <Grid item lg={2} md={2} sm={1} xs={1}>
        <FileExplorer filesAndFoldersData={filesAndFoldersData} handlePageChange={handlePageChange}/>
      </Grid>
      <Grid item lg={10} md={10} sm={11} xs={11} className={classes.root}>
        {/* <div className={classes.headerWrapper}>
          <div className={classes.selectWrap}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">File Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchType}
                label="Age"
                onChange={(event)=>handleSelectSearchType(event)}
              >
                <MenuItem value={'byFileName'}>By File Name</MenuItem>
                <MenuItem value={'byPage'}>By Page</MenuItem>
                <MenuItem value={'byFileContent'}>By File Content </MenuItem>
              </Select>
            </FormControl>
            <div >
              <div className={classes.searchWrap}>
                <TextField 
                  type="text"
                  placeholder="Enter To Search"
                  onChange={handleChangeOnSearch}
                  // onFocus={()=>setIsSuggestionsOpen(true)}
                  // onClick={setIsSuggestionsOpen(true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchResults?.length !== 0 ? <div>{searchResults?.length} result(s)</div> : null}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
             {isSuggestionsOpen && (searchResults || searchValidtionError) ? 
                searchResults.length !== 0 ? 
                <div className={classes.suggestionWrap} ref={suggestionsContainerRef} onScroll={onScroll}>
                  {searchType === 'byFileName' && searchResults?.map((result)=>{
                    return(
                      <div className={classes.search} onClick={()=> handleClickOnSearch(result.file_id,1)}>{result.file_name}</div>
                    )
                  })}
                  {searchType === 'byPage'&& searchResults.length !== 0  &&
                    <Link target="_blank" to={{pathname: `${searchResults.link}`}}>
                      <div className={classes.search}>{searchResults.file_name}</div>
                    </Link>
                  }
                  {searchType === 'byFileContent' && 
                    Object?.entries(highlighting)?.map(([hightlightId, highlight]) => {
                      return searchResults?.map((result) => {
                      if(result.id === hightlightId){
                        return(
                          <div onClick={()=> handleClickOnSearch(result.file_id,result?.page_number?.[0])}>
                            <div style={{color: 'red'}}>{result.file_name}</div>
                            <div className={classes.search} dangerouslySetInnerHTML={{__html: highlight.page_text}}></div>
                          </div>
                        )
                      }
                    })
                  })}
                </div>
               : searchValidtionError ? 
                <div className={classes.suggestionWrap} style={{color:'red',padding:'8px 10px',width:'480px'}}>{searchValidtionError}</div>
               : <div className={classes.suggestionWrap} style={{padding:'8px 10px',width:'480px'}}>No Results Found</div>
               : null
              }
            </div>
          </div>
          <div>.</div>
        </div> */}
        {loading1 || loading2 || loading3 || loading4? (
          // <CircularCenteredLoader/>
          <LoadingScreen
            view={states.view}
          />
        ) : (
          // <Files
          //   folder_id={folder_id}
          //   allFiles={[]}
          //   allFolders={[]}
          //   reload={reload}
          //   profile={profile.profile}
          //   parent_folder={parent_folder}
          //   allRoutesLength={allRoutes.length}
          // />
          <Files
            folder_id={folder_id}
            allFiles={hideFiles ? [] : allFiles}
            filteredFiles={hideFiles ? [] : filteredFiles}
            allFolders={hideFolders ? [] : allFolders}
            reload={reload}
            profile={profile.profile}
            parent_folder={parent_folder}
            allRoutes={allRoutes}
            totalPages={totalPagesFiles > totalPagesFolders ? totalPagesFiles : totalPagesFolders}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            pageSizeOptions={pageSizeOptions}
            pagesList={pagesList}
            handleSizeChange={handleSizeChange}
            pageSize={pageSize}
            allPagesList={allPagesList}
            isRootFolderStamped={isRootFolderStamped}
            rootFolder={rootFolder}
            openDonePopup={openDonePopup}
            setOpenDonePopup={setOpenDonePopup}
            currentSorting={currentSorting}
            setcurrentSorting={setcurrentSorting}
            currentView={currentView}
            setcurrentView={setcurrentView}
            currentFilter={currentFilter}
            setcurrentFilter={setcurrentFilter}
            states={states}
            paginationProgress={paginationProgress}
            setPaginationProgress={setPaginationProgress}
            OCRableFiles={OCRableFiles}
            setOCRableFiles={setOCRableFiles}
            documentSetData={documentSetData}
            workspace_id={workspace_id}
            workspaceNameGiven={workspaceName}
            refreshDirectories={getFilesAndFoldersDirectory}
            refreshFilesAndFolders={getFilesandFoldersData}
          />
        )}
      </Grid>
      {/* {showPdfModal &&
        <PdfViewingModal  
          open={showPdfModal} 
          dismiss={() => setShowPdfModal(false)}
          pdfUrl={pdfUrl}
          // handlePdfClick={handlePdfClick}
        />
      } */}
    </Grid>
  );
}
