import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';  
import StampingPaginate from '../images/paginate-images/StampingPaginate.png'
import ContinousAcrossAllBundles from '../images/paginate-images/ContinousAcrossAllBundles.png';
import PaginateFromOne from '../images/paginate-images/PaginateFromOne.png';
import ContinousPerBundle from '../images/paginate-images/ContinousPerBundle.png';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
  paper: {
    borderRadius: 0,
    minWidth: '400px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '280px',
    },
  },
  button: {
    padding: '0.75rem 1.5rem',
  },
  paginationModalWrap: {
    display: 'flex',
    gap: theme.spacing(2),
    overflowY: 'auto',
    maxHeight: '60vh',
    justifyContent: 'center',
    ['@media (max-width: 840px)']: {
      flexWrap: 'wrap',
    },
  }, 
  paginationContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  paginateBoxTitle: {
    color: '#000',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    marginLeft: '16px',
  },
  paginateBoxSubTitle: {
    color: '#696871',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    marginTop: '6px',
    marginLeft: '16px',
    marginBottom: '40px',
  },
  paginateBoxWrap: {
    border: '1px solid #D9D9D9',
    cursor: 'pointer',
    width: '22%',
    [theme.breakpoints.down('md')]: {
      width: '25%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '36%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    },
  },
  paginateBtn: {
    background: '#4A3BFF',
    width: '228px',
    height: '52px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color:  '#FFF',
    fontSize: '18px',
    fontWeight: '500',
    cursor: 'pointer',
  },
  paginateBtnWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
  },
  selectedPaginateBoxWrap: {
    cursor: 'pointer',
    background:' #4A3BFF',
    color:  '#FFF',
    width: '22%',
    border: '1px solid #D9D9D9',
    [theme.breakpoints.down('md')]: {
      width: '25%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '36%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    },
  },
  selectedPaginateBoxTitle: {
    color:  '#FFF',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    marginLeft: '16px',
  },
  selectedPaginateBoxSubTitle: {
    color:  '#FFF',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    marginTop: '6px',
    marginLeft: '16px',
    marginBottom: '40px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontFamily: 'Satoshi-Bold',
  },
  subTitle: {
    color: '#696871',
    fontSize: '1.1rem'
  }
}))

export default function PaginationChildModal({
  title,
  subTitle,
  handleStamp_stamp,
  handleStamp_paginate_from_1,
  handleStamp_continuous_per_bundle,
  handleStamp_continuous_across_all_bundles
}) {
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = React.useState(null); // Track the selected item

    const PAGINATION_TYPES = [
        {
            title: 'Stamping',
            subTitle: 'Achieve consistent labeling with our feature: Folder/File/Page number (A/1/1) format for easy organization.',
            imgPath: StampingPaginate,
            onClick: handleStamp_stamp,
        },
        {
            title: 'Paginate From 1',
            subTitle: 'Enjoy organized files with seamless pagination, where each file starts with the number 1 for easy navigation.',
            imgPath: PaginateFromOne,
            onClick: handleStamp_paginate_from_1,
        },
        {
            title: 'Continuous per bundle',
            subTitle: 'Streamline file organization within sub-folders using bundled numbering. Sequentially number files within each sub-folder, maintaining clarity across sets.',
            imgPath: ContinousPerBundle,
            onClick: handleStamp_continuous_per_bundle,
        },
        {
            title: 'Continuous across all bundles',
            subTitle: 'Experience effortless file organization with automatic numbering from 1 to x, regardless of folder placement.',
            imgPath: ContinousAcrossAllBundles,
            onClick: handleStamp_continuous_across_all_bundles,
        }
    ]

    // Function to handle item click and update the selected item
    const handleItemClick = (index) => {
        setSelectedItem(index);
    };

    return (
      <div className={classes.paginationContainer}>
        <div style={{textAlign: 'center'}}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.subTitle}>{subTitle}</Typography>
        </div>
        <div style={{height: '2rem'}}></div>
        <div className={classes.paginationModalWrap}>
          {PAGINATION_TYPES.map((paginateBox, index) => {
            return(
              <div 
                key={index}
                onClick={() => handleItemClick(index)}
                className={index === selectedItem ? classes.selectedPaginateBoxWrap : classes.paginateBoxWrap}
              >
                <img src ={paginateBox.imgPath} alt={paginateBox.title} width='100%'/>
                <div className={index === selectedItem ? classes.selectedPaginateBoxTitle : classes.paginateBoxTitle}>{paginateBox.title}</div>
                <div className={index === selectedItem ? classes.selectedPaginateBoxSubTitle : classes.paginateBoxSubTitle}>{paginateBox.subTitle}</div>
              </div>
            )
          })}
        </div>
        <div className={classes.paginateBtnWrap} onClick={PAGINATION_TYPES?.[selectedItem]?.onClick}>
          <div className={classes.paginateBtn}>Continue</div>
        </div>
      </div>
    );
}