import { Button, Grid, makeStyles, Typography, TextField, FormControl, Select, MenuItem, InputLabel, InputAdornment } from "@material-ui/core";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Add } from "@material-ui/icons";
import CustomTable from "shared/CustomTable";
import EmptyWorkspaceIcon from "images/EmptyWorkspace.png";
import { getWorkspaces } from "api/FileApi";
import LoadingScreen from "features/LoadingScreen/LoadingScreenDashboard";
import { useWorkspace } from "contexts/WorkspaceContext";
import WorkspaceLoadingScreen from "features/LoadingScreen/WorkspaceLoadingScreen";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "2rem",
        [theme.breakpoints.down("sm")]: {
          marginTop: "1.5rem",
        },
        [theme.breakpoints.down("xs")]: {
          marginTop: "1rem",
        },
    },
    workspaceTitle: {
        fontSize: '24px',
        fontWeight: '700',
        lineHeight:' 100%' /* 24px */
    },
    workspaceHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    customtablewrapper: {
        marginTop: '25px'
    }
}));

export default function Workspace() {
    const classes = useStyles();
    const history = useHistory();
		const { setWorkspaceList, setWorkspaceId } = useWorkspace();

    const [workspaces, setWorkspaces] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getWorkspacesData()
    }, [])

    const getWorkspacesData = async () => {
        try {
          const res = await getWorkspaces();
          setWorkspaces(res.data.results);
			setWorkspaceList(res?.data?.results);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };      

    const handleWorkspaceClick = (item) => {
			setWorkspaceId(item.id);
        history.push(`/folder/${item?.root_folder.id}/${item.id}`);
    }

    // Render loading div when data is still loading
    if (isLoading) {
        return (
            <Grid container wrap="nowrap" justify="center">
                <Grid item lg={10} md={10} sm={11} xs={11} className={classes.root}>
                    <div className="loading-div">
                        {/* You can customize your loading indicator here */}
                        <WorkspaceLoadingScreen/>
                    </div>
                </Grid>
            </Grid>
        );
    }
    
    return(
        <Grid container wrap="nowrap" justify="center">
            <Grid item lg={10} md={10} sm={11} xs={11} className={classes.root}>
                <div className={classes.customtablewrapper}>
                    <CustomTable 
                        headers={["Name", "Size", "Members", "Owner", "Modified on"]} 
                        isEmptyData={true}
                        iconImg={EmptyWorkspaceIcon}
                        data={workspaces}
                        handleWorkspaceClick={handleWorkspaceClick}
                        getWorkspacesData={getWorkspacesData}
												setWorkspaces={setWorkspaces}
												setWorkspaceList={setWorkspaceList}
                    />
                </div>
            </Grid>
        </Grid>
    )
}