import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";

export const initialState = {
  loading: false,
  hasErrors: false,
  documentSet: {},
  errors: {},
};

const documentSetSlice = createSlice({
  name: "documentSet",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.documentSet = payload;
      state.loading = false;
      state.hasErrors = false;
      state.errors = {};
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errors = payload;
    },
  },
});

export const { setProgress, setSucessfull, setError } = documentSetSlice.actions;

export default documentSetSlice.reducer;

export function getDocumentSet() {
    return async (dispatch) => {
        dispatch(setProgress());
        instance({
          method: "get",
          url: "/api/workspace/",
        }).then(
          (response) => {
            console.log(response.data);
            dispatch(setSucessfull(response.data));
            return response.data;
          },
          (error) => {
            console.log(error);
            // console.log(error.request.status);
            // console.log(error.message)
            // console.log(error.request.response)
            dispatch(setError());
          }
        );
      };
}

