import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import plusImage from "images/plusrounded.svg";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { UploadFiles, UploadFolders } from "../api/FileApi";
import LinearProgressWithLabel from "shared/LinearProgressWithLabel";

async function myCustomFileGetter(event) {
  const files = [];
  const fileList = event.dataTransfer
    ? event.dataTransfer.files
    : event.target.files;

  for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    files.push(file);
  }
  return files;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    position: "absolute",
    left: "50%",
    top: "45%",
    "-webkit-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      top: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
      width: "95%",
    },
  },
  firstText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  selected: {
    background: "#F2F2F2",
    padding: "0.3rem 1.3rem",
  },
  unselected: {
    padding: "0.3rem 1.3rem",
    cursor: "pointer",
  },
  choiceText: {
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    color: "#4F4F4F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  fieldsText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    marginTop: "0.7rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.3rem",
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  inputfield: {
    width: "100%",
    height: "3rem",
    background: "#E0E0E0",
    color: "#000000",
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    "&:focus": {
      outline: "none !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    paddingLeft:'10px'
  },
  dropbox: {
    width: "100%",
    background: "#E0E0E0",
    color: "#000000",
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    padding: "1rem 2px",
    textAlign: "center",
    paddingLeft: '10px',
    cursor: 'pointer'
  },
  alertSelected: {
    padding: "0.3rem",
    background: "#E0E0E0",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  alertUnSelected: {
    padding: "0.3rem",
    cursor: "pointer",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  cancel: {
    textDecoration: "underline",
    fontSize: "20px",
    fontWeight: "400",
    color: "#2F80ED",
    lineHeight: "36px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      lineHeight: "26px",
    },
  },
  add: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#FFFFFF",
    lineHeight: "36px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  addbtn: {
    background: "#2F80ED",
    marginLeft: "3rem",
    width: "120px",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      marginLeft: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90px",
      marginLeft: "1rem",
    },
  },
  box: {
    padding: "0.6rem 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  container: {
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  scroll: {
    overflowY: "auto",
    maxHeight: "20rem",
    cursor: "all-scroll",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "15rem",
    },
  },
  notify: {
    fontSize: "20px",
    color: "#4F4F4F",
    lineHeight: "36px",
    fontFamily: "Roboto",
    textAlign: "center",
    marginTop: "10px",
    textTransform: "capitalize",
  },
  alertOnHeading: {
    fontSize: 8,
    lineHeight: "14px",
    fontWeight: 600,
    color: "white",
    fontFamily: `'Inter',sans-serif`,
  },
  alertOnText: {
    fontSize: 8,
    lineHeight: "14px",
    fontWeight: 300,
    color: "white",
    fontFamily: `'Inter',sans-serif`,
  },
  uploadingInfo: {
    color: "#4F4F4F",
    fontWeigt: "500",
    fontFamily: `'Inter',sans-serif`,
    fontSize: "14px",
    wordBreak: "break-all",
  },
}));

export default function UploadFolder({
  openmodal,
  handleCloseModal,
  folder_id,
  handleCloseModalRefresh,
}) {
  const classes = useStyles();
  const [choice, setChoice] = useState("details");
  const [folderName, setFolderName] = useState("");
  const [uploadedFolderName, setUploadedFolderName] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadingFileName, setUploadingFileName] = useState('');

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    getFilesFromEvent: (event) => myCustomFileGetter(event),
  });
  const [filesPreview, setFilesPreview] = useState(false);

  const [progress,setProgress]= useState(0);
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setFilesPreview(true);
      let t = acceptedFiles[0].webkitRelativePath.split("/");
      setUploadedFolderName(t[0]);
      setFolderName((prev) => {
        if(prev.trim() === '') 
          return t[0];
        else
          return prev;
      });
    }
  }, [acceptedFiles]);

  const handleNameChange = (e) => {
    setFolderName(e.target.value);
  };

  let uploadedFiles = 0;

  const handleUploadFolder = () => {
    // build file and folder structure
    setLoading(true);
    let rootFolder = { files: [], folders: [], folder_name: "root" };
    acceptedFiles.forEach((item) => {
      let parent_folder = rootFolder;
      let arr = item.webkitRelativePath.split("/");
      for (let i = 1; i < arr.length; i++) {
        const element = arr[i];
        if (i !== arr.length - 1) {
          // folder
          let t = { files: [], folders: [], folder_name: element };
          let exist = false;
          let exist_index = -1;
          for (let p = 0; p < parent_folder.folders.length; p++) {
            const e = parent_folder.folders[p];
            if (e.folder_name === element) {
              exist = true;
              exist_index = p;
              break;
            }
          }
          if (!exist) {
            parent_folder.folders.push(t);
            parent_folder = t;
          } else {
            parent_folder = parent_folder.folders[exist_index];
          }
        } else {
          parent_folder.files.push(item);
        }
      }
    });
    sortFolder(rootFolder);
    UploadFolders(folderName, folder_id)
      .then((res) => {
        if (acceptedFiles.length === 0) {
          handleCloseModalRefresh();
        } else {
          let f_id = res.data.id;
          let parent = rootFolder;

          uploadFolderRecursive(parent, f_id);
        }
      })
      .catch((error) => {
        console.log("err", error);
        if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
          alert(error?.response?.data?.error);
        }
        handleCloseModal();
      });
  };

  const sortFolder = (parent) => {
    parent.files.sort(function (a, b) {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      return nameA.localeCompare(nameB, "en", { numeric: true });
    });
    parent.folders.sort(function (a, b) {
      var nameA = a.folder_name.toUpperCase();
      var nameB = b.folder_name.toUpperCase();
      return nameA.localeCompare(nameB, "en", { numeric: true });
    });
    for (let index = 0; index < parent.folders.length; index++) {
      const element = parent.folders[index];
      sortFolder(element);
    }
  };

  const uploadFolderRecursive = async (parent, parent_folder_id) => {
    await uploadFiles(parent, parent_folder_id);
    await parent.folders.reduce(async (a, p) => {
      await a;
      await UploadFolders(p.folder_name, parent_folder_id).then((r) => {
        uploadFolderRecursive(p, r.data.id);
      });
    }, Promise.resolve());
  };

  const uploadFiles = async (parent, parent_folder_id) => {
    await parent.files.reduce(async (a, file) => {
      try{
        // Wait for the previous item to finish processing
        await a;
        // Process this item
        setUploadingFileName(file.name);
        await UploadFiles(parent_folder_id, file);
        uploadedFiles += 1;
        let percent= uploadedFiles*100/acceptedFiles.length;
        setProgress(percent);
        if (uploadedFiles === acceptedFiles.length) {
          setTimeout(()=>{
            handleCloseModalRefresh();
          },800)
        }
      }
      catch(error){
        if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
          alert(error?.response?.data?.error);
        }
        handleCloseModal();
      }
    }, Promise.resolve());
  };
  const body = (
    <Box className={classes.paper}>
      <Typography className={classes.firstText}>Add folder</Typography>
      <Box height="1rem" />
      <Grid container>
        <Grid
          item
          className={
            choice === "details" ? classes.selected : classes.unselected
          }
          onClick={() => {
            if (choice !== "details") setChoice("details");
          }}
        >
          <Typography className={classes.choiceText}>Details</Typography>
        </Grid>
      </Grid>
      {choice === "details" && (
        <>
          <Box height="0.5rem" />
          <Grid container className={classes.container} spacing={2}>
            <Grid item sm={3} xs={4}>
              <Typography className={classes.fieldsText}>
                Name of the folder*
              </Typography>
            </Grid>
            <Grid item sm={9} xs={8}>
              <input
                type="text"
                placeholder="Enter folder name"
                autoFocus
                className={classes.inputfield}
                onChange={handleNameChange}
                value={folderName}
              />
            </Grid>
          </Grid>
          <Box height="1rem" />
          <Grid container className={classes.container} spacing={2}>
            <Grid item sm={3} xs={4}>
              <Typography className={classes.fieldsText}>
                Browse folder
              </Typography>
            </Grid>
            <Grid item sm={9} xs={8}>
              <Box>
                <div
                  {...getRootProps({
                    className: `dropzone ${classes.dropbox}`,
                  })}
                >
                  <input
                    {...getInputProps()}
                    directory=""
                    webkitdirectory=""
                    mozdirectory=""
                    type="file"
                  />
                  {!filesPreview ? (
                    <>
                      <img src={plusImage} alt="add" />
                    </>
                  ) : (
                    <Typography>{uploadedFolderName}</Typography>
                  )}
                </div>
              </Box>
            </Grid>
          </Grid>
          <Box height="1rem" />
        </>
      )}
      <Box height="1rem" />
      <Grid container justify="flex-end" className={classes.container} spacing={2} alignItems= 'center'>
        {loading && 
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Box  style={{width: '100%', padding: 10}}>
              <LinearProgressWithLabel value={progress} />
              <Typography className={classes.uploadingInfo}>Uploading {uploadingFileName} ...</Typography>
            </Box>
          </Grid>
        }
        <Grid item xs>
        
        </Grid>
        <Grid item 
          style={loading ? {display : 'none'} : {}}
        >
          <Typography className={classes.cancel} onClick={handleCloseModal}>
            Cancel
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.addbtn}
            onClick={handleUploadFolder}
            disabled={folderName.trim() === "" || loading}
          >
            {loading && <CircularProgress style={{ width: 15, height: 15, marginRight: '0.5rem' }} />}
            <Typography className={classes.add}>Add</Typography>
          </Button>
        </Grid>
      </Grid>
      <Box height="1rem" />
    </Box>
  );

  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
      disableBackdropClick={loading}
    >
      <Fade in={openmodal}>{body}</Fade>
    </Modal>
  );
}
