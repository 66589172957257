import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";

export const initialState = {
  loading: false,
  hasErrors: false,
  user: {},
  errors: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
      state.hasErrors = false;
      state.errors = {};
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errors = payload;
    },
  },
});

export const { setProgress, setSucessfull, setError } = userSlice.actions;

export default userSlice.reducer;

export function getUser() {
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: "get",
      url: "/api/user/",
    }).then(
      (response) => {
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);
        // console.log(error.request.status);
        // console.log(error.message)
        // console.log(error.request.response)
        dispatch(setError());
      }
    );
  };
}

export function register(userData) {
  let {
    email,
    password,
    name,
    otp,
  } = userData;
  const fullName = name.trim();
  var first_name = fullName.substring(0, fullName.lastIndexOf(' '));
  var last_name = fullName.substring(fullName.lastIndexOf(' ') + 1, fullName.length);
  
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "/signup/",
        data: {
          user: { email, password, first_name, last_name},
          otp: otp,
        },
      });
      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      // console.log("error.message...", error.message);
      // console.log("error.request.status...", error.request.status);
      // console.log("error.request.response...", error.request.response);
      console.log("inside send otp catch block...before response return");
      return JSON.parse(error.request.response);
      // return JSON.parse(error.request.response.data);
      // dispatch(setError(JSON.parse(error.request.response)));
    }
  };
}
