import React from 'react';
import Effortless_Document_Management from 'images/Effortless_Document_Management.svg';
import Add_Collaborators from 'images/Add_Collaborators.svg';
import Upload_or_Import from 'images/Upload_or_Import.svg';
import CustomCarousel from 'features/CustomCarousel/CustomCarousel';

export default function Graphics(){

  const GraphicsCollection = [
    {
      title: 'Effortless Next-Gen Document Management loved by the Legal community',
      content: `<ul>
                    <li>1-click pagination of filings, briefs and exhibits</li>
                    <li>Powerful semantic search even within image document</li>
                    <li>Instant hyperlinked Indices for all your case files</li>
                </ul>`,
      image_src: Effortless_Document_Management,
    },
    {
      title: 'Encrypted for Maximum Security',
      content: `<p>Experience peace of mind with our advanced security features, featuring high-grade encryption, ensuring absolute confidentiality and safeguarding your valuable case files</p>`,
      image_src: Add_Collaborators,
    },
    {
      title: 'Upload from anywhere',
      content: `<p>Import your case files from Google Drive, Dropbox and OneDrive in a single click.</p>`,
      image_src: Upload_or_Import,
    }
  ];

  

  return (
    <CustomCarousel
      GraphicsCollection={GraphicsCollection}
      style={{padding:'3rem'}}
      // You can also set css class of Carousel root container by passing it through className prop and set the time to show each slide on screen by passing time in miliseconds through delay prop
      // className 
      // delay
    />
  )
}