import { Box, Typography, LinearProgress } from "@material-ui/core";
import React from "react";

export default function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography style={{fontSize: "18px",fontWeight: "400",fontFamily: `'Inter',sans-serif`,color: "#000000",}}>{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
