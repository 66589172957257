import {
  Box,
  Grid,
  makeStyles,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { UploadFiles, importFromDrives, UploadFolders } from "../api/FileApi";
import LinearProgressWithLabel from "shared/LinearProgressWithLabel";
import CustomizedDialogs  from "features/CustomisedDialogbox/CustomisedDialogbox";
import { useSnackbar } from "contexts/SnackbarContext";
import DropboxChooser from "features/dropbox/DropboxChooser";
import { DROPBOX_APP_KEY, DEV_REDIRECT_URI, PROD_REDIRECT_URI, GOOGLE_DRIVE, DROPBOX, WARNING, INFO, ERROR, ERROR_TITLE, WARNING_TITLE } from "constants/appConstants";
import { REDIRECT_URI } from "api/globalaxios";

async function myCustomFileGetter(event) {
  const files = [];
  const fileList = event.dataTransfer
    ? event.dataTransfer.files
    : event.target.files;

  for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    files.push(file);
  }
  return files;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    position: "absolute",
    // maxHeight:'20rem',
    left: "50%",
    top: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  firstText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  selected: {
    padding: "0.3rem 1.3rem",
    cursor: 'pointer',
    fontSize: '1.3rem',
    fontFamily: 'Satoshi-Bold',
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  unselected: {
    fontSize: '1.3rem',
    padding: "0.3rem 1.3rem",
    cursor: "pointer",
  },
  choiceText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  fieldsText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    marginTop: "0.7rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.3rem",
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  inputfield: {
    width: "100%",
    height: "3rem",
    background: "#E0E0E0",
    color: "#000000",
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    "&:focus": {
      outline: "none !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  dropbox: {
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    background: "rgba(74, 59, 255, 0.03)",
    color: "#000000",
    fontSize: "1rem",
    border: `2px dashed ${theme.palette.primary.main}`,
    padding: "1rem",
    textAlign: "center",
    height: '13rem',
    overflow: "scroll",
    cursor: 'pointer'
  },
  alertSelected: {
    padding: "0.3rem",
    background: "#E0E0E0",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  alertUnSelected: {
    padding: "0.3rem",
    cursor: "pointer",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  cancel: {
    textDecoration: "underline",
    fontSize: "20px",
    fontWeight: "400",
    color: "#2F80ED",
    lineHeight: "36px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      lineHeight: "26px",
    },
  },
  add: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#FFFFFF",
    lineHeight: "36px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  addbtn: {
    background: "#2F80ED",
    marginLeft: "3rem",
    width: "120px",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      marginLeft: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90px",
      marginLeft: "1rem",
    },
  },
  box: {
    padding: "0.6rem 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  container: {
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  scroll: {
    overflowY: "auto",
    maxHeight: "20rem",
    cursor: "all-scroll",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "15rem",
    },
  },
  notify: {
    fontSize: "20px",
    color: "#4F4F4F",
    lineHeight: "36px",
    fontFamily: "Roboto",
    textAlign: "center",
    marginTop: "10px",
    textTransform: "capitalize",
  },
  alertOnHeading: {
    fontSize: 8,
    lineHeight: "14px",
    fontWeight: 600,
    color: "white",
    fontFamily: `'Inter',sans-serif`,
  },
  alertOnText: {
    fontSize: 8,
    lineHeight: "14px",
    fontWeight: 300,
    color: "white",
    fontFamily: `'Inter',sans-serif`,
  },
  uploadingInfo: {
    color: "#4F4F4F",
    wordBreak: "break-all",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '200px',
    },
  },
  browse_text: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  }
}));

export default function UploadFile({
  openmodal,
  handleCloseModal,
  folder_id,
  handleCloseModalRefresh,
  parent_folder,
  setImportStatus,
  handleOpenSnackBarImport,
  autoRefreshOnImport,
  permissionToImportFolder,
}) {
  const classes = useStyles();
  const [choice, setChoice] = useState('uploadFile');

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadingFileName, setUploadingFileName] = useState('');
  const [uploadedFolderName, setUploadedFolderName] = useState("");
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    getFilesFromEvent: (event) => myCustomFileGetter(event),
    disabled: loading,
  });
  const [filesPreview, setFilesPreview] = useState(false);

  const [link, setLink] = useState('');
  const [platform, setPlatform] = useState('');
  const [error,setError] = useState();

  const { showSnackbar } = useSnackbar();

  const platformList = [GOOGLE_DRIVE,DROPBOX];
  const [isFolder, setIsFolder] = useState('');

  // let REDIRECT_URI;

  // if (window.location.host == "app-doc.webnyay.in" || window.location.host == "docs.webnyay.ai") {
  //   REDIRECT_URI = PROD_REDIRECT_URI;
  // } else {
  //   REDIRECT_URI = DEV_REDIRECT_URI;
  // }
  // // REDIRECT_URI = 'http://localhost:3000/dropbox/authorised';

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setFilesPreview(true);
      let t = acceptedFiles[0].webkitRelativePath.split("/");
      setUploadedFolderName(t[0]);
    }
  }, [acceptedFiles]);

  let uploadedFiles = 0;

  const handleUploadFile = async () => {
    if(acceptedFiles.length === 0){
      showSnackbar('Please select a file or folder',WARNING,WARNING_TITLE);
      return;
    }
    setLoading(true);
    await acceptedFiles.reduce(async (a, file) => {
      try{
        // Wait for the previous item to finish processing
        await a;
        // Process this item
        setUploadingFileName(file.name);
        await UploadFiles(folder_id, file);
        uploadedFiles += 1;
        let percent= uploadedFiles*100/acceptedFiles.length;
        setProgress(percent);
        if (uploadedFiles === acceptedFiles.length) {
          setTimeout(()=>{
            handleCloseModalRefresh();
          },800)
        }
      }
      catch(error){
        if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
          showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
        }
        handleCloseModal();
      }
    }, Promise.resolve());
    // handleCloseModalRefresh();
  };

  const handleChangeonLink = (event) => {
    setLink(event.target.value);
    if(event.target.value === '')
      setError(null);
  }

  const handleChangePlatform = (event) => {
    setPlatform(event.target.value);
  }

  const validateLinkForImport = (link) => {
    let url;
    try {
      url = new URL(link);
    } catch (_) {
      return 'Link is not valid';
    }
    if(url.protocol !== "https:"){
      return 'Link is not valid';
    }
    else if(!link.includes('/drive.google.com/') && !link.includes('/docs.google.com/')){
      return 'Not a google drive link';
    }
    return 'valid';
  }

  const onChoose = (files) => {
    setLink(files[0]?.link);
    setIsFolder(files[0]?.isDir);
  }

  const getAuthorizationCode = async () => {
    if(isFolder && !permissionToImportFolder()){
      showSnackbar('You cannot import a folder here\nIt will breaks the pagination',WARNING,WARNING_TITLE);
      return;
    }
    const Dropbox_App_Key = DROPBOX_APP_KEY;
    const import_info = {
      link: link,
      parent_folder: parent_folder.id,
    }
    sessionStorage.setItem('import_info',JSON.stringify(import_info));
    window.location.href = `https://www.dropbox.com/oauth2/authorize?token_access_type=offline&client_id=${Dropbox_App_Key}&redirect_uri=${REDIRECT_URI}&response_type=code`;
  }

  const handleImport = () => {
    if(link === '' || platform === ''){
      showSnackbar('Please fill both required field!',WARNING,WARNING_TITLE);
      return;
    }
    else{
      if(platform === DROPBOX){
        getAuthorizationCode();
        return;
      }
      if(validateLinkForImport(link) !== 'valid' ){
        setError(validateLinkForImport(link));
      }
      else if(link.includes('folders') && !permissionToImportFolder()){
        showSnackbar('You cannot import a folder here\nIt will breaks the pagination',WARNING,WARNING_TITLE);
      }
      else{
        let data = {
          link: link,
          parent_folder: parent_folder.id,
          platform: platform
        }
        importFromDrives(data)
        .then((res)=>{
          setLoading(true);
          setImportStatus(res.data.doc.import_status);
          handleCloseModal();
          // handleOpenSnackBarImport();
          showSnackbar('Import is in progress. Please wait for 5-10 minutes',INFO,'For your information!');
          autoRefreshOnImport();
        })
        .catch((error) => {
          if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
            showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
          }
          handleCloseModal();
        })
      }
    }
  }

  const handleUploadFolder = () => {
    if(acceptedFiles.length === 0){
      showSnackbar('Please select a file or folder',WARNING,WARNING_TITLE);
      return;
    }
    // build file and folder structure
    setLoading(true);
    let rootFolder = { files: [], folders: [], folder_name: "root" };
    acceptedFiles.forEach((item) => {
      let parent_folder = rootFolder;
      let arr = item.webkitRelativePath.split("/");
      for (let i = 1; i < arr.length; i++) {
        const element = arr[i];
        if (i !== arr.length - 1) {
          // folder
          let t = { files: [], folders: [], folder_name: element };
          let exist = false;
          let exist_index = -1;
          for (let p = 0; p < parent_folder.folders.length; p++) {
            const e = parent_folder.folders[p];
            if (e.folder_name === element) {
              exist = true;
              exist_index = p;
              break;
            }
          }
          if (!exist) {
            parent_folder.folders.push(t);
            parent_folder = t;
          } else {
            parent_folder = parent_folder.folders[exist_index];
          }
        } else {
          parent_folder.files.push(item);
        }
      }
    });
    sortFolder(rootFolder);
    UploadFolders(uploadedFolderName, folder_id)
      .then((res) => {
        if (acceptedFiles.length === 0) {
          handleCloseModalRefresh();
        } else {
          let f_id = res.data.id;
          let parent = rootFolder;

          uploadFolderRecursive(parent, f_id);
        }
      })
      .catch((error) => {
        console.log("err", error);
        if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
          showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
        }
        handleCloseModal();
      });
  };

  const sortFolder = (parent) => {
    parent.files.sort(function (a, b) {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      return nameA.localeCompare(nameB, "en", { numeric: true });
    });
    parent.folders.sort(function (a, b) {
      var nameA = a.folder_name.toUpperCase();
      var nameB = b.folder_name.toUpperCase();
      return nameA.localeCompare(nameB, "en", { numeric: true });
    });
    for (let index = 0; index < parent.folders.length; index++) {
      const element = parent.folders[index];
      sortFolder(element);
    }
  };

  const uploadFolderRecursive = async (parent, parent_folder_id) => {
    await uploadFiles(parent, parent_folder_id);
    await parent.folders.reduce(async (a, p) => {
      await a;
      await UploadFolders(p.folder_name, parent_folder_id).then((r) => {
        uploadFolderRecursive(p, r.data.id);
      });
    }, Promise.resolve());
  };

  const uploadFiles = async (parent, parent_folder_id) => {
    await parent.files.reduce(async (a, file) => {
      try{
        // Wait for the previous item to finish processing
        await a;
        // Process this item
        setUploadingFileName(file.name);
        await UploadFiles(parent_folder_id, file);
        uploadedFiles += 1;
        let percent= uploadedFiles*100/acceptedFiles.length;
        setProgress(percent);
        if (uploadedFiles === acceptedFiles.length) {
          setTimeout(()=>{
            handleCloseModalRefresh();
          },800)
        }
      }
      catch(error){
        if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
          showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
        }
        handleCloseModal();
      }
    }, Promise.resolve());
  };

  const uploadModal = (choice) => {
    switch(choice) {
      case 'uploadFile' :
        return (
          <>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <div
                    {...getRootProps({
                      className: `dropzone ${classes.dropbox}`,
                    })}
                  >
                    <input {...getInputProps()} type="file" directory webkitdirectory mozdirectory />
                    {!filesPreview ? (
                      <>
                        <Typography style={{color:'#888'}}>Drop your file <br/>here or <span className={classes.browse_text}>browse</span></Typography>
                      </>
                    ) : (
                      acceptedFiles.map((file) => {
                        return (
                          <Typography
                            key={file.name}
                            style={{ wordBreak: "break-all" }}
                          >
                            {file.name}
                          </Typography>
                        );
                      })
                    )}
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Box height="1rem" />
            <Grid container className={classes.container} alignItems='center'>
              {loading && 
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box  style={{width: '100%', padding: 10}}>
                    <LinearProgressWithLabel value={progress} />
                    <Typography className={classes.uploadingInfo}>Uploading {uploadingFileName}</Typography>
                  </Box>
                </Grid>
              }
            </Grid>
          </>
        )
      case 'uploadFolder' :
        return (
          <>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <div
                    {...getRootProps({
                      className: `dropzone ${classes.dropbox}`,
                    })}
                  >
                    <input {...getInputProps()} type="file" directory='' webkitdirectory='' mozdirectory='' />
                    {!filesPreview ? (
                      <>
                        <Typography style={{color:'#888'}}>Drop your folder <br/>here or <span className={classes.browse_text}>browse</span></Typography>
                      </>
                    ) : (
                      <Typography style={{ wordBreak: "break-all" }}>{uploadedFolderName}</Typography>
                    )}
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Box height="1rem" />
            <Grid container className={classes.container} alignItems='center'>
              {loading && 
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box  style={{width: '100%', padding: 10}}>
                    <LinearProgressWithLabel value={progress} />
                    <Typography className={classes.uploadingInfo}>Uploading {uploadingFileName}</Typography>
                  </Box>
                </Grid>
              }
            </Grid>
          </>
        )
      case 'import' :
        return (
          <Box style={{height:'17rem'}}>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel id="platformId">Select Platform</InputLabel>
                  <Select
                    labelId="platformId"
                    id="platform"
                    value={platform}
                    label="Select Platform"
                    onChange={handleChangePlatform}
                  >
                    {platformList.map((platform,index)=>{
                      if(platform === DROPBOX){
                        return (
                          <MenuItem key={index} value={platform} style={{display:'block'}}>
                            <DropboxChooser
                              appKey={DROPBOX_APP_KEY}
                              success={onChoose}
                              folderselect={true}                                    
                            >
                              {platform}
                            </DropboxChooser>
                          </MenuItem>
                        )
                      }
                      else{
                        return <MenuItem key={index} value={platform}>{platform}</MenuItem>
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box height="1rem" />
            { platform === GOOGLE_DRIVE &&
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <TextField
                    error={error}
                    helperText={error}
                    placeholder="Enter link here"
                    variant="outlined"
                    fullWidth
                    onChange={handleChangeonLink}
                    value={link}
                    required
                    autoFocus
                  />
                </Grid>
              </Grid>
            }
            <Box height="1rem" />
          </Box>
        )
      default :
    }
  }

  const body = (
    <Box>
      <Grid container>
        <Grid
          item
          className={
            choice === "uploadFile" ? classes.selected : classes.unselected
          }
          onClick={() => {
            setChoice("uploadFile");
          }}
        >
          Upload File
        </Grid>
        {permissionToImportFolder() && (
          <Grid
            item
            className={
              choice === "uploadFolder" ? classes.selected : classes.unselected
            }
            onClick={() => {
              setChoice("uploadFolder");
              console.log(permissionToImportFolder());
            }}
          >
            Upload Folder
          </Grid>
        )}
        <Grid
          item
          className={
            choice === "import" ? classes.selected : classes.unselected
          }
          onClick={() => {
            setChoice("import");
          }}
        >
          Import
        </Grid>
      </Grid>
      <Box height="1rem" /> 
      {uploadModal(choice)}
    </Box>
  );

  return (
    <CustomizedDialogs
      open={openmodal}
      handleClose={handleCloseModal}
      title={'Upload or Import files'}
      buttonText={(choice === 'uploadFile' || choice === 'uploadFolder') ? 'Upload' : 'Import'}
      onSubmit={choice === 'uploadFile' ? handleUploadFile : choice === 'uploadFolder' ? handleUploadFolder : handleImport}
      isSubmitButtonDisabled={loading}
      disableBackDropClick={loading}
      isCancelButtonDisabled={loading}
    >
      {body}
    </CustomizedDialogs>
  );
}
