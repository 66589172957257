import React, { RefObject } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
// import { TextMode } from '../entities';
// import { Dimmer } from 'semantic-ui-react';
// import { Div } from '../ui/components/Div';
// import { ConfirmContent } from './ConfirmContent';

export const EditorComponent = ({
    dimmerActive,
    cancelDelete,
    deleteText,
  text,
  width,
  height,
  inputRef,
  mode,
  size,
  fontFamily,
  positionTop,
  positionLeft,
  onChangeText,
  toggleEditMode,
  handleMouseDown,
  handleMouseMove,
  handleMouseOut,
  handleMouseUp,
  lineHeight,
  onSingleClick,
  deleteIndex,
  index,
}) => {
  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseOut={handleMouseOut}
      onDoubleClick={toggleEditMode}
      onClick={onSingleClick}
      style={{
        width,
        border: 1,
        height,
        fontFamily,
        fontSize: size,
        lineHeight,
        // cursor: mode === TextMode.COMMAND ? 'move' : 'default',
        top: positionTop,
        left: positionLeft,
        borderColor: 'white',
        borderStyle: 'solid',
        wordWrap: 'break-word',
        padding: 0,
        position: 'absolute',
        border: deleteIndex === index ? '2px solid blue': '',
      }}
    >

        {/* <Dimmer.Dimmable as={Div} size="medium" dimmed={dimmerActive}> */}
          <div dangerouslySetInnerHTML={{__html: text}}></div>
          {/* <div style={{cursor: 'pointer'}} onClick={deleteText}><DeleteIcon/></div> */}
        {/* </Dimmer.Dimmable> */}
    </div>
  );
};
