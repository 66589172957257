import React from "react";
import { Popover, Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuwidth: {
    'min-width': "150px",
    [theme.breakpoints.down("sm")]: {
      'min-width': "130px",
    },
    [theme.breakpoints.down("xs")]: {
      'min-width': "150px",
    },
  },
}))

export default function MenusPopover({
	id,
	anchorEl,
	open,
	onClose,
	menus
}){
	const classes = useStyles();

	return (
		<Popover
			id={id}
			anchorEl={anchorEl}
			keepMounted
			open={open}
			onClose={onClose}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			style={{ padding: 0 }}
		>
			<Box className={classes.menuwidth}>
				<Box>
				{
					menus.menuItems.map((menuItem,index)=>{
						return (
							<Typography
								key={index}
								className={menus.className}
								onClick={menuItem.onClick}
								style={menuItem.style}
							>
								{menuItem.title}
							</Typography>
						)
					})
				}
				</Box>
			</Box>
		</Popover>
	)
}