import { Box, Button, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { PASSWORD_HELPER_TEXT, PASSWORD_REGEX } from "constants/appConstants";
import FormattedMessage from "features/common/TranslatedMessage";
import DisplayContainer from "features/profile/DisplayContainer";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changePassword } from "slices/loginSlice";
import Swal from "sweetalert2";

export default function ProfileSecurity() {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
    error: "",
    nonFieldErrors: [],
  });
  const [emptyError1, setEmptyError1] = useState();
  const [emptyError2, setEmptyError2] = useState();

  const { currentPassword, newPassword, error, nonFieldErrors } = state;

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState({ ...state, [name]: value });
    if (name === "currentPassword" && value !== "") {
      setEmptyError1();
    }
    if (name === "newPassword" && value !== "") {
      setEmptyError2();
    }
    if (name === "currentPassword" && value === "") {
      setEmptyError1("Current password required");
    }
    if (name === "newPassword" && value === "") {
      setEmptyError2("New password required");
    }
  };

  const setErrors = (error, nonFieldErrors) => {
    setState({ ...state, error, nonFieldErrors });
  };

  const onSubmit = async () => {
    if (currentPassword === "" || newPassword === "") {
      if (currentPassword === "") {
        setEmptyError1("Current password required");
      }
      if (newPassword === "") {
        setEmptyError2("New password required");
      }
    } else {
      setEmptyError1();
      setEmptyError2();
      if (currentPassword === newPassword) {
        setEmptyError2("New password can't be the same as current password");
        return;
      }
      const isStrongPassward = PASSWORD_REGEX.test(newPassword);
      if(!isStrongPassward){
        setEmptyError2(PASSWORD_HELPER_TEXT);
        return;
      }
      try {
        await dispatch(changePassword(currentPassword, newPassword));
        Swal.fire({
          icon: "success",
          title: "Password changed succesfully",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        let parsedError = JSON.parse(err);
        if (parsedError.error.non_field_errors) {
          setErrors("", parsedError.error.non_field_errors);
        } else {
          setErrors(parsedError.error, []);
        }
      }
    }
  };
  return (
    <Box>
      <DisplayContainer title={<FormattedMessage id="auth.password" />}>
        <TextField
          name="currentPassword"
          value={currentPassword}
          onChange={onChange}
          placeholder="Current password"
          fullWidth
          type="password"
        />
        <Box height="1rem"></Box>
        {error.length > 0 && !emptyError1 && !emptyError2 && (
          <Alert severity="error">{error}</Alert>
        )}
        {emptyError1 && <Alert severity="error">{emptyError1}</Alert>}
        <Box height="2.5rem"></Box>
        <TextField
          name="newPassword"
          value={newPassword}
          onChange={onChange}
          placeholder="New password"
          fullWidth
          type="password"
        />
        <Box height="1rem"></Box>
        {emptyError2 && <Alert severity="error">{emptyError2}</Alert>}
        {!emptyError1 &&
          !emptyError2 &&
          nonFieldErrors.map((error, index) => {
            return (
              <Alert severity="error" key={index}>
                {error}
              </Alert>
            );
          })}
        <Box height="2.5rem"></Box>

        <Button variant="contained" color="primary" onClick={onSubmit}>
          {<FormattedMessage id="buttons.resetPassword" />}
        </Button>
      </DisplayContainer>
    </Box>
  );
}
