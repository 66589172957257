import {
  Box,
  Grid,
  IconButton,
  Menu,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Hidden,
  Checkbox,
  Avatar,
} from "@material-ui/core";
import FolderImage from "images/folder.svg";
import DotsImage from "images/threeDots.svg";
import FolderIcon from "images/folderIcon.svg";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils";
import { deleteFolder, UpdateFolder, bookmarkIndexFile, getFiles } from "../api/FileApi";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SimpleDialog from "./SimpleDialog";
import CustomisedTooltip from "features/CustomisedToolTip/CustomisedToolTip";
import { useSnackbar } from "contexts/SnackbarContext";
import { ERROR, ERROR_TITLE, SUCCESS, SUCCESS_TITLE } from "constants/appConstants";

export default function FolderView({
  folder,
  classes,
  reload,
  profile,
  view,
  handlePageChange,
  paginationStatus,
  currentPage,
  pageSize,
  workspace_id,
  handleChangeOnFolderCheckbox,
  selectedFolders,
}) {
  const history = useHistory();
  const [anchorElLocal, setAnchorElLocal] = useState(null);
  const [bookmarkPossible, setBookmarkPossible] = useState([]);
  const [bookmarkModal, setBookmarkModal] = useState(false);
  const [bookmarkId, setBookmarkId] = useState('');
  const { showSnackbar } = useSnackbar();
  const isSelected = selectedFolders?.includes(`${folder.id}`);

  const handleClick = (e) => {
    setAnchorElLocal(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorElLocal(null);
  };

  function folderSize(size) {
    size = parseInt(size);
    if (size)
      if (size < 1024) return `${size.toFixed(2)} B`;
      else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
      else if (size < 1073741824) return `${(size / 1048576).toFixed(2)} MB`;
      else return `${(size / 1073741824).toFixed(2)} GB`;
    else return "0 B";
  }

  let localName = folder.nameToShowUser;
  let prefix = folder.nameToShowUser.split("__")[0] + "__";
  if (localName) {
    localName = localName.replace(prefix, "");
  }
  const [originalFolderName, setOriginalFolderName] = useState(localName);

  const [rename, setRename] = useState(false);
  // const [name, setName] = useState(folder.name);
  const handleFolderNameChange = (e) => {
    setOriginalFolderName(e.target.value);
  };
  const inputRef = useRef(null);
  const handleOnBlur = () => {
    console.log(originalFolderName?.length)
    if(originalFolderName?.length > 300){
      showSnackbar('Maximum 300 characters are allowed',ERROR,ERROR_TITLE)
      return;
    }
    setRename(false);
    if (originalFolderName !== localName) {
      UpdateFolder(folder.id, {
        // name: prefix + originalFolderName,

        nameToShowUser:
          folder.nameToShowUser.split("__").length > 1
            ? prefix + originalFolderName
            : originalFolderName,
      }).then(() => {
        reload();
      });
    }
  };

  const handleRename = () => {
    handleClose();
    setRename(true);
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 100);
  };

  const [isDeleteClicked,setDeleteClicked] = useState(false);

  const handleDelete = () => {
    handleClose();
    setDeleteClicked(true);
    deleteFolder(folder.id).then(() => {
      reload();
    })
    .catch((error)=>{
      setDeleteClicked(false);
      if(error?.response?.status === 424 && error?.response?.data?.Failure){
        showSnackbar(error?.response?.data?.Failure,ERROR,ERROR_TITLE);
      }
    })
  };

  const handleOpenFolder = (id) => {
    history.push(`/folder/${id}/${workspace_id}`);
    handlePageChange(1);
  };

  async function handlebookmark(id){
    let searchableData;
    await getFiles(id, currentPage, pageSize).then((res)=> {
      if(res?.data?.results){
        searchableData = res?.data?.results?.filter(item => item.is_searchable === false);
        setBookmarkPossible(searchableData);
      }
    })
    .catch((error)=>{
      console.log(error);
      if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    })
    setBookmarkId(id);

    if(searchableData?.length === 0){
      handleClose();
      handleBookmarkContinue(id);
      setBookmarkModal(false)
    }else {
      handleClose();
      setBookmarkModal(true);
    }
  }

  function handleBookmarkContinue(id){
    bookmarkIndexFile(id).then((res) => {
      showSnackbar("Bookmark completed.",SUCCESS,SUCCESS_TITLE);
      reload();
    })
    .catch((error)=>{
      console.log(error);
      if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    })
    setBookmarkModal(false);
  }

  function handleCloseBookmarkModal(){
    setBookmarkPossible([]);
    setBookmarkModal(false);
  }

  return (
    <>
      {view === "list" ? (
        <Grid
          container
          className={classes.filesHover}
          spacing={1}
          onDoubleClick={() => {
            handleOpenFolder(folder.id);
          }}
        >
          <Grid item lg={4} md={4} sm={4} xs={7}>
            <div style={{display:'flex',alignItems:'center'}}>
              <Checkbox
                id={folder.id}
                color="primary"
                className={isSelected?classes.visibility:classes.visibilityHidden}
                style={{padding:'5px',height:'fit-content'}}
                checked={isSelected}
                onChange={handleChangeOnFolderCheckbox}
              />
              <FiberManualRecordIcon style={{visibility:'hidden',fontSize:'small'}}/>
              <span style={{display: 'inline-block'}}>
                <img style={{width: '20px', height: '16px', verticalAlign: 'bottom'}} src={FolderIcon} alt="folder" />
              </span>
              <Typography
                className={classes.tableContent}
                style={{ marginLeft: "0.5rem" }}
              >
                {!rename ? (
                  folder.nameToShowUser
                ) : (
                  <input
                    ref={inputRef}
                    value={originalFolderName}
                    style={{ width: "70%" }}
                    onChange={handleFolderNameChange}
                    onBlur={handleOnBlur}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleOnBlur();
                      }
                    }}
                  />
                )}
              </Typography>
            </div>
          </Grid>
          <Hidden xsDown>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <Typography
                className={classes.tableContent}
              >
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <Typography
                className={classes.tableContent}
              >
                {folder?.number_of_pages}
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <Typography
                className={classes.tableContent}
              >
                {folderSize(folder.size)}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item lg={2} md={2} sm={2} xs={4}>
            <Typography
              className={classes.tableContent}
            >
              <div style={{display:'flex',gap:6,alignItems:'center'}}>
                <Avatar style={{height:30,width:30,backgroundColor:'#FFAB00'}}>
                  {folder?.owner_name.charAt(0)}
                </Avatar>
                {folder?.owner_name}
              </div>
            </Typography>
          </Grid>
          <Hidden xsDown>
            <Grid item lg={1} md={1} sm={1} xs={1}>
                {folder?.are_subfiles_stamped && (
                  <CustomisedTooltip title={<div style={{alignItems:'center'}}><FiberManualRecordIcon color="secondary" style={{fontSize:'small'}}/> Paginated</div>}>
                    <FiberManualRecordIcon color="secondary" style={{fontSize:'small'}}/>
                  </CustomisedTooltip>
                )}
                {folder?.is_ocred && (
                  <CustomisedTooltip title={<div style={{alignItems:'center'}}><FiberManualRecordIcon color="primary" style={{fontSize:'small'}}/> OCR done</div>}>
                    <FiberManualRecordIcon color="primary" style={{fontSize:'small'}}/>
                  </CustomisedTooltip>
                )}
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={3}>
              <div style={{display:'flex',gap:'15px'}}>
                <Typography
                  className={classes.tableContent}
                >
                  {/* {format(new Date(folder.modified), "d MMMM y, h:m a")} */}
                  {formatDate(folder.modified)}
                </Typography>
                <IconButton
                  onClick={handleClick}
                  aria-controls={`three-dots-menu${folder.id}`}
                  aria-haspopup="true"
                  style={{ padding: 0 }}
                >
                  <img src={DotsImage} alt="options" />
                </IconButton>
              </div>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid
              item
              lg={1}
              md={1}
              sm={1}
              xs={1}
              style={{ textAlign: "right" }}
            >
              <IconButton
                onClick={handleClick}
                aria-controls={`three-dots-menu${folder.id}`}
                aria-haspopup="true"
                style={{ padding: 0 }}
              >
                <img src={DotsImage} alt="options" />
              </IconButton>
            </Grid>
          </Hidden>
        </Grid>
      ) : (
        <Grid
          item
          lg={2}
          md={3}
          sm={3}
          xs={6}
          className={classes.gridFilesHover}
          onDoubleClick={() => {
            handleOpenFolder(folder.id);
          }}
        >
          <Card style={{height:'100%'}}>
            <CardMedia
              component={"img"}
              height=""
              image={FolderIcon}
              style={{
                width: "40%",
                margin: "auto",
                padding: "12%",
              }}
            />
            <CardContent
              style={{ padding: "15px", borderTop: "1px solid #eeeeee" }}
            >
              <Grid container alignItems="center">
                <Grid item lg={10} md={10} sm={10} xs={10}>
                  <Typography
                    className={classes.tableContent}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {/* <span>
                      <img src={FolderImage} alt="folder" />
                    </span> */}
                    <Checkbox
                      id={folder.id}
                      color="primary"
                      className={isSelected?classes.visibility:classes.visibilityHidden}
                      style={{padding:'5px',height:'fit-content'}}
                      checked={isSelected}
                      onChange={handleChangeOnFolderCheckbox}
                    />
                    <CustomisedTooltip title={folder.nameToShowUser}>
                      <span style={{}}>
                        {!rename ? (
                          folder.nameToShowUser
                        ) : (
                          <input
                            ref={inputRef}
                            value={originalFolderName}
                            style={{ width: "70%" }}
                            onChange={handleFolderNameChange}
                            onBlur={handleOnBlur}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleOnBlur();
                              }
                            }}
                          />
                        )}
                      </span>
                    </CustomisedTooltip>
                  </Typography>
                </Grid>
                {
                  <Grid
                    item
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    style={{ textAlign: "right" }}
                  >
                    <IconButton
                      onClick={handleClick}
                      aria-controls={`three-dots-menu${folder.id}`}
                      aria-haspopup="true"
                      style={{ padding: 0 }}
                    >
                      <img src={DotsImage} alt="options" />
                    </IconButton>
                  </Grid>
                }
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Menu
        id={`three-dots-menu${folder.id}`}
        anchorEl={anchorElLocal}
        keepMounted
        open={Boolean(anchorElLocal)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box className={classes.menuwidth}>
          <Box>
            <Typography
              className={classes.filesandfolder}
              onClick={handleRename}
              style={
                paginationStatus === 1 ? {display:'none'} : null
              }
            >
              Rename
            </Typography>
            {/* <Typography
              className={classes.filesandfolder}
              onClick={handlePaginate}
            >
              Paginate
            </Typography> */}
            <Typography
              className={classes.filesandfolder}
              onClick={handleDelete}
              style={isDeleteClicked?{pointerEvents:'none',background:'#6e6e6e',color:'white'}:null}

            >
              Delete
            </Typography>
            <Typography
              className={classes.filesandfolder}
              onClick={() => handlebookmark(folder.id)}
            >
              Bookmark
            </Typography>
          </Box>
        </Box>
      </Menu>
      {bookmarkModal ?
        <SimpleDialog
          open={bookmarkModal}
          handleAgreeBtn={() =>handleBookmarkContinue(bookmarkId)}
          closeModal={handleCloseBookmarkModal}
          title="Continue Bookmark"
          subTitle="There are some images files that need to be OCRed. Do you want to proceed with the merging and bookmarking process anyway?"
        />
      : null}
    </>
  );
}
