import React from "react";
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Button,
	Typography,
	makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme)=>({
	root: {
		borderRadius: 0,
		border: `1px solid ${theme.palette.border}`,
		height: '100%',
		position: 'relative',
	},
	title: {
		fontSize: '1.2rem',
		fontWeight: 700,
		color: 'black',
	},
	subTitle: {
		color: '#808080',
		fontSize: '0.8rem',
	},
	cardContent: {
		padding: theme.spacing(0,2),
	},
	cardActions: {
		padding: theme.spacing(2),
		position: 'absolute',
		bottom: 0,
	},
	button: {
		fontWeight: 700,
		padding: '0.75rem 1.5rem',
		backgroundColor: 'rgba(74, 59, 255, 0.10)',
	}
}))

export default function ButtonCard ({
	title,
	subTitle,
	avatar,
	children,
	buttonText,
	onSubmit,
	buttonStyle,
	isButtonDisabled,
}) {
	const classes = useStyles();

	return (
		<Card variant="outlined" className={classes.root}>
			<CardHeader
				avatar={avatar}
				title={
					<Typography className={classes.title}>{title}</Typography>
				}
				subheader={subTitle && 
					<Typography className={classes.subTitle}>{subTitle}</Typography>
				}
			/>	
			<CardContent className={classes.cardContent}>
				{children}
      </CardContent>
			{onSubmit && (
				<div style={{height:'65px'}}>
					<CardActions className={classes.cardActions}>
						<Button
							className={classes.button}
							style={buttonStyle}
							disabled={isButtonDisabled}
							onClick={onSubmit}
							color="primary"
						>
							{buttonText || 'Submit'}
						</Button>
					</CardActions>
				</div>
			)}
		</Card>
	)
}