import React, {useState } from "react";
import { makeStyles, Box, Typography} from "@material-ui/core";
import GetDetails from "./GetDetails";
import Docs from "images/MicrosoftTeams-image 1.png";
import { useFormik } from "formik";
import { object, string, } from 'yup';
import { CREATE_USER, LOGIN, VERIFY_EMAIL, VERIFY_PASSWORD } from "constants/appConstants";
import Graphics from "./Graphics";
import { checkUserExistsOrNot, setTokenAsync, sendOtpAsync } from "slices/loginSlice";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { isLocalhost } from "Routes/Routes";

const LoginSchema = object().shape({
  email: string().email("Invalid email").required("Email Required"),
  password: string().required("Password Required"),
  create_password: string().required("Password Required")
    .min(6, "Password Too Short!")
    .max(15, "Password Too Long!")
    .matches(/^(.*[A-Z].*)/, "Must Contain an Upper Case Alphabet")
    .matches(/^(.*[a-z].*)/, "Must Contain a Lower Case Alphabet")
    .matches(/^(.*[0-9].*)/, "Must Contain a number")
    .matches(/^(.*\W.*)/, "Must Contain a special character"),
  name: string().required("Name required"),
  otp: string().required("OTP Required")

});

const useStyles = makeStyles((theme) => ({
  splitScreen: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',
  },
  leftPane: {
      width: '50%',
      backgroundColor: '#4A3BFF',
      [theme.breakpoints.down("xs")]: {
        display: 'none',
      },
  },
  rightPane: {
      width: '50%',
      backgroundColor: 'white',
      [theme.breakpoints.down("xs")]: {
        width: '100%',
      },
  },
  details: {
    width: '25rem',
    margin: '7rem auto 0',
  },
  logo:{
    width: '6.5625rem',
    height: '2.5625rem',
    margin: '0 auto 1.5rem',
  },
  title: {
    fontSize: '2.625rem',
    fontWeight: 700,
    lineHeight: 'normal',
    letteSpacing: 0,
    textAlign: 'center',
    color: 'black',
    marginBottom: '0.75rem',
  },
  sub_title: {
    color: 'black',
    textAlign: 'center',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  color_gray: {
    color: '#5A5A5A',
    fontWeight: 400,
  },
  
}))

function Authorization() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { state: routeState } = useLocation();

  const [page, setPage] = useState(LOGIN);
  const [formStates, setFormStates] = useState({});

  let ar = location.pathname.split("/");

  const formik = useFormik({
    initialValues: {
      email: '',
      password: 'pwd',
      create_password: 'Pwd@1234',
      name: 'xyz',
      otp: 'otp',
    },
    validationSchema: LoginSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      setFormStates(values);
      if(page === LOGIN){
        handleSubmitOnLogin(values.email);
      }
      else if(page === VERIFY_PASSWORD){
        handleSubmitOnPassword(values.email,values.password);
      }
      else if(page === CREATE_USER){
        handleSubmitOnCreateUser(values.email,values.name,values.password);
      }
      else{

      }
    }
  });
  
  const handleSubmitOnLogin = async (email) => {
    let response = await dispatch(checkUserExistsOrNot(email));
    if (response.status) {
      formik.setFieldValue('password','');
      setPage(VERIFY_PASSWORD);
    }
    else {
      formik.setFieldValue('name','');
      formik.setFieldValue('create_password','');
      setPage(CREATE_USER);
    }
  }

  const handleSubmitOnPassword = (email,password) => {
    dispatch(setTokenAsync(email, password)).then((data) => {
      if (routeState) {
        history.push(`${routeState.from.pathname}`);
      } else {
        try {
          if (ar[1] !== "loginforstamping") {
            throw Error("dd");
          }
          if (isLocalhost) {
            window.location.href =
              "http://localhost:8000/linktodoc/?path=" +
              ar[2] +
              "/" +
              ar[3] +
              "/" +
              ar[4];
          } else {
            window.location.href =
              "https://dev-api-pdf.webnyay.in/linktodoc/?path=" +
              ar[2] +
              "/" +
              ar[3] +
              "/" +
              ar[4];
          }
        } catch {
          history.push("/");
        }
      }
    })
    .catch((error)=>{
      if(error.request.status === 400){
				error = JSON.parse(error?.request?.response);
				formik.setFieldError('password',error?.data?.non_field_errors);
			}
    })
  }

  const handleSubmitOnCreateUser = async (email,name,password) => {
    const isFullname = name.trim()?.split(' ')?.length > 1;
    if(!isFullname){
      formik.setFieldError('name','Full Name Required');
      return;
    }
    let response = await dispatch(sendOtpAsync(email, name));
    if(response?.success === 'Otp sent'){
      formik.setFieldValue('otp','');
      setPage(VERIFY_EMAIL);
    }
  }


  return (
    <>
      <div className={classes.splitScreen}>
        <div className={classes.leftPane}>
          <Graphics/>
        </div>
        <div className={classes.rightPane}>
          <Box className={classes.details}>
            <Box className={classes.logo}>
              <img src={Docs} alt="Docs" width='100%'></img>
            </Box>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Box>
                <Typography className={classes.title}>
                  {page === CREATE_USER ? 'Create your account' : 
                    page === VERIFY_EMAIL ? 'Verify email address' : 'Welcome to Docs'}
                </Typography>
                <Typography className={page === CREATE_USER || page === VERIFY_PASSWORD ? classes.sub_title : `${classes.sub_title} ${classes.color_gray}` }>
                  {
                    page === LOGIN ? 'Enter your account details below' :
                    page === VERIFY_PASSWORD ? `Log in using ${formik.values.email}` :
                    page === CREATE_USER ? `Sign up using ${formik.values.email}` : `Please enter the OTP we've send to`
                  }
                </Typography>
                {
                  page === VERIFY_EMAIL && 
                  <Typography className={`${classes.sub_title}`}>{formik.values.email}</Typography>
                }
              </Box>
              <Box style={{height:'4rem'}}></Box>
              <GetDetails
                page={page}
                setPage={setPage}
                formik={formik}
                sendOtp={handleSubmitOnCreateUser}
                formStates={formStates}
              />
            </form> 
          </Box>
        </div>
      </div>

    </>
  );
}

export default Authorization;
