import React, { createContext, useState, useContext } from "react";
import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";

const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
  const workspaces = useSelector((state) => state?.documentSet?.documentSet?.results, shallowEqual);

  const [workspaceList, setWorkspaceList] = useState('');
  const [workspace, setWorkspace] = useState();
  const [workspaceId, setWorkspaceId] = useState();

	useEffect(()=>{
    if(workspaces){
      setWorkspaceList(workspaces);
    }
	},[workspaces])

  useEffect(()=>{
    if(workspaceId && workspaceList){
      setWorkspace(workspaceList?.filter((workspace)=> workspace?.id===workspaceId)?.[0]);
    }
  },[workspaceId,workspaceList])
	
  return (
    <WorkspaceContext.Provider
      value={{ workspace, setWorkspace, workspaceId, setWorkspaceId, workspaceList, setWorkspaceList }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspace = () => {
  return useContext(WorkspaceContext);
};
