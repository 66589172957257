import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import {
  ARBITRATOR,
  ARBITRATOR_RV,
  MEDIATOR,
  SECRETARIAT,
} from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import DashboardDropDown from "features/header/DashboardDropdown";
import HambergerMenu from "features/header/HamburgerMenu";
import ProfileDropdown from "features/header/ProfileDropdown";
import React, { Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import SearchBar from "shared/SearchBar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: 'white',
    color: 'black',
    borderBottom: `1px solid ${theme.palette.border}`,
    boxShadow: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'flex',
    flexGrow: 1,
    "& img": {
      width: "6rem",
      height: "auto",
      verticalAlign: 'middle',
      
    },
  },
}));

const AppHeader = () => {
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();

  const token = useSelector((state) => state.login.token);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  const isProfessionalAccount =
    profileType === ARBITRATOR ||
    profileType === ARBITRATOR_RV ||
    profileType === MEDIATOR ||
    profileType === SECRETARIAT;

  const loggedIn = token !== "" ? true : false;
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let MenuItems;
  if (loggedIn) {
    MenuItems = (
      <Fragment>
        <ProfileDropdown profile={profile} />
      </Fragment>
    );
  } else {
    MenuItems = (
      <Button
        color="inherit"
        onClick={() => {
          history.push("/login");
        }}
      >
        <FormattedMessage id="auth.login" />
        {/* Login */}
      </Button>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={handleDrawerOpen}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.title}>
            <Link to="/">
              <img
                src="/Docs_logo.svg"
                alt="logo"
                onClick={() => {
                  sessionStorage.clear();
                }}
              />
            </Link>
            <Box width="1rem"></Box>
            <Hidden smDown>
              <Fragment>
                {isProfessionalAccount ? (
                  <DashboardDropDown profileType={profileType} />
                ) : (
                  <Button
                    // color="inherit"
                    onClick={() => {
                      history.push("/");
                      sessionStorage.clear();
                    }}
                    style={{fontSize: '1.2rem'}}
                  >
                    Home

                    {/* Dashboard */}
                  </Button>
                )}
              </Fragment>
            </Hidden>
          </div>
          <Box width="2rem"></Box>
          
          {location.pathname !== '/workspace' ?<SearchBar/> : null}

          <Box width="2rem"></Box>

          <Hidden smDown>{MenuItems}</Hidden>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <HambergerMenu
          open={open}
          handleDrawerClose={handleDrawerClose}
          isProfessionalAccount={isProfessionalAccount}
          profileType={profileType}
        />
      </Hidden>
    </div>
  );
};

export default AppHeader;
