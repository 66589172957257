import {
  Box,
  Grid,
  Hidden,
  IconButton,
  Menu,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Avatar,
} from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import FileImage from "images/file.svg";
import DotsImage from "images/threeDots.svg";
import PdfFileIcon from "images/fileIcon.svg";
import WordFileIcon from "images/word_file_icon.png";
import ExcelFileIcon from "images/excel_file_icon.png";
import ImageFileIcon from "images/image_file_icon.png";
import PowerpointFileIcon from "images/powerpoint_file_icon.png";
import TxtFileIcon from "images/txt_file_icon.png";
import { formatDate } from "utils";
import { deleteFile, saveEditedPdf, UpdateFile } from "../api/FileApi";
// import { StampFile } from "../api/FileApi";
import { hyperlinkFile, DownloadFile } from "../api/FileApi";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import history from "app/history";
import { PdfViewingModal } from "./editModals/PdfViewingModal";
import CustomisedTooltip from "features/CustomisedToolTip/CustomisedToolTip";
import { useSnackbar } from "contexts/SnackbarContext";
import { ERROR, ERROR_TITLE, SUCCESS, SUCCESS_TITLE, WARNING, WARNING_TITLE } from "constants/appConstants";
import CustomizedDialogs from "features/CustomisedDialogbox/CustomisedDialogbox";
import UploadModal from "shared/UploadModal";

export default function FileView({
  file,
  classes,
  reload,
  allRoutes,
  profile,
  view,
  paginationStatus,
  OCRableFiles,
  isRootFolderStamped,
  stampType,
  handleChangeOnFileCheckbox,
  selectedFiles,
}) {
  const allRoutesLength= allRoutes.length;

  const [anchorEl, setAnchorEl] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const {showSnackbar} = useSnackbar();
  const isSelected = selectedFiles?.includes(`${file.id}`);

  const handleClick = (e) => {
    if(OCRableFiles?.status === "OCR in progress" && OCRableFiles?.ocrable_files.find(obj => obj.id == file.id)){
      showSnackbar('The OCR of this file is in progress...\n Please try to use these options atfer OCR is done',WARNING,WARNING_TITLE);
      return;
    }
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModalAndRefresh = () => {
    reload();
    setAnchorEl(null);
  };

  function fileSize(size) {
    size = parseInt(size);
    if (size)
      if (size < 1024) return `${size.toFixed(2)} B`;
      else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
      else if (size < 1073741824) return `${(size / 1048576).toFixed(2)} MB`;
      else return `${(size / 1073741824).toFixed(2)} GB`;
    else return "0 B";
  }

  const handlePdfGetUrlClick = async () => {
    try{
      const res = await DownloadFile(file.id)
      setPdfUrl(res.data.url+`#page=${1}`);
    }catch(err){
      console.log(err)
    }
  };

  let prefix = file.name.split("__")[0] + "__";
  let arr = file.name.split(".");
  let extension = "." + arr[arr.length - 1];
  let localName = file.name;
  localName = localName.replace(extension, "");
  localName = localName.replace(prefix, "");
  const [originalFileNameWithoutExtension, setNameWithoutExtension] =
    useState(localName);
  const [rename, setRename] = useState(false);
  const handleFileNameChange = (e) => {
    setNameWithoutExtension(e.target.value);
  };
  const inputRef = useRef(null);

  function handlePdfClick(){
    localStorage.setItem('fileName', localName);
    history.push(`/view-pdf/${file.id}/${1}/${stampType}`)
  }

  const handleOnBlur = () => {
    if(originalFileNameWithoutExtension?.length > 300){
      showSnackbar('Maximum 300 characters are allowed',ERROR,ERROR_TITLE)
      return;
    }
    setRename(false);
    if (originalFileNameWithoutExtension !== localName) {
      UpdateFile(file.id, {
        name:
          arr.length > 1
            ? prefix + originalFileNameWithoutExtension + extension
            : prefix + originalFileNameWithoutExtension,
      }).then(() => {
        reload();
      });
    }
  };

  const handleRename = () => {
    handleClose();
    setRename(true);
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 100);
  };

  const [isDeleteClicked,setIsDeleteClicked] = useState(false);

  const handleDelete = () => {
    handleClose();
    setIsDeleteClicked(true);
    deleteFile(file.id).then(() => {
      //dispatch();
      reload();
    })
    .catch((error)=>{
      setIsDeleteClicked(false);
      if(error?.response?.status === 424 && error?.response?.data?.error){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    })
  };

  const [isHyperlinkClicked,setIsHyperlinkClicked] = useState(false);
  const handleHyperlink = () => {
    if(!isRootFolderStamped){
      showSnackbar(`Some files are not paginated.\nPlease paginate before hyperlink.`,WARNING,WARNING_TITLE);
      return;
    }
    handleClose();
    setIsHyperlinkClicked(true);
    hyperlinkFile(file.id).then(() => {
      showSnackbar('Hyperlinking is done.',SUCCESS,SUCCESS_TITLE);
    })
    .catch((error)=>{
      setIsHyperlinkClicked(false);
      if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    })
  }

  const handlePdfModal = () => {
    handlePdfGetUrlClick()
    setShowPdfModal(true)
  }

  var prefixes='';
  allRoutes.forEach((x)=>{
    prefixes= prefixes + x.nameToShowUser.split('_')[0] + '/';
  })
  prefixes= prefixes + file.name.split('_')[0];

  const handleClickOnReplace = () => {
    setOpenModal(true);
  }

  const closeModal = () => {
    setOpenModal(false);
  }

  const handleReplaceFile = () => {
    if(files.length === 0){
      return;
    }
    files.map((a)=>{
      setLoading(true);
      try{
        console.log(loading,'loading');
        saveEditedPdf(file.id,a)
        .then((res)=>{
          closeModal();
          setLoading(false);
          showSnackbar('File replaced successfully!', SUCCESS, SUCCESS_TITLE);
          reload();
        })
        .catch((error)=>{
          setLoading(false);
          if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
            showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
          }
        })
      }
      catch(err){
        console.log(err);
        setLoading(false);
      }
    })
  }


  return (
    <>
  
      {view === "list" ? (
          <Grid container className={classes.filesHover} spacing={1} >
            <Grid item lg={4} md={4} sm={4} xs={7} >
              <div style={{display:'flex',alignItems:'center'}}>
                <Checkbox
                  id={file.id}
                  color="primary"
                  className={isSelected?classes.visibility:classes.visibilityHidden}
                  style={{padding:'5px',height:'fit-content'}}
                  checked={isSelected}
                  onChange={handleChangeOnFileCheckbox}
                />
                <FiberManualRecordIcon style={(OCRableFiles?.ocrable_files.find(obj => obj.id == file.id))?{color:'#2f80ed',fontSize:'small'}:{visibility:'hidden',fontSize:'small'}}/>
                <span style={{display: 'inline-block',verticalAlign:'middle'}}>
                  <img 
                    src = {file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? WordFileIcon : file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? ExcelFileIcon 
                    : (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp") ? ImageFileIcon 
                    : file.type === "application/vnd.ms-powerpoint" ? PowerpointFileIcon 
                    : file.type === "application/pdf" ? PdfFileIcon : TxtFileIcon} 
                    style={{width: '16px', height: '20px', verticalAlign: 'bottom'}} 
                    alt="file" 
                  />
                </span>
                {!rename ? (
                  <Typography
                    onClick={handlePdfModal}
                    className={classes.tableContent}
                    style={{ marginLeft: "0.5rem" }}
                  >
                    {file.name}
                  </Typography>
                ) : (
                  <Typography
                    className={classes.tableContent}
                  >
                    <span style={{display: 'inline-block',verticalAlign:'middle'}}>
                      <img 
                        src = {file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? WordFileIcon : file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? ExcelFileIcon 
                        : (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp") ? ImageFileIcon 
                        : file.type === "application/vnd.ms-powerpoint" ? PowerpointFileIcon 
                        : file.type === "application/pdf" ? PdfFileIcon : TxtFileIcon} 
                        style={{width: '16px', height: '20px'}} 
                        alt="file" 
                      />
                    </span>
                    <span style={{ marginLeft: "1rem" }}>
                      {/*  eslint-disable-next-line */}
                      <a href="javascript:;" rel="noopener noreferrer">
                        <input
                          ref={inputRef}
                          value={originalFileNameWithoutExtension}
                          style={{ width: "70%" }}
                          onChange={handleFileNameChange}
                          onBlur={handleOnBlur}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleOnBlur();
                            }
                          }}
                        />
                      </a>
                    </span>
                  </Typography>
                )}
              </div>
            </Grid>
            <Hidden xsDown>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <CustomisedTooltip title={prefixes}>
                  <Typography
                    className={classes.tableContent}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {prefixes}
                  </Typography>
                </CustomisedTooltip>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography
                  className={classes.tableContent}
                >
                  {file.number_of_pages}
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography
                  className={classes.tableContent}
                >
                  {fileSize(file.size)}
                </Typography>
              </Grid>
            </Hidden>
            <Grid item lg={2} md={2} sm={2} xs={4}>
              <Typography
                className={classes.tableContent}
              >
                <div style={{display:'flex',gap:6,alignItems:'center'}}>
                  <Avatar style={{height:30,width:30,backgroundColor:'#FFAB00'}}>
                    {file?.owner_name.charAt(0)}
                  </Avatar>
                  {file?.owner_name}
                </div>
              </Typography>
            </Grid>
            <Hidden xsDown>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                {file?.pagination_end_date && (
                  <CustomisedTooltip title={<div><FiberManualRecordIcon color="secondary" style={{fontSize:'small'}}/> Paginated</div>}>
                    <FiberManualRecordIcon color='secondary' style={{fontSize:'small'}}/>
                  </CustomisedTooltip>
                )}
                {file?.OCR_end_date && (
                  <CustomisedTooltip title={<div style={{alignItems:'center'}}><FiberManualRecordIcon color="primary" style={{fontSize:'small'}}/> OCR done</div>}>
                    <FiberManualRecordIcon color="primary" style={{fontSize:'small'}}/>
                  </CustomisedTooltip>
                )}
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={3}>
                <div style={{display:'flex',gap:'15px'}}>
                  <Typography
                    className={classes.tableContent}
                  >
                    {formatDate(file.modified)}
                  </Typography>
                  <IconButton
                    onClick={handleClick}
                    // onMouseEnter={handleClick}
                    aria-controls={`three-dots-menu${file.id}`}
                    aria-haspopup="true"
                    style={{ padding: 0 }}
                  >
                    <img src={DotsImage} alt="options" />
                  </IconButton>
                </div>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid
                item
                lg={1}
                md={1}
                sm={1}
                xs={1}
                style={{ textAlign: "right" }}
              >
                {/*  eslint-disable-next-line */}
                <a href="javascript:;" rel="noopener noreferrer">
                  <IconButton
                    onClick={handleClick}
                    // onMouseEnter={handleClick}
                    aria-controls={`three-dots-menu${file.id}`}
                    aria-haspopup="true"
                    style={{ padding: 0 }}
                  >
                    <img src={DotsImage} alt="options" />
                  </IconButton>
                </a>
              </Grid>
            </Hidden>
          </Grid>
      ) : (
        <CustomisedTooltip title={prefixes} placement='top' arrow>
          <Grid
            item
            lg={2}
            md={3}
            sm={3}
            xs={6}
            className={classes.gridFilesHover}
          >
            <Card style={{height:'100%'}}> 
                <CardMedia
                  onClick={handlePdfModal}
                  component={"img"}
                  height=""
                  image={file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? WordFileIcon 
                  : file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? ExcelFileIcon 
                  : (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp") ? ImageFileIcon 
                  : file.type === "application/vnd.ms-powerpoint" ? PowerpointFileIcon 
                  : file.type === "application/pdf" ? PdfFileIcon : TxtFileIcon}
                  style={{
                    width: "30%",
                    margin: "auto",
                    padding: '10%'
                  }}
                />
              <CardContent
                style={{ padding: "15px", borderTop: "1px solid #eeeeee" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={10} md={10} sm={10} xs={10}>
                    <div style={{display:'flex',alignItems:'center'}}>
                      <Checkbox
                        id={file.id}
                        color="primary"
                        className={isSelected?classes.visibility:classes.visibilityHidden}
                        style={{padding:'5px',height:'fit-content'}}
                        checked={isSelected}
                        onChange={handleChangeOnFileCheckbox}
                      />
                      <Typography
                        className={classes.tableContent}
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                        onClick={handlePdfModal}
                      >
                        {/* <span style={{display: 'inline-block'}}>
                          <img src={FileImage} alt="folder" style={{verticalAlign: 'bottom'}} />
                        </span> */}
                        <CustomisedTooltip title={file.name}>
                          <span>
                            {!rename ? (
                              file.name
                            ) : (
                              // eslint-disable-next-line
                              <a href="javascript:;" rel="noopener noreferrer">
                                <input
                                  ref={inputRef}
                                  value={originalFileNameWithoutExtension}
                                  style={{ width: "70%" }}
                                  onChange={handleFileNameChange}
                                  onBlur={handleOnBlur}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      handleOnBlur();
                                    }
                                  }}
                                />
                              </a>
                            )}
                          </span>
                        </CustomisedTooltip>
                      </Typography>
                    </div>
                  </Grid>
                  {
                    <Grid
                      item
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ textAlign: "right" }}
                    >
                      <IconButton
                        onClick={handleClick}
                        // onMouseEnter={handleClick}
                        aria-controls={`three-dots-menu${file.id}`}
                        aria-haspopup="true"
                        style={{ padding: 0 }}
                      >
                        <img src={DotsImage} alt="options" />
                      </IconButton>
                    </Grid>
                  }
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </CustomisedTooltip> 
      )}
       
      {showPdfModal &&
        <PdfViewingModal  
          open={showPdfModal} 
          dismiss={() => setShowPdfModal(false)}
          pdfUrl={pdfUrl}
          handlePdfClick={handlePdfClick}
          showEditBtn={true}
          disableEditBtn={!file?.is_searchable}
        />
      }
      <Menu
        aria-controls={`three-dots-menu${file.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box className={classes.menuwidth}>
          <Box>
            <Typography
              className={classes.filesandfolder}
              onClick={handleRename}
              style={
                paginationStatus === 1 ? {display:'none'} : null
              }
            >
              Rename
            </Typography>
            <Typography
              className={classes.filesandfolder}
              onClick={handleDelete}
              style={isDeleteClicked ? {pointerEvents:'none',background:'#6e6e6e',color:'white'} : paginationStatus === 1 ? {display:'none'} : null}
            >
              Delete
            </Typography>
            {
              file.type === "application/pdf" &&
                <CustomisedTooltip title= {file?.is_searchable ?  "" : "Please OCR and then edit"}>
                  <Typography
                    className={file?.is_searchable ? classes.filesandfolder : classes.disableBtn}
                    onClick={file?.is_searchable ? handlePdfClick : () => null}
                  >
                    Edit
                  </Typography>
                </CustomisedTooltip>
            }
            { file.type === "application/pdf" &&
              <Typography
                className={`${classes.filesandfolder} ${
                  file.is_stamped ||
                  file.type !== "application/pdf" ||
                  allRoutesLength !== 1
                    ? "classes.stamped"
                    : ""
                }`}
                style={
                  // !file.is_stamped ||
                  // (file.type !== "application/pdf" &&
                  // file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
                  isHyperlinkClicked
                    ? { background: '#6e6e6e', pointerEvents:'none', color:'white' }
                    : {}
                }
                onClick={handleHyperlink}
              >
                Hyperlink
              </Typography>
            }
            <Typography
              className={classes.filesandfolder}
              onClick={handleClickOnReplace}
              style={ paginationStatus === 1 ? {display:'none'} : null}
            >
              Replace
            </Typography>
          </Box>
        </Box>
      </Menu>
      <CustomizedDialogs
        open={openModal}
        handleClose={closeModal}
        title={'Replace file'}
        buttonText={'Upload'}
        onSubmit={handleReplaceFile}
        isSubmitButtonDisabled={loading || files.length === 0}
        disableBackDropClick={loading}
        isCancelButtonDisabled={loading}
      >
        <UploadModal
          loading={loading}
          setFiles={setFiles}
        />
      </CustomizedDialogs>
    </>
  );
}
