import * as React from 'react';
import {
    Button,
    Dialog,
    Typography,
    IconButton
  } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { makeStyles, withStyles } from '@material-ui/core/styles';  
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme)=>({
  paper: {
    borderRadius: 0,
    minWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '280px',
    },
  },
  button: {
    padding: '0.75rem 1.5rem',
  }
}))

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2,3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    color: '#000',
    fontSize: '1.25rem',
    fontWeight: 700,
  },
  subTitle: {
    color: '#808080',
    fontSize: '0.9rem',
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, subTitle, isCancelButtonDisabled, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other} >
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} disabled={isCancelButtonDisabled}>
          <CloseIcon />
        </IconButton>
      ) : null}
      {subTitle && (
        <Typography className={classes.subTitle}>{subTitle}</Typography>
      )}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2,3),
    borderTop: '1px solid #F3F3F3',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  open, 
  handleClose,
  title,
  subTitle,
  children,
  buttonText,
  onSubmit,
  isSubmitButtonDisabled,
  disableBackDropClick,
  isCancelButtonDisabled,
}) {
  const classes = useStyles();
  const onClose = (event,reason) => {
    if(disableBackDropClick && reason === 'backdropClick'){
      console.log(reason);
    }
    else{
      handleClose();
    }
  }
  return (
    <div>
      <Dialog 
        onClose={onClose} 
        aria-labelledby="customized-dialog-title" 
        open={open} 
        classes={classes}
        disableEscapeKeyDown={disableBackDropClick}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} subTitle={subTitle} isCancelButtonDisabled={isCancelButtonDisabled}>
          {title}
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        {onSubmit && (
          <DialogActions>
            <Button variant='outlined' onClick={handleClose} className={classes.button} style={{border: '1px solid rgba(0, 0, 0, 0.87)'}} disabled={isCancelButtonDisabled}>
              Cancel
            </Button>
            <Button variant='contained' onClick={onSubmit} color='primary' className={classes.button} style={{marginLeft: '1rem'}} disabled={isSubmitButtonDisabled}>
              {buttonText || 'Submit'}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
