import React from "react";
import { useParams } from "react-router-dom";
import { DownloadFile } from "../api/FileApi";

export default function DownloadFileHelper() {
  let { file_id, page_number } = useParams();
  React.useEffect(() => {
    DownloadFile(file_id)
      .then((res) => {
        window.location.href = res.data.url+`#page=${page_number}`;
      })
      .catch((error) => {
        console.log(error);
      });
  }, [file_id]);

  return <div></div>;
}
