import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createProfileIfNot } from "slices/profileSlice";
import {useParams} from 'react-router-dom';
import * as Yup from "yup";
import React from "react";

const DetailsSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string()
    .min(6, "Password Too Short!")
    .max(20, "Password Too Long!")
    .required("Password Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password Required"),
  phoneNumber: Yup.string().required("phone number is required"),
});

const DetailsForm = ({
  profileData: {
    user: { email = "", first_name = "", last_name = "" } = {},
    phone_number = "",
  },
  empty: profileEmpty,
  setStep,
  refresh,
  handleOpenSnackBarAssigned,
  handleOpenSnackBarError,
  setSnackText,
}) => {
  const dispatch = useDispatch();
  let { workspace_id } = useParams();
  workspace_id= parseInt(workspace_id);

  const documentSet = useSelector((state) => state.documentSet, shallowEqual);

  const [phoneError, setPhoneError] = useState();
  const onPhoneChange = (e) => {
    setPhoneError();
    validatePhone(e.target.value);
    formik.setFieldValue("phoneNumber", e.target.value);
  };
  const validatePhone = (v) => {
    let ph = v;
    let countspace = (ph.match(/ /g) || []).length;
    if (countspace > 0) {
      if (countspace > 1) {
        setPhoneError("Invalid Mobile Number. Please only enter numbers.");
        return false;
      } else {
        if (/^\d+$/.test(ph.split(" ")[1])) {
          return true;
        } else {
          setPhoneError("Invalid Mobile Number. Please only enter numbers.");
          return false;
        }
      }
    } else {
      if (/^\d+$/.test(ph)) {
        return true;
      } else {
        setPhoneError("Invalid Mobile Number. Please only enter numbers.");
        return false;
      }
    }
  };

  const debouncedOnSubmit = debounce(async (values) => {
    const profileBody = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      phoneNumber: values.phoneNumber,
      doc_set_id: documentSet?.documentSet?.results?.[0]?.id,
      workspace_id: workspace_id
    };

    try {
      dispatch(createProfileIfNot(profileBody))
        .then((resp) => {
          console.log("resp", resp);
          handleOpenSnackBarAssigned();
          refresh();
          setStep(1);
        })
        .catch((err) => {
          console.error("err.response.data", err?.response?.data);
          if (err?.response?.status === 409 || err?.response?.status === 424) {
            setSnackText(err?.response?.data?.error);
            handleOpenSnackBarError();
            setStep(1);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, 1000);

  const formik = useFormik({
    initialValues: {
      email: email,
      firstName: first_name,
      lastName: last_name,
      password: "Test@123",
      confirmPassword: "Test@123",
      phoneNumber: phone_number,
    },
    onSubmit: debouncedOnSubmit,
    validationSchema: DetailsSchema,
  });

  return (
    <Box p="2rem 0">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Box p="0rem 2rem">
              <form onSubmit={formik.handleSubmit}>
                <Typography variant="h4" gutterBottom align="center">
                  Insert Collaborator Details
                </Typography>
                <Box height="1rem"></Box>

                <TextField
                  variant="outlined"
                  margin="normal"
                  value={formik.values.email}
                  required
                  fullWidth
                  id="email"
                  label={<FormattedMessage id="user.email" />}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={!profileEmpty}
                />
                {formik.errors.email && formik.touched.email && (
                  <Alert severity="error">{formik.errors.email}</Alert>
                )}

                <TextField
                  variant="outlined"
                  margin="normal"
                  value={formik.values.firstName}
                  required
                  fullWidth
                  id="firstName"
                  label={<FormattedMessage id="user.firstName" />}
                  name="firstName"
                  autoComplete="firstName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={!profileEmpty}
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <Alert severity="error">{formik.errors.firstName}</Alert>
                )}
                <TextField
                  variant="outlined"
                  margin="normal"
                  value={formik.values.lastName}
                  required
                  fullWidth
                  id="lastName"
                  label={<FormattedMessage id="user.lastName" />}
                  name="lastName"
                  autoComplete="lastName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={!profileEmpty}
                />
                {formik.errors.lastName && formik.touched.lastName && (
                  <Alert severity="error">{formik.errors.lastName}</Alert>
                )}

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="phoneNumber"
                  label={<FormattedMessage id="user.phoneNumber" />}
                  id="phoneNumber"
                  value={formik.values.phoneNumber}
                  onBlur={onPhoneChange}
                  onChange={onPhoneChange}
                  disabled={!profileEmpty || !!phone_number}
                />
                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                  <Alert severity="error">{formik.errors.phoneNumber}</Alert>
                )}
                {phoneError && <Alert severity="error">{phoneError}</Alert>}

                <Box height="1rem"></Box>

                <Box display="flex" justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    {<FormattedMessage id="buttons.submit" />}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>
    </Box>
  );
};

export default DetailsForm;
