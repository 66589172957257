import React, { RefObject } from 'react';
// import { Dimmer } from 'semantic-ui-react';
// import { Div } from '../ui/components/Div';
import { ConfirmContent } from './ConfirmContent';
import {
  Backdrop,
} from "@material-ui/core";

export const DrawingComponent= ({
  dimmerActive,
  cancelDelete,
  deleteDrawing,
  positionTop,
  positionLeft,
  width,
  height,
  svgRef,
  path,
  stroke,
  strokeWidth,
  handleMouseDown,
  handleMouseMove,
  handleMouseOut,
  handleMouseUp,
  onClick,
  deleteIndex,
  index
}) => {
  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseOut={handleMouseOut}
      // onDoubleClick={onClick}
      onClick={onClick}
      style={{
        position: 'absolute',
        top: positionTop,
        left: positionLeft,
        width,
        height,
        cursor: 'move',
        border: deleteIndex === index ? '2px solid blue': '',
      }}
    >
      {/* <div onConfirm={deleteDrawing}>Delete</div> */}
      <div dimmed={dimmerActive}>
        <svg ref={svgRef}>
          <path
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={stroke}
            fill="none"
            d={path}
          />
        </svg>
        {/* <Backdrop active={dimmerActive} onClickOutside={cancelDelete}>
          <ConfirmContent
            title="Delete?"
            onConfirm={deleteDrawing}
            onDismiss={cancelDelete}
          />
        </Backdrop> */}
      </div>
    </div>
  );
};
