import instance from "api/globalaxios";
import history from "app/history";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { PdfViewingModal } from "./editModals/PdfViewingModal";
import { Box, Typography, Button } from "@material-ui/core";
import FallbackWithCustomMessages from "features/fallBack/FallBackWithCustomMessages";

export default function StampingTokenHelper() {
  const location = useLocation();
  const dispatch = useDispatch();

  let ar = location.pathname.split("/");
  const [pdfUrl, setPdfUrl] = useState('')
  const [showPdfModal, setShowPdfModal] = useState(false);
  const workspaceId = ar[2];

  useEffect(()=>{
    if (ar[3]) {
      let p = "" + ar[3];
      for (let index = 4; index < ar.length; index++) {
        p += '/' + ar[index]      
      }
      p = p.toUpperCase()
      dispatch(getLinkofDoc(p, workspaceId)).then((res) => {
        setShowPdfModal(true);
        setPdfUrl(res.url);
      }).catch(error=>{
        console.log('error.response.data.error', error.response.data.error)
        setError(error.response.data.error)
      });
    }
    // eslint-disable-next-line
  },[])

  const [error, setError] = useState()

  

  function getLinkofDoc(path, workspaceId) {
    return async () => {
      try {
        let response = await instance({
          method: "get",
          url: `/file-presiged-url-for-path/${workspaceId}/?path=` + path,
        });
        return response.data;
        // console.log(response.data)
      } catch (error) {
        console.log(error);
        throw error;
      }
    };
  }

  function handlePdfClick(){
    // console.log(res,'res')
    // history.push(`/view-pdf/${file.id}/${1}/${localName}`)
  }

  function handlePdfModalClose(){
    setShowPdfModal(false);
    history.goBack();
  } 


  if(error){
    return (
      <FallbackWithCustomMessages
        ErrorMessage={error}
        ButtonText='Go Back'
      />
    );
  }

  return (
    showPdfModal &&
      <PdfViewingModal  
        open={showPdfModal} 
        dismiss={handlePdfModalClose}
        pdfUrl={pdfUrl}
        handlePdfClick={handlePdfClick}
      />
  );
}
