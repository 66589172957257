import { useState, useCallback, useEffect } from 'react';
import { save } from '../utils/pdfSave';
import { useSnackbar } from 'contexts/SnackbarContext';
import { WARNING, WARNING_TITLE } from 'constants/appConstants';

export const usePdf = () => {
  const [name, setName] = useState('');
  const [pageIndex, setPageIndex2] = useState(-1);
  const [dimensions, setDimensions] = useState();
  const [file, setFile] = useState();
  const [pages, setPages] = useState([]);
  const [isMultiPage, setIsMultiPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [fileIdPdf,setFileIdPdf] = useState();
  const [savingLoader, setSavingLoader] = useState(false);
  const [paginationType, setPaginationType] = useState();
  const currentPage = pages[pageIndex];

  const {showSnackbar} = useSnackbar();

  const setDimensionsHandler = useCallback(setDimensions, [setDimensions]);
  const isValid = arrayOfArray => arrayOfArray.every(arr => Array.isArray(arr) && arr.length === 0)

  const nextPage = () => {
    const newPageIndex = pageIndex + 1;
    setPageIndex2(pageIndex + 1);
    setIsFirstPage(newPageIndex === 0);
    setIsLastPage(newPageIndex === pages.length - 1);
  };

  const previousPage = () => {
    const newPageIndex = pageIndex - 1;
    setPageIndex2(newPageIndex);
    setIsFirstPage(newPageIndex === 0);
    setIsLastPage(newPageIndex === pages.length - 1);
  };

  const initialize = ({ name, file, pages: _pages }, file_id, stamp_type) => {
    const multi = _pages.length > 1;
    setName(name);
    setFile(file);
    setPages(_pages);
    setPageIndex2(0);
    setIsMultiPage(multi);
    setIsFirstPage(true);
    setIsLastPage(_pages.length === 1);
    setFileIdPdf(file_id);
    setPaginationType(stamp_type);
  };

  const savePdf = async (attachments) => {
    if (isSaving || !file) return;
    if(isValid(attachments)) {
      showSnackbar('Edit Pdf before saving',WARNING,WARNING_TITLE);
      return;
    }

    setIsSaving(true);
    try {
      await save(file, attachments, name, fileIdPdf, setSavingLoader, paginationType,showSnackbar);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  return {
    currentPage,
    dimensions,
    setDimensions: setDimensionsHandler,
    name,
    setName,
    pageIndex,
    setPageIndex2,
    file,
    setFile,
    nextPage,
    pages,
    savePdf,
    initialize,
    isMultiPage,
    previousPage,
    isFirstPage,
    isLastPage,
    isSaving,
    savingLoader,
    setPaginationType,
    setIsFirstPage,  
    setIsLastPage
  };
};
