import { IconButton,Button,Avatar } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout, setLogOut } from "slices/loginSlice";

// const options = ["Profile", "Logout"];

const options = [
  <FormattedMessage id="user.profile" />,
  <FormattedMessage id="auth.logout" />,
];

const StyledMenu = withStyles({
  list: { padding: "0" },
  paper: { borderRadius: "0", width: "8%" },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    padding: "0.8rem",
    color: theme.palette.primary.main,
    "&:focus": {
      color: "#fff",

      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#fff",
      },
    },
    "&&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  list: {
    padding: "0",
  },
  themeColor: {
    backgroundColor: theme.palette.primary.main
  }
}));

export default function ProfileDropdown({ profile }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const history = useHistory();
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.root}>
      <IconButton
        aria-controls="lock-menu" 
        aria-haspopup="true"
        color="inherit"
        data-testid="profile-drop"
        onClick={handleClickListItem}
      >
        <Avatar className={classes.themeColor}>{`${profile?.user?.first_name?.charAt(0)}${profile?.user?.last_name?.charAt(0)}`}</Avatar>
      </IconButton>
      {/* <Button
        aria-controls="lock-menu"
        aria-haspopup="true"
        // color="inherit"
        data-testid="profile-drop"
        onClick={handleClickListItem}
      >
        Profile
      </Button> */}
      <StyledMenu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <StyledMenuItem
            key={index}
            selected={index === selectedIndex}
            onClick={(event) => {
              handleMenuItemClick(event, index);
              if (index === 0) {
                history.push(`/profile`);
              } else {
                dispatch(logout());
                dispatch(setLogOut());
                history.push("/login");
              }
            }}
          >
            {option}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
