import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

export default function FullPageSpinner({isTextMessage = false, textLoaderMessage=""}) {
  return (
    <Box
      height="60vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
      {isTextMessage ? <div style={{marginLeft:'0.7rem'}}>{textLoaderMessage}</div> : null }
    </Box>
  );
}
