import React, {useState,useCallback,useEffect,useRef} from "react";
import { 
	Select, 
	TextField,
	InputAdornment,
	FormControl,
	InputLabel,
	MenuItem,
	makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import SearchIcon from 'images/searchIcon.svg';
import { debounceSearch } from "utils";
import { useQuery, useInfiniteQuery } from "react-query";
import { findSearchByFileName, findSearchByPagination, findSearchInFiles, DownloadFile } from "api/FileApi";
import { useSelector,shallowEqual } from "react-redux";
import { PdfViewingModal } from "newFileStructure/editModals/PdfViewingModal";
import { useSnackbar } from "contexts/SnackbarContext";
import { ERROR, ERROR_TITLE } from "constants/appConstants";
import { useWorkspace } from "contexts/WorkspaceContext";

const useStyles = makeStyles((theme)=>({
	searchWrap: {
    marginLeft: 0,
    width: '100%',
  },
  suggestionWrap: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    width: '308px',
    boxShadow: '0px 0px 8px #ddd',
    borderRadius: '6px',
    overflowY: 'scroll',
    maxHeight: '300px',
    zIndex: '100',
  },
  search: {
    padding: '8px 10px',
    borderBottom: '1px solid #F3F3F3',
    "&:hover": {
      backgroundColor: '#efefef',
      cursor: 'pointer'
    }
  },
  selectWrap: {
    display: 'flex',
    flexDirection:'row',
    alignItems: 'flex-end'
  },
  suggestionHeader: {
    padding: '8px 10px',
    color: 'gray',
    borderBottom: '1px solid #e2e2e2',
  },
}))

export default function SearchBar({

}){
	const classes = useStyles();
  const { workspaceId } = useWorkspace();
  
	const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);
  const [searchText , setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchType,setSearchType] = useState('byFileName');
  const [searchValidtionError, setSearchValidationError] = useState(null);
	const [highlighting, setHighlighting] = useState({});
	const [startPageNumber , setStartPageNumber ] = useState(0);
  const [endPageNumber , setEndPageNumber ] = useState(50);
	const [pdfUrl, setPdfUrl] = useState('');
  const [showPdfModal, setShowPdfModal]= useState(false);
	const suggestionsContainerRef = useRef(null);
	let fetching = false;

  const {showSnackbar} = useSnackbar();

	function handleClickOutside(e) {
    if (suggestionsContainerRef.current && !suggestionsContainerRef.current.contains(e.target)) {
        setIsSuggestionsOpen(false);
    }
  }

	const onScroll = async (event) => {
		const { scrollHeight, scrollTop, clientHeight } = event?.target;
		if (!fetching && (scrollHeight - scrollTop <= clientHeight*1.5)) {
			fetching = true;
			await fetchNextPage();
			fetching = false;
		}
	};

	const { data, hasNextPage, fetchNextPage } = useInfiniteQuery( ["search-data"], ({ pageParam = 50, pageParam2= 100  }) => findSearchInFiles(searchText, workspaceId, pageParam, pageParam2),
    {
      getNextPageParam: (lastPage, pages, pageParam2) => {
        // console.log(lastPage, pages, 'pagesInTab' , pageParam2)
        // setStartPageNumber(pageParam2);
        // setEndPageNumber(pageParam2 + 50)
        // const maxPages = lastPage?.data?.total_results; // 147-97 = 50
        // const nextPage= pages?.length + 1;
        // console.log(startPageNumber,endPageNumber, maxPages,nextPage)
        // return endPageNumber <= maxPages ? nextPage : undefined;
      },
      enabled : false,
    }
  );

	useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
        document.removeEventListener('click', handleClickOutside);
    }
	}, []);

  useEffect(() => {
    if(data && searchType === 'byFileContent'){
      setHighlighting(data?.pages?.[0]?.data?.highlighting);
      setSearchResults(data?.pages?.[0]?.data?.results);
    }
  },[data])

	const handleSearch = (value, searchType) => { 
    setIsSuggestionsOpen(true);
    setSearchText(value);
    if(workspaceId)
      fetchSearchData(value, searchType);
  }

	const handleSelectSearchType = (event) => {
    setSearchType(event.target.value)
  }
	const handleChangeOnSearch = (event) => {
    let searchValue = event.target.value;
    setSearchResults([]);
    setSearchValidationError(null);
    if(searchValue === ''){
      setIsSuggestionsOpen(false);
    }
    else{
      optimisedSearchUsingDebounce(searchValue, searchType)
    }
  }
	useEffect(()=>{
    if(workspaceId){
      fetchSearchData(searchText,searchType)
    }
  },[searchType, searchText])

  const fetchSearchData = (value, searchType) => {
    let updatedSearchValueByFile = value.replaceAll(/[^\w\s]/gi, "");
    if(updatedSearchValueByFile?.trim()?.length === 0) return;

    let updatedValue = value.replaceAll(/[^\w\s]/gi, "-");
    if(searchType === 'byFileName'){
      findSearchByFileName(updatedSearchValueByFile, startPageNumber, endPageNumber, workspaceId)
      .then((res)=>{
        setSearchResults(res.data.results);
      })
      .catch((error)=>{
        if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
          if(error?.response?.status === 204){
            setSearchValidationError(error?.response?.data?.error);
          }
          else{
            showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
          }
        }
      })
    }else if(searchType === 'byPage'){
      findSearchByPagination(updatedValue, workspaceId)
      .then((res)=>{
        setSearchResults(res.data);
      })
      .catch((error)=>{
        if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
          if(error?.response?.status === 400){
            setSearchValidationError(error?.response?.data?.error);
          }
          else{
            showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
          }
        }
      })
    }else {
      findSearchInFiles(updatedSearchValueByFile, startPageNumber, endPageNumber, workspaceId)
      .then((res)=>{
        setSearchResults(res.data.results);
        setHighlighting(res.data.highlighting)
      })
      .catch((error)=>{
        if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
          if(error?.response?.status === 204){
            setSearchValidationError(error?.response?.data?.error);
          }
          else{
            showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
          }
        }
      })
    }
  }
	function handleClickOnSearch(fileId, page_number){
    if(fileId){
      handlePdfClick(fileId, page_number);
    }
  }
	const handlePdfClick = async (fileId, page_number) => {
		try{
			const res = await DownloadFile(fileId)
			setPdfUrl(res.data.url+`#page=${page_number}`);
			setShowPdfModal(true);
		}catch(err){
			console.log(err)
		}
	};

  const optimisedSearchUsingDebounce = useCallback(debounceSearch(handleSearch), [])


	return (
		<>
			<div className={classes.selectWrap}>
				<div >
					<div className={classes.searchWrap}>
						<TextField 
							type="text"
							variant="outlined"
							size="small"
							placeholder="Enter To Search"
							onChange={handleChangeOnSearch}
							// onFocus={()=>setIsSuggestionsOpen(true)}
							// onClick={setIsSuggestionsOpen(true)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<img src={SearchIcon} alt="search" height='20px' width='20px'></img>
									</InputAdornment>
								),
							}}
						/>
						<FormControl size='small'>
							<Select
								variant="outlined"
								value={searchType}
								onChange={(event)=>handleSelectSearchType(event)}
							>
								<MenuItem value={'byFileName'}>By File Name</MenuItem>
								<MenuItem value={'byPage'}>By Page</MenuItem>
								<MenuItem value={'byFileContent'}>By File Content </MenuItem>
							</Select>
						</FormControl>
					</div>
					{isSuggestionsOpen && (searchResults || searchValidtionError) ? 
						searchResults.length !== 0 ? 
						<div className={classes.suggestionWrap} ref={suggestionsContainerRef} onScroll={onScroll}>
							{searchResults?.length !== 0 ? <div className={classes.suggestionHeader} >{searchResults?.length} result(s) found:</div> : null}
							{searchType === 'byFileName' && searchResults?.map((result)=>{
								return(
									<div className={classes.search} onClick={()=> handleClickOnSearch(result.file_id,1)}>{result.file_name}</div>
								)
							})}
							{searchType === 'byPage'&& searchResults.length !== 0  &&
								<Link target="_blank" to={{pathname: `${searchResults.link}`}}>
									<div className={classes.search}>{searchResults.file_name}</div>
								</Link>
							}
							{searchType === 'byFileContent' && 
								Object?.entries(highlighting)?.map(([hightlightId, highlight]) => {
									return searchResults?.map((result) => {
									if(result.id === hightlightId){
										return(
											<div onClick={()=> handleClickOnSearch(result.file_id,result?.page_number?.[0])}>
												<div style={{color: 'red',padding:'8px 10px'}}>{result.file_name}</div>
												<div className={classes.search} dangerouslySetInnerHTML={{__html: highlight.page_text}}></div>
											</div>
										)
									}
								})
							})}
						</div>
						: searchValidtionError ? 
						<div className={classes.suggestionWrap} style={{color:'red',padding:'8px 10px'}}>{searchValidtionError}</div>
						: <div className={classes.suggestionWrap} style={{padding:'8px 10px'}}>No Results Found</div>
						: null
					}
				</div>
			</div>
			{showPdfModal &&
        <PdfViewingModal  
          open={showPdfModal} 
          dismiss={() => setShowPdfModal(false)}
          pdfUrl={pdfUrl}
          // handlePdfClick={handlePdfClick}
        />
      }
		</>
	)
}