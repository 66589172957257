import { Button, Box, Grid } from '@material-ui/core';
import React from 'react';
import { downloadFromS3 } from 'api/FileApi';
import Swal from 'sweetalert2';
import { useSnackbar } from 'contexts/SnackbarContext';
import { SUCCESS, SUCCESS_TITLE } from 'constants/appConstants';

function SpecialDownload(){
  const {showSnackbar} = useSnackbar();
  const handleDownload = () => {
    Swal.fire({  
      position: 'top',   
      text: 'Download initiated successfully.\n Please check after 30 minutes for the downloaded files.', 
      color: '#148f18', 
      showConfirmButton: false,  
      timer: 2000  
    });  
    downloadFromS3()
    .then((res)=>{
      showSnackbar('Download completed successfully',SUCCESS,SUCCESS_TITLE);
      console.log('Download from S3 response: ',res);
    })
    .catch((error)=>{
      console.log(error);
    })
  }
  return (
    <>
      <Box style={{width: '40%', margin: '5rem auto', textAlign: 'center'}}>
        <Grid container justifyContent='center' direction='column' spacing={5}>
          <Grid item>
            <Button variant='contained' style={{textTransform: 'none', padding: '1rem 2rem', color: 'blue'}} onClick={handleDownload}>
              Download
            </Button>
          </Grid>
          {/* <Grid item>
            <Link to="/" style={{ textDecoration: "None" }}>
              <Button variant="outlined" color="primary" style={{padding:'0.7rem 1rem'}}>
                Go Back
              </Button>
            </Link>
          </Grid> */}
        </Grid>
        
      </Box>
    </>
  )
}

export default SpecialDownload;