import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { useBackground } from "contexts/BackgroundContext";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import Fallback from "features/fallBack";
import ForgotPassword from "features/ForgotPassword";
import ForgotPasswordResult from "features/ForgotPasswordResult";
import Header from "features/header";
import LinkedinCallback from "features/linkedinCallback";
import Login from "features/login";
import NotFound from "features/notFound";
import Profile from "features/profile";
import ResetPassword from "features/ResetPassword";
import ResetPasswordResult from "features/ResetPasswordResult";
import Signup from "features/signup";
import DownloadFile from "newFileStructure/DownloadFileHelper";
import NewFileContainer from "newFileStructure/NewFileContainer";
import React, {useState, useEffect} from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { isLoggedIn } from "slices/loginSlice";
import StampingTokenHelper from "newFileStructure/StampingTokenHelper";
import Invite from "newFileStructure/Invite";
import PdfViewer from "newFileStructure/PdfViewer";
import FallbackWithCustomMessages from "features/fallBack/FallBackWithCustomMessages";
import SpecialDownload from "newFileStructure/SpecialDownload";
import DropboxAuthorised from "newFileStructure/DropboxAuthorised";
import Authorization from "features/Authorization/Authorization";
import Workspace from "newFileStructure/Workspace";

export const isLocalhost = true;
// export const isLocalhost = false;

const useStyles = makeStyles((theme) => {
  return {
    lightBlue: {
      backgroundColor: theme.palette.common.lightBlue,
    },
    white: {
      backgroundColor: "white",
    },
    default: {
      backgroundColor: theme.palette.common.white,
    },
  };
});

function Routes() {
  const classes = useStyles();
  const location = useLocation();

  const { background } = useBackground();
  const profile = useSelector((state) => state.profile, shallowEqual);
  const {
    profile: { profile_type: profileType },
  } = useSelector((state) => state.profile, shallowEqual);

  const documentSet = useSelector((state) => state.documentSet, shallowEqual);
  console.log("profile", profile);
  console.log("location", location);
  console.log('documentSet ',documentSet, profileType);

  const LoadingComponent = () => {
    const history = useHistory();
    if (!documentSet.loading) { //removed profile_type
      history.push("/");
    }
    return <CircularCenteredLoader />;
  };


  return (
    <React.Fragment>
      <div className={classNames("content", { [classes[background]]: true })}>
        {isLoggedIn() !== '' && <Header position="fixed" />}
        <Switch>
          <PrivateRoute path="/loading" exact>
            <LoadingComponent />
          </PrivateRoute>
          
          <PrivateRoute path="/workspace" exact>
            <Workspace/>
          </PrivateRoute>

          <PrivateRoute path="/folder/:folder_id/:workspace_id" exact>
            {/* <FilesContainer /> */}
            <NewFileContainer />
          </PrivateRoute>

          <PrivateRoute path="/openfile/:file_id/:page_number" exact>
            <DownloadFile />
          </PrivateRoute>

          <PrivateRoute path="/view-pdf/:file_id/:page_number/:stamp_type" exact>
            <PdfViewer />
          </PrivateRoute>

          <PrivateRoute path="/invite" exact>
            <Invite />
          </PrivateRoute>

          <Route path="/linkedin-callback" exact>
            <LinkedinCallback />
          </Route>

          <PrivateRoute path="/profile" exact>
            <Profile />
          </PrivateRoute>

          <PrivateRoute path='/dropbox/authorised' >
            <DropboxAuthorised/>
          </PrivateRoute>

          <PrivateRoute path="/stampinghelper/:workspaceId/:bundle/:tab">
            <StampingTokenHelper />
          </PrivateRoute>

          <PrivateRoute path="/downloadWithFolderStructure">
            <SpecialDownload />
          </PrivateRoute>

          <GuestRoute path="/loginforstamping/:bundle/:tab/:page" exact>
            <Authorization />
          </GuestRoute>
          <GuestRoute path="/login" exact>
            <Authorization />
          </GuestRoute>

          <GuestRoute path="/forgot-password" exact>
            <ForgotPassword />
          </GuestRoute>

          <GuestRoute path="/reset-password" exact>
            <ResetPassword />
          </GuestRoute>

          <GuestRoute path="/forgot-password-confirm" exact>
            <ForgotPasswordResult />
          </GuestRoute>

          <GuestRoute path="/reset-password-confirm" exact>
            <ResetPasswordResult />
          </GuestRoute>

          {/* <GuestRoute path="/signup" exact>
            <Signup />
          </GuestRoute> */}

          <Route path="/fallback" exact>
            {/* <Fallback /> */}
            <FallbackWithCustomMessages />
          </Route>

          {isLoggedIn() !== "" ? (
            <PrivateRoute path="/" exact>
              {documentSet.loading? (
                <Redirect to={{ pathname: "/loading" }} />
              ) : (
                // <Redirect
                //   to={{
                //     pathname: `/folder/${documentSet?.documentSet?.results?.[0]?.root_folder?.id}`,
                //     state: location?.state,
                //   }}
                // />
                <Redirect 
                  to={{
                    pathname: `/workspace`,
                    state: location?.state
                  }}
                />
              )}
            </PrivateRoute>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          )}

          <Route path="/*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
}

function GuestRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn() === "" ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn() !== "" ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default Routes;
