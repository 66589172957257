import { Box, Button, Typography } from "@material-ui/core";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { Link } from "react-router-dom";
import { GENERIC_MSG } from "constants/appConstants";

export default function FallbackWithCustomMessages({ErrorMessage, ButtonText}) {
  
  return (
    <Box height="80vh" width="60vw" margin="auto" textAlign="center">
      <Box height="20vh"></Box>
      <Typography style={{color:'black',fontSize:'1.4rem'}} dangerouslySetInnerHTML={{ __html: ErrorMessage ? ErrorMessage : GENERIC_MSG }} >
      </Typography>
      <Box height="3rem"></Box>
      <Link to="/" style={{ textDecoration: "None" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            sessionStorage.clear();
          }}
          style={{padding:'0.9rem 2rem', fontFamily: 'Satoshi-Medium'}}
        >
          {
            ButtonText ? ButtonText : 'Back to Home'
          }
        </Button>
      </Link>
    </Box>
  );
}
