import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { errorInterceptor } from "api/globalaxios";
import { IntlProviderWrapper } from "contexts/IntlContext";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import theme from "theme";
import App from "./App";
import store from "./app/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { prepareAssets } from '../src/utils/prepareAssets';
import "./index.css";
import { SnackbarProvider } from "contexts/SnackbarContext";
import { WorkspaceProvider } from "contexts/WorkspaceContext";

prepareAssets();
errorInterceptor(store);
const queryClient = new QueryClient();

const Main = () => (
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <IntlProviderWrapper>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <SnackbarProvider>
                <WorkspaceProvider>
                  <App />
                </WorkspaceProvider>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </IntlProviderWrapper>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(<Main />, document.getElementById("root"));
