import React from 'react';
import {makeStyles } from "@material-ui/core";
import Ellipsis from './Ellipsis';

const useStyles = makeStyles((theme) => ({
    ellipsisContainer: {
        position: 'absolute',
        zIndex: 1,
        cursor: 'pointer'
    },
    actionsContainer: {
        width: '144px',
        padding: '15px 0px 7px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ellipsisContainer: {
        marginLeft: '20px',
    },
    contextMenu:  {
        padding: '15px',
        background: 'white',
        boxShadow: '0px 2px 25px rgba(0, 0, 0, 0.15)',
        borderRadius: '4px',
    }
}));

function TableRowActionBtns({
    hideEllipsis = false,
    isThreeDotsClicked,
    activeEllipsisColor,
    ellipsisAriaLabel = "",
    menuAriaLabel = "",
    threeDotsRef,
    onThreeDotsClick,
    actionButtons,
    children,
}) {
    const classes = useStyles();

    return (
        <div >
            {actionButtons}
            {
                !hideEllipsis ?
                    <Ellipsis
                        isThreeDotsClicked={isThreeDotsClicked}
                        activeEllipsisColor={activeEllipsisColor}
                        containerClassName={classes.ellipsisContainer}
                        ariaLabel={ellipsisAriaLabel}
                        threeDotsRef={threeDotsRef}
                        onEllipsisClick={onThreeDotsClick}
                    >
                        <div
                            className={classes.contextMenu}
                            style={{ "--themeColor": activeEllipsisColor }}
                            role="menubar"
                            aria-label={menuAriaLabel}
                        >
                            {children}
                        </div>
                    </Ellipsis>
                    : null
            }
        </div>
    )
}

export default TableRowActionBtns;