import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";

export const initialState = {
  loading: false,
  hasErrors: false,
  questions: [],
};

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.questions = payload.results;
      state.loading = false;
      state.hasErrors = false;
    },
    setError: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { setProgress, setSucessfull, setError } = questionsSlice.actions;
export default questionsSlice.reducer;

export function getQuestions(company_product, case_file_type) {
  console.log("In questisons");
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: "get",
      url: "/question/",
      params: {
        company_product: company_product,
        case_file_type: case_file_type,
      },
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);
        // console.log(error.request.status);
        // console.log(error.message)
        // console.log(error.request.response)
        dispatch(setError);
      }
    );
  };
}

export function getQuestionsAnonymous(company_product, case_file_type, email) {
  console.log("In questisons");
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: "get",
      url: "/anonymousquestions/",
      params: {
        company_product: company_product,
        case_file_type: case_file_type,
        email: email,
      },
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);
        // console.log(error.request.status);
        // console.log(error.message)
        // console.log(error.request.response)
        dispatch(setError);
      }
    );
  };
}
