import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";
import { auth } from "app/firebase";
import { getProfile } from "slices/profileSlice";
import { getUser } from "slices/userSlice";
import { getDocumentSet } from "./documentSetSlice";
import { getChatAuthToken, setChatAuthToken } from "utils/localStorage";

export const initialState = {
  loading: false,
  hasErrors: false,
  token: isLoggedIn(),
  chatToken: returnChatCredentials(1),
  chatId: returnChatCredentials(2),
  errors: {},
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginProgress: (state) => {
      state.loading = true;
    },
    setTokenSucessfull: (state, { payload }) => {
      login(payload);
      state.token = payload.token;
      state.chatToken = payload.chat_token;
      state.chatId = payload.chat_user_id;
      state.loading = false;
      state.hasErrors = false;
    },
    setChatToken: (state, { payload }) => {
      state.chatToken = payload.chatToken;
      state.chatId = payload.userId;
      state.loading = false;
      state.hasErrors = false;
    },
    setLoginError: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errors = payload;
    },
    setLogOut: (state) => {
      state.loading = false;
      state.token = "";
      state.chatToken = "";
      state.chatId = "";
      state.hasErrors = false;
    },
  },
});

export const {
  setLoginProgress,
  setTokenSucessfull,
  setLoginError,
  setLogOut,
  setChatToken,
} = loginSlice.actions;
export default loginSlice.reducer;

export function setTokenAsync(username, password) {
  return async (dispatch) => {
    dispatch(setLoginProgress());

    try {
      let response = await instance({
        method: "post",
        url: "/token-auth/",
        data: {
          email: username,
          password: password,
        },
      });
      document.cookie =
        "pdfToken=" +
        response.data.token +
        ";expires=Thu, 2 Aug 9001 20:47:11 UTC; path=/";
      dispatch(setTokenSucessfull(response.data));
      dispatch(getUser());
      dispatch(getProfile());
      dispatch(getDocumentSet());
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        dispatch(setLoginError(JSON.parse(error.request?.response)));
        if(!JSON.parse(error.request?.response)?.data?.non_field_errors && JSON.parse(error.request?.response)?.error){
          alert(JSON.parse(error.request.response).error);
        }
      }
      throw error;
    }
  };
}

export function sendOtpAsync(email, firstName) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "post",
        url: "/api/get-otp-email/",
        data: {
          email: email,
          name: firstName,
        },
      });
      console.log("otp message", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        dispatch(setLoginError(JSON.parse(error.request.response)));
      }
      throw error;
    }
  };
}

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("chatToken");
  localStorage.removeItem("userId");
  document.cookie = "pdfToken=;";
  socialLogout();
  return {
    type: "USER_LOGGED_OUT",
  };
}

export function socialLogout() {
  return auth.signOut();
}

function login(payload) {
  localStorage.setItem("token", payload.token);
  document.cookie =
    "pdfToken=" + payload.token + ";expires=Thu, 2 Aug 9001 20:47:11 UTC;";
  setChatAuthToken(payload.chat_token, payload.chat_user_id);
}

export function isLoggedIn() {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  } else {
    return "";
  }
}

export function returnChatCredentials(type) {
  const { token, userId } = getChatAuthToken();
  return type === 1 ? token : userId;
}

export function changePassword(currentPassword, newPassword) {
  return async (dispatch) => {
    try {
      await instance({
        method: "patch",
        url: "/api/changepassword/",
        data: {
          currentPassword,
          newPassword,
        },
      });

      dispatch(getUser());
      dispatch(getProfile());
      dispatch(getDocumentSet());
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        throw error.request.response;
        // dispatch(setLoginError(JSON.parse(error.request.response)));
      }
    }
  };
}

export function socialAuth({ idToken }) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "post",
        url: "/api/social",
        data: { idToken },
      });

      console.log(response.data);
      dispatch(setTokenSucessfull(response.data));
      dispatch(getUser());
      dispatch(getProfile());
      dispatch(getDocumentSet());
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        throw error.request.response;
        // dispatch(setLoginError(JSON.parse(error.request.response)));
      }
    }
  };
}

export async function getLinkedinCustomToken({ code, state }) {
  try {
    let response = await instance({
      method: "get",
      url: `/api/linkedin-callback`,
      params: { code, state },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.request.status === 400) {
      throw error.request.response;
      // dispatch(setLoginError(JSON.parse(error.request.response)));
    }
  }
}

export async function verifyResetToken({ token }) {
  try {
    let response = await instance({
      method: "post",
      url: `/api/password_reset/validate_token`,
      params: { token },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.request.status === 400) {
      throw error.request.response;
      // dispatch(setLoginError(JSON.parse(error.request.response)));
    }
  }
}

export async function resetPassword({ email, token, password }) {
  try {
    let response = await instance({
      method: "post",
      url: `/api/reset_password/change`,
      data: { token, password, email },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.request.status === 400) {
      throw error.request.response;
      // dispatch(setLoginError(JSON.parse(error.request.response)));
    }
  }
}

export function forgotPassword(email) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "post",
        url: "/api/password_reset/",
        data: {
          email,
        },
      });

      return response.data;

      // dispatch(getUser());
      // dispatch(getProfile());
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        // throw error.request.response;
        dispatch(setLoginError(JSON.parse(error.request.response)));
      }
    }
  };
}

export function checkUserExistsOrNot(email){
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "get",
        url: `/api/check-user-exists-or-not/${email}/`,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        // throw error.request.response;
        // dispatch(setLoginError(JSON.parse(error.request.response)));
      }
    }
  }
}
