import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { importFromDrives } from "api/FileApi";
import { DEV_REDIRECT_URI, DROPBOX, DROPBOX_APP_KEY, DROPBOX_APP_SECRET, ERROR, ERROR_TITLE, INFO, INFO_TITLE, PROD_REDIRECT_URI } from "constants/appConstants";
import Axios from "axios";
import { useSnackbar } from "contexts/SnackbarContext";
import { REDIRECT_URI } from "api/globalaxios";

export default function DropboxAuthorised(){
  const history = useHistory();
  const {showSnackbar} = useSnackbar();
  let url = new URL(window.location.href);
  const code = (url.searchParams.get('code'));
  const error = (url.searchParams.get('error'));
  const PLATFORM = DROPBOX;
  const import_info = JSON.parse(sessionStorage.getItem('import_info'));
  const workspaceId = localStorage.getItem('workspaceId');
  const path = workspaceId ? `/folder/${import_info?.parent_folder}/${workspaceId}/` : '/' ;
  // let REDIRECT_URI;

  // if (window.location.host == "app-doc.webnyay.in" || window.location.host == "docs.webnyay.ai") {
  //   REDIRECT_URI = PROD_REDIRECT_URI;
  // } else {
  //   REDIRECT_URI = DEV_REDIRECT_URI;
  // }
  // // REDIRECT_URI = 'http://localhost:3000/dropbox/authorised';
  
  useEffect(()=>{
    if(!error){
      const data = {
        code: code,
        grant_type:'authorization_code',
        redirect_uri: REDIRECT_URI,
        client_id: DROPBOX_APP_KEY,
        client_secret: DROPBOX_APP_SECRET,
      }
      Axios.post('https://api.dropboxapi.com/oauth2/token',null,{ params: data })
      .then((response)=>{
        let token = response?.data;
        const data = {
          link: import_info.link,
          platform: PLATFORM,
          parent_folder: import_info.parent_folder,
          token: token,
        }
        importFromDrives(data)
        .then((res)=>{
          showSnackbar('Import process initiated and is in progress. This process takes a while. Please refresh the dashboard after an hour to view the imported documents.',INFO,INFO_TITLE);
        })
        .catch((error)=>{
          if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
            showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
          }
        })
        .finally(() => {
          history.push({ pathname: path});
        });
      })
      .catch((error)=>{
        console.log(error)
        history.push({pathname: path});
      })
    }
    else{
      showSnackbar('We cannot proceed ahead untill you do not allow us.',ERROR,ERROR_TITLE);
      history.push({pathname: path});
    }
  },[])
  
  return (
    <h1 style={{textAlign:'center'}}>Redirecting to the dashboard...</h1>
  )
}