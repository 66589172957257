import React, { useState, createRef, useEffect } from 'react';
import {
    makeStyles,
    Box,
    Grid,
    Button,
    MenuItem,
    Select,
    Typography,
    FormControl,
    TextField,
} from "@material-ui/core";
import CustomizedDialogs from 'features/CustomisedDialogbox/CustomisedDialogbox';
import Delete from '@material-ui/icons/Delete';
// import { Modal, Button, Menu, Dropdown, Label } from 'semantic-ui-react';

export const Color = {
    DARK_SKYBLUE: '#4C71E2',
    DULL_BLUE: '#2B519E',
    BLUE: '#3136F9',
    GRAY: '#555',
    DARK_GRAY: '#333',
    DULL_BLACK: '#100f0f',
    BLACK: '#000',
}

const useStyles = makeStyles((theme) => ({
  toolbar:{
    background: 'white',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    border: '1px solid rgba(34,36,38,.15)',
    boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  selected: {
    paddingBottom: "0.2rem",
    cursor: 'pointer',
    fontSize: '1.2rem',
    fontFamily: 'Satoshi-Bold',
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  unselected: {
    fontSize: '1.2rem',
    paddingBottom: "0.2rem",
    cursor: "pointer",
  },
  colorsWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    padding: '8px',
    flexWrap: 'wrap',
    alignContent: 'flex-start'
  },
  colorContainer: {
    height: '1.4rem',
    width: '1.4rem',
    border: '3px solid white',
    borderRadius: '50%',
    cursor:'pointer'
  },
}));

export const SignatureModal = ({ open, dismiss, confirm, drawing }) => {
  const svgRef = createRef();
  const [paths, setPaths] = useState([]);
  const [path, setPath] = useState((drawing && drawing.path) || '');
  const [svgX, setSvgX] = useState(0);
  const [svgY, setSvgY] = useState(0);
  const [minX, setMinX] = useState(Infinity);
  const [maxX, setMaxX] = useState(0);
  const [minY, setMinY] = useState(Infinity);
  const [maxY, setMaxY] = useState(0);
  const [mouseDown, setMouseDown] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState(5);
  const [stroke, setStroke] = useState(Color.BLACK);
  const [strokeDropdownOpen, setStrokeDropdownOpen] = useState(false);

  const classes = useStyles();
  const [choice, setChoice] = useState('type');
  const [text, setText] = useState('');
  const [font, setFont] = useState('');
  // const fontFamily = ['cursive','Courier New','Brush Script MT','Georgia','Satoshi-Medium','Trebuchet MS'];
    const fontFamily = [ "MrDeHaviland","Somelove","BastligaOne","Suavity","Signature"];

  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;
    const { x, y } = svg.getBoundingClientRect();
    setSvgX(x);
    setSvgY(y);
  }, [svgRef]);

  const handleMouseDown = (event) => {
    event.preventDefault();
    setMouseDown(true);

    const x = event.clientX - svgX;
    const y = event.clientY - svgY;
    setMinX(Math.min(minX, x));
    setMaxX(Math.max(maxX, x));
    setMinY(Math.min(minY, y));
    setMaxY(Math.max(maxY, y));
    setPath(path + `M${x},${y}`);
    setPaths([...paths, ['M', x, y]]);
  };

  const handleMouseMove = (event) => {
    event.preventDefault();
    if (!mouseDown) return;

    const x = event.clientX - svgX;
    const y = event.clientY - svgY;
    setMinX(Math.min(minX, x));
    setMaxX(Math.max(maxX, x));
    setMinY(Math.min(minY, y));
    setMaxY(Math.max(maxY, y));
    setPath(path + `L${x},${y}`);
    setPaths([...paths, ['L', x, y]]);
  };

  const handleMouseUp = (event) => {
    event.preventDefault();
    setMouseDown(false);
  };

  const resetDrawingBoard = () => {
    setPaths([]);
    setPath('');
    setMinX(Infinity);
    setMaxX(0);
    setMinY(Infinity);
    setMaxY(0);
    setStrokeWidth(5);
    setStroke(Color.BLACK);
  };

  const handleDoneOnDraw = () => {
    if (!paths.length) {
      confirm();
      return;
    }

    const boundingWidth = maxX - minX;
    const boundingHeight = maxY - minY;

    const dx = -(minX - 10);
    const dy = -(minY - 10);

    confirm({
      stroke,
      strokeWidth,
      width: boundingWidth + 20,
      height: boundingHeight + 20,
      path: paths.reduce(
        (fullPath, lineItem) =>
          `${fullPath}${lineItem[0]}${lineItem[1] + dx}, ${lineItem[2] + dy}`,
        ''
      ),
    });
    closeModal();
  };

  const handleDoneOnText = () =>{
    if(text === '' || text === 'Type here'){
      alert('Please add your signature!')
      return;
    }
    confirm({
      text,
      color:stroke,
      size: 36,
      type: 'signText',
      fontFamily: font,
    });
    closeModal();
  }

  const closeModal = () => {
    resetDrawingBoard();
    dismiss();
  };

  // TODO: Move to config
  const strokeSizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleStrokeSelect = (color) => () => {
    setStroke(color);
    setStrokeDropdownOpen(false);
  };

  const handleClickOnFont = (fontname) => {
    if(font === fontname){
      setFont('');
    }
    else{
      setFont(fontname);
    }
  } 

  return (
    <CustomizedDialogs
      open={open}
      handleClose={closeModal}
      buttonText={'Done'}
      onSubmit={choice === 'draw' ? handleDoneOnDraw : handleDoneOnText}
      title={'Create signature'}
    >
      <Box>
        <Grid container style={{gap:'2rem'}} justifyContent='center'>
          <Grid
            item
            className={
              choice === "type" ? classes.selected : classes.unselected
            }
            onClick={() => {
              setChoice("type");
            }}
          >
            Type
          </Grid>
          <Grid
            item
            className={
              choice === "draw" ? classes.selected : classes.unselected
            }
            onClick={() => {
              setChoice("draw");
            }}
          >
            Draw
          </Grid>
        </Grid>
        <div style={{height:'1rem'}} ></div>
        {choice==='draw' && (
          <div className={classes.toolbar}>
            <div>Tools</div>
            <Select
              label={`${strokeWidth}`}
              value={`${strokeWidth}`}
              labelId={`${strokeWidth}`}
            >
            {strokeSizes.map((size,index) => (
              <MenuItem
                key={index}
                value={size}
                selected={size === strokeWidth}
                onClick={() => setStrokeWidth(size)}
              >
                {size}
              </MenuItem>
            ))}
            </Select>
          </div>
        )}
        <div style={{height:'1rem'}} ></div>
        <div className={classes.colorsWrap}>
          {Object.values(Color).map((color, index) => (
            <div 
              style={stroke===color ? {border: `1.5px solid ${color}`,borderRadius:'50%'}:null} 
              key={index}
            >
              <div
                className={classes.colorContainer}
                color={color}
                onClick={handleStrokeSelect(color)}
                style={{backgroundColor:color}}
              />
            </div>
          ))}
        </div>
        <div style={{height:'1rem'}} ></div>
        {choice === 'draw' ? (
          <>
            <Typography style={{color:'#696871',textAlign:'center'}}>Sign your name using your mouse or touchpad.</Typography>
            <div style={{height:'1rem'}} ></div>
            <div
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
            >
              <svg
                ref={svgRef}
                style={{
                  width: '100%',
                  height: '40vh',
                  border:'1px solid #E5E5E5',
                }}
              >
                <path
                  strokeWidth={strokeWidth}
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  stroke={stroke}
                  fill="none"
                  d={path}
                />
              </svg>
              <Button onClick={resetDrawingBoard} style={{position:'absolute',bottom:0,marginBottom:'24px'}}>Clear</Button>
            </div>
          </>
        ) : (
          <>
            <FormControl fullWidth>
              <Typography style={{color:'black',marginBottom:'0.5rem'}}>Type</Typography>
              <TextField
                variant='outlined'
                size='small'
                onChange={(e) =>setText(e.currentTarget.value)}
                value={text}
                placeholder='Add your signature...'
              />
            </FormControl>
            <Box style={{height:'1.5rem'}}></Box>
            <Box className={classes.colorsWrap} style={{width:'300px',margin:'auto',height:'35.8vh'}}>
              {
                fontFamily.map((fontname,index)=>{
                  return (
                    <Box key={index} style={font === fontname ? {padding:'5px 10px',backgroundColor:'rgb(118 107 251 / 10%)'} : {padding:'5px 10px'}}>
                      <Typography style={{fontFamily:fontname,fontSize:'3rem',color:stroke}} onClick={()=>handleClickOnFont(fontname)}>{text ? text : 'Type here'}</Typography>
                    </Box>
                  )
                })
              }
            </Box>
          </>
        )}
      </Box>
    </CustomizedDialogs>
  );
};
