import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { importFromDrives, } from "../api/FileApi";
import DropboxChooser from "features/dropbox/DropboxChooser";
import { DROPBOX_APP_KEY, DEV_REDIRECT_URI, PROD_REDIRECT_URI } from "constants/appConstants";


const useStyles = makeStyles((theme) => ({
  paper: {
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    position: "absolute",
    // maxHeight:'20rem',
    left: "50%",
    top: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  firstText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  fieldsText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    marginTop: "0.7rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.3rem",
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  inputfield: {
    width: "100%",
    height: "3rem",
    background: "#E0E0E0",
    color: "#000000",
    fontSize: "18px",
    fontFamily: `'Inter',sans-serif`,
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    paddingLeft: '0.5rem',
    "&:focus": {
      outline: "none !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  cancel: {
    textDecoration: "underline",
    fontSize: "20px",
    fontWeight: "400",
    color: "#2F80ED",
    lineHeight: "36px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      lineHeight: "26px",
    },
  },
  add: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#FFFFFF",
    lineHeight: "36px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      lineHeight: "28px",
    },
  },
  addbtn: {
    background: "#2F80ED",
    marginLeft: "3rem",
    width: "120px",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      marginLeft: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      marginLeft: "1rem",
    },
  },
  container: {
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
}));

export default function ImportModal({
  openModal,
  handleCloseModal,
  parent_folder,
  setImportStatus,
  handleOpenSnackBarImport,
  autoRefreshOnImport,
  permissionToImportFolder,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState('');
  const [platform, setPlatform] = useState('');
  const [error,setError] = useState();

  const [isFolder, setIsFolder] = useState('');

  const platformList = ['Google Drive','Dropbox'];

  let REDIRECT_URI;

  if (window.location.host == "app-doc.webnyay.in" || window.location.host == "docs.webnyay.ai") {
    REDIRECT_URI = PROD_REDIRECT_URI;
  } else {
    REDIRECT_URI = DEV_REDIRECT_URI;
  }
  // REDIRECT_URI = 'http://localhost:3000/dropbox/authorised';

  const handleChangeonLink = (event) => {
    setLink(event.target.value);
    if(event.target.value === '')
      setError(null);
  }

  const handleChangePlatform = (event) => {
    setPlatform(event.target.value);
  }

  const validateLinkForImport = (link) => {
    let url;
    if(platform === 'Dropbox'){
      return 'valid';
    }
    try {
      url = new URL(link);
    } catch (_) {
      return 'Link is not valid';
    }
    if(url.protocol !== "https:"){
      return 'Link is not valid';
    }
    else if(!link.includes('/drive.google.com/') && !link.includes('/docs.google.com/')){
      return 'Not a google drive link';
    }
    return 'valid';
  }

  const onChoose = (files) => {
    setLink(files[0].link);
    setIsFolder(files[0].isDir);
  }

  const getAuthorizationCode = async () => {
    if(isFolder && !permissionToImportFolder()){
      alert('You cannot import a folder here\nIt will breaks the pagination');
      return;
    }
    const Dropbox_App_Key = DROPBOX_APP_KEY;
    const import_info = {
      link: link,
      parent_folder: parent_folder.id,
    }
    sessionStorage.setItem('import_info',JSON.stringify(import_info));
    window.location.href = `https://www.dropbox.com/oauth2/authorize?token_access_type=offline&client_id=${Dropbox_App_Key}&redirect_uri=${REDIRECT_URI}&response_type=code`;
  }

  const handleImport = () => {
    if(link === '' || platform === ''){
      alert('Please fill both required field!');
      return;
    }
    else{
      if(platform === 'Dropbox'){
        getAuthorizationCode();
        return;
      }
      if(validateLinkForImport(link) !== 'valid' ){
        setError(validateLinkForImport(link));
      }
      else if(link.includes('folders') && !permissionToImportFolder()){
        alert('You cannot import a folder here\nIt will breaks the pagination');
      }
      else{
        let data = {
          link: link,
          parent_folder: parent_folder.id,
          platform: platform,
        }
        importFromDrives(data)
        .then((res)=>{
          setLoading(true);
          setImportStatus(res.data.doc.import_status);
          handleCloseModal();
          handleOpenSnackBarImport();
          autoRefreshOnImport();
        })
        .catch((error) => {
          if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
            alert(error?.response?.data?.error);
          }
          handleCloseModal();
        })
      }
    }
  }

  const body = (
    <Box className={classes.paper}>
      <Typography className={classes.firstText}>Import files and folders</Typography>
      <Box height="1.5rem" />
      <Grid container className={classes.container} spacing={2}>
        <Grid item sm={3} xs={4}>
          <Typography className={classes.fieldsText}>
            Select platform 
          </Typography>
        </Grid>
        <Grid item sm={9} xs={8}>
        <FormControl fullWidth variant="outlined" required>
          <InputLabel id="platformId">Platform</InputLabel>
          <Select
            labelId="platformId"
            id="platform"
            value={platform}
            label="Platform"
            onChange={handleChangePlatform}
          >
            {platformList.map((platform,index)=>{
              if(platform === 'Dropbox'){
                return (
                  <MenuItem key={index} value={platform} style={{display:'block'}}>
                    <DropboxChooser
                      appKey={DROPBOX_APP_KEY}
                      success={onChoose}
                      folderselect={true}                                    
                    >
                      {platform}
                    </DropboxChooser>
                  </MenuItem>
                )
              }
              else{
                return <MenuItem key={index} value={platform}>{platform}</MenuItem>
              }
            })}
          </Select>
        </FormControl>
        </Grid>
      </Grid>
      <Box height="1rem" />
      { platform === 'Google Drive' &&
        <Grid container className={classes.container} spacing={2}>
          <Grid item sm={3} xs={4}>
            <Typography className={classes.fieldsText}>
              URL*  
            </Typography>
          </Grid>
          <Grid item sm={9} xs={8}>
            <TextField
              error={error}
              helperText={error}
              placeholder="Enter link here"
              variant="outlined"
              fullWidth
              onChange={handleChangeonLink}
              value={link}
              required
              autoFocus
            />
          </Grid>
        </Grid>
      }
      <Box height="2rem" />
      <Grid container justify="flex-end" className={classes.container} alignItems='center'>
        {loading && 
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography className={classes.fieldsText}>Import is in progress...</Typography>
          </Grid>
        }
        <Grid item xs>
        
        </Grid>
        <Grid item style={loading ? {display : 'none'} : {}}>
          <Typography className={classes.cancel} onClick={handleCloseModal}>
            Cancel
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.addbtn}
            onClick={handleImport}
            disabled={loading}
          >
            {loading && <CircularProgress style={{ width: 15, height: 15, marginRight: '0.5rem'}} />}
            <Typography className={classes.add}>Import</Typography>
          </Button>
        </Grid>
      </Grid>
      <Box height="1rem" />
    </Box>
  );

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openModal}>{body}</Fade>
    </Modal>
  );
}
