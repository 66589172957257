import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";

async function myCustomFileGetter(event) {
  const files = [];
  const fileList = event.dataTransfer
    ? event.dataTransfer.files
    : event.target.files;

  for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    files.push(file);
  }
  return files;
}

const useStyles = makeStyles((theme)=>({
  dropbox: {
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    background: "rgba(74, 59, 255, 0.03)",
    color: "#000000",
    fontSize: "1rem",
    border: `2px dashed ${theme.palette.primary.main}`,
    padding: "1rem",
    textAlign: "center",
    height: '13rem',
    overflow: "scroll",
    cursor: 'pointer'
  },
  container: {
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  browse_text: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  }
}))

export default function UploadModal({
  loading,
  setFiles,
}) {
  const classes = useStyles();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    getFilesFromEvent: (event) => myCustomFileGetter(event),
    disabled: loading,
    multiple: false,
  });

  useEffect(()=>{
    setFiles(acceptedFiles);
  },[acceptedFiles])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box>
            <div
              {...getRootProps({
                className: `dropzone ${classes.dropbox}`,
              })}
            >
              <input {...getInputProps()} type="file" directory webkitdirectory mozdirectory />
              {acceptedFiles?.length === 0 ? (
                <>
                  <Typography style={{color:'#888'}}>Drop your file <br/>here or <span className={classes.browse_text}>browse</span></Typography>
                </>
              ) : (
                acceptedFiles.map((file) => {
                  return (
                    <Typography
                      key={file.name}
                      style={{ wordBreak: "break-all" }}
                    >
                      {file.name}
                    </Typography>
                  );
                })
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
      {/* <Box height="1rem" />
      <Grid container className={classes.container} alignItems='center'>
        {loading && 
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box  style={{width: '100%', padding: 10}}>
              <LinearProgressWithLabel value={progress} />
              <Typography className={classes.uploadingInfo}>Uploading {uploadingFileName}</Typography>
            </Box>
          </Grid>
        }
      </Grid> */}
    </>
  )
}