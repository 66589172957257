import React from 'react';
import {
    Modal,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    makeStyles
  } from "@material-ui/core";
import CustomisedTooltip from 'features/CustomisedToolTip/CustomisedToolTip';

const useStyles = makeStyles((theme) => ({
    iframeWrap: {
        overflowY: 'scroll',
        overflowY: 'scroll !important',
        WebkitOverflowScrolling: "touch",
        overflowScrolling: "touch",
    },
}));

export const PdfViewingModal = ({open, dismiss, pdfUrl, handlePdfClick, showEditBtn = false, disableEditBtn= false }) => {    
    const classes = useStyles()
    const closeModal = () => {
        dismiss();
    };

    return (
        <>
        <Dialog disableEnforceFocus fullScreen dimmer="inverted" style={{width: '100%',height: '100%'}} open={open} onClose={closeModal}>
            <DialogTitle>
                {showEditBtn && 
                    <CustomisedTooltip style={{display: 'flex', justifyContent: 'flex-start'}} title={disableEditBtn ? "Please OCR and then edit" : ""}>
                        <div>
                            <Button  
                                onClick={handlePdfClick}
                                variant="contained" 
                                disabled={disableEditBtn}>
                                Edit
                            </Button>
                        </div>
                    </CustomisedTooltip>
                }
            </DialogTitle>
            <DialogContent>
                <iframe
                    className={classes.iframeWrap}
                    src={pdfUrl}
                    width={'100%'}
                    height={'100%'}
                />                
            </DialogContent>
            <DialogActions>
            <Button color="black" content="Close" onClick={closeModal}>Close</Button>
            {showEditBtn && 
                <CustomisedTooltip title={disableEditBtn ? "Please OCR and then edit" : ""}>
                    <div>
                        <Button
                            content="Done"
                            labelPosition="right"
                            icon="checkmark"
                            onClick={handlePdfClick}
                            positive
                            variant="contained"
                            disabled={disableEditBtn}
                        >
                            Edit
                        </Button>
                    </div>
                </CustomisedTooltip>
            }
            </DialogActions>
        </Dialog>
    </>
  );
};