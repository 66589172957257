import { saveEditedPdf, hyperlinkFile } from 'api/FileApi';
import { readAsArrayBuffer } from './asyncReader';
import { getAsset } from './prepareAssets';
import { Color } from 'newFileStructure/editModals/SignatureModal';
import Swal from "sweetalert2";
import fontkit from '@pdf-lib/fontkit';
import TimesNewRoman from '../assets/fonts/TimesNewRoman/TimesNewRoman.ttf';
import TimesNewRoman_Italic from '../assets/fonts/TimesNewRoman/TimesNewRoman-Italic.ttf';
import TimesNewRoman_Bold from '../assets/fonts/TimesNewRoman/TimesNewRoman-Bold.ttf';
import TimesNewRoman_Bold_Italic from '../assets/fonts/TimesNewRoman/TimesNewRoman-Bold-Italic.ttf';
import { ERROR, ERROR_TITLE } from 'constants/appConstants';
import BastligaOne from '../assets/fonts/BastligaOne.ttf';
import MrDeHaviland from '../assets/fonts/MrDeHaviland.ttf';
import Somelove from '../assets/fonts/Somelove.otf';
import Thesignature from '../assets/fonts/Thesignature.ttf';
import TheSuavity from '../assets/fonts/TheSuavity.ttf';


const normalize = (value) =>
  parseFloat((value / 255).toFixed(1));

const wrapText = (text, width, font, fontSize) => {
  const words = text.split(' ');
  let line = '';
  let result = '';
  for (let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + ' ';
    const testWidth = font.widthOfTextAtSize(testLine, fontSize);
    if (testWidth > width) {
      result += line + '\n';
      line = words[n] + ' ';
    } else {
      line = testLine;
    }
  }
  result += line;
  return result;
}

async function saveEditedPdfFile(fileIdPdf, blob, setSavingLoader, paginationType,showSnackbar){
  setSavingLoader(true);
  await saveEditedPdf(fileIdPdf, blob)
    .then(async (res)=>{
      console.log('PDF Saved');
      if(paginationType !== 0){
        await hyperlinkFile(fileIdPdf).then(() => {
          console.log('hyperlink successfull')
        })
        .catch((error)=>{
          if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
            showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
          }
        })
      }
      setSavingLoader(false);
      await Swal.fire({
        icon: "success",
        title: "Pdf saved succesfully",
        showConfirmButton: false,
        timer: 1500,
      });
      window.location.reload(false)
    })
    .catch((error)=>{
      if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
      setSavingLoader(false)
    })
}


function compensateRotation (pageRotation, x, y, scale, pageWidth, pageHeight, size) {
  var rotationRads = (pageRotation * Math.PI )/ 180;
  var updatedY;
  var updatedX = x/scale;

  if(pageRotation === 90 || pageRotation === 270){
    updatedY = pageWidth - ((y + size)/scale);
  }
  else{
    updatedY = pageHeight - ((y + size)/scale);
  }
  var drawX = null;
  var drawY = null;
  if(pageRotation === 90){
    drawX = updatedX * Math.cos(rotationRads) - updatedY * Math.sin(rotationRads) + pageWidth ;
    drawY = updatedX * Math.sin(rotationRads) + updatedY * Math.cos(rotationRads);
  }
  else if(pageRotation === 180){
    drawX = updatedX * Math.cos(rotationRads) - updatedY * Math.sin(rotationRads) + pageWidth ;
    drawY = updatedX * Math.sin(rotationRads) + updatedY * Math.cos(rotationRads) + pageHeight;
  }
  else if(pageRotation === 270){
    drawX = updatedX * Math.cos(rotationRads) - updatedY * Math.sin(rotationRads);
    drawY = updatedX * Math.sin(rotationRads) + updatedY * Math.cos(rotationRads) + pageHeight;
  }
  else{
    //no rotation
    drawX = updatedX;
    drawY = updatedY;
  }
  return [drawX, drawY];
}

export async function save(
  pdfFile,
  objects,
  name,
  fileIdPdf,
  setSavingLoader,
  paginationType,
  showSnackbar,
) {
  const PDFLib = await getAsset('PDFLib');
  const download = await getAsset('download');
  let pdfDoc = {};
  // let pdfDoc: {
  //   getPages: () => any[];
  //   embedFont: (arg0: unknown) => any;
  //   embedJpg: (arg0: unknown) => any;
  //   embedPng: (arg0: unknown) => any;
  //   embedPdf: (arg0: any) => [any] | PromiseLike<[any]>;
  //   save: () => any;
    // rotate?: Rotation;
        // xSkew?: Rotation;
        // ySkew?: Rotation;
        
  // };

  try {
    pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(pdfFile));
  } catch (e) {
    console.log('Failed to load PDF.');
    throw e;
  }
  const {
    PDFName,
    PDFString,
    
  } = PDFLib;

  const suitableFont = (bold,italic) => {
    if(bold && italic){
      return TimesNewRoman_Bold_Italic;
    }
    else if(bold){
      return TimesNewRoman_Bold;
    }
    else if(italic){
      return TimesNewRoman_Italic;
    }
    else {
      return TimesNewRoman;
    }
  }

  const fonts = {
    BastligaOne: {
      src: BastligaOne,
    },
    MrDeHaviland: {
      src: MrDeHaviland,
    },
    Somelove: {
      src: Somelove,
    },
    Signature: {
      src: Thesignature,
    },
    Suavity: {
      src: TheSuavity,
    }
  }

  const pagesProcesses = pdfDoc.getPages().map(async (page, pageIndex) => {
    const pageObjects = objects[pageIndex];
    const pageRotation = page.getRotation().angle;

    const pageHeight = page.getHeight();
    const pageWidth = page.getWidth();
    const embedProcesses = pageObjects.map(async (object) => {
      if (object.type === 'image') {
        const { file, x, y, width, height } = object;
        let img;
        try {
          if (file.type === 'image/jpeg') {
            img = await pdfDoc.embedJpg(await readAsArrayBuffer(file));
          } else {
            img = await pdfDoc.embedPng(await readAsArrayBuffer(file));
          }
          return () =>
            page.drawImage(img, {
              x,
              y: pageHeight - y - height,
              width,
              height,
            });
        } catch (e) {
          console.log('Failed to embed image.', e);
          throw e;
        }
      } else if (object.type === 'text') {
        const {
          x,
          y,
          text,
          size,
          width,
          bold,
          italic,
        } = object;
        pdfDoc.registerFontkit(fontkit);
        const font = suitableFont(bold,italic);
        const fontBytes = await fetch(font).then(res => res.arrayBuffer())
        const pdfFont = await pdfDoc.embedFont(fontBytes);
        const updatedWrapText = wrapText(text, width, pdfFont, size);
        const {
          rgb,
          degrees,
        } = PDFLib;
        let [drawX, drawY] = compensateRotation(pageRotation, x, y, 1, pageWidth, pageHeight, size);
        return () =>{
          page.drawText(updatedWrapText, {
            maxWidth: pageWidth - x, 
            font: pdfFont,
            size,
            x: drawX,
            y: drawY,
            color: rgb(0.95, 0.1, 0.1),
            rotate: degrees(pageRotation),
          });
        }
      }else if (object.type === 'editorText') {
        const {
          x,
          y,
          path,
          lineHeight = 1.4,
          size = 16,
          fontFamily = 'Times-Roman',
          width,
          backgroundColor = 'limeGreen',
          link,
        } = object;
        const {
          rgb,
          degrees,
        } = PDFLib;
        const pdfFont = await pdfDoc.embedFont(fontFamily);
        let [drawX, drawY] = compensateRotation(pageRotation, x, y, 1, pageWidth, pageHeight, size);
        let textStringLength = path.length;
        let updatedLink;
        if(path?.[0] === '[' && path?.[textStringLength-1] === ']' && !link){
          let updatedPagination = path.replaceAll(/[{()}[\]]/g, '');
          updatedLink = `${window.location.origin}/stampingHelper/${updatedPagination}`
        }else{
          updatedLink = link;
        }
        const linkAnnotation = pdfDoc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [drawX, drawY, drawX+20, drawY +20],
          // Border: [0, 0, 2],
          C: [0, 0, 1],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(updatedLink),
          },
        });
       
        const linkAnnotationRef = pdfDoc.context.register(linkAnnotation);
        return () => {
            page.drawText(path, {
              maxWidth: width,
              font: pdfFont,
              size,
              lineHeight,
              x: drawX,
              y: drawY,
              color: rgb(0, 0, 1),
              rotate: degrees(pageRotation)
            });            
            page.node.set(PDFName.of('Annots'), pdfDoc.context.obj([linkAnnotationRef]));
          }
        } 
      else if (object.type === 'drawing') {
        const {
          x,
          y,
          path,
          scale,
          stroke,
          strokeWidth,
        } = object;
        const {
          pushGraphicsState,
          setLineCap,
          popGraphicsState,
          setLineJoin,
          LineCapStyle,
          LineJoinStyle,
          rgb,
          degrees,
        } = PDFLib;
        let [drawX, drawY] = compensateRotation(pageRotation, x, y, scale, pageWidth, pageHeight, 0);

        return () => {
          page.pushOperators(
            pushGraphicsState(),
            setLineCap(LineCapStyle.Round),
            setLineJoin(LineJoinStyle.Round)
          );

          const color = window.w3color(stroke).toRgb();

          page.drawSvgPath(path, {
            borderColor: rgb(
              normalize(color.r),
              normalize(color.g),
              normalize(color.b)
            ),
            borderWidth: strokeWidth,
            scale,
            x: drawX,
            y: drawY,
            rotate: degrees(pageRotation)
          });
          page.pushOperators(popGraphicsState());
        };
      }else if (object.type === 'signText') {
        const {
          x,
          y,
          text,
          size,
          fontFamily,
          width,
          color,
          ref,
        } = object;
        pdfDoc.registerFontkit(fontkit);
        const fontBytes = await fetch(fonts[fontFamily].src).then(res => res.arrayBuffer())
        const pdfFont = await pdfDoc.embedFont(fontBytes);
        const textColor = window.w3color(color).toRgb();
        const updatedWrapText = wrapText(text, width, pdfFont, size);
        const {
          rgb,
          degrees,
        } = PDFLib;
        let [drawX, drawY] = compensateRotation(pageRotation, x, y, 1, pageWidth, pageHeight, size);
        return () =>{
          page.drawText(updatedWrapText, {
            maxWidth: pageWidth - x, 
            font: pdfFont,
            size,
            x: drawX,
            y: drawY,
            color: rgb(
              normalize(textColor.r),
              normalize(textColor.g),
              normalize(textColor.b)
            ),
            rotate: degrees(pageRotation)
          });
        }
      }
      
    });
    // embed objects in order
    const drawProcesses = await Promise.all(embedProcesses);
    drawProcesses.forEach((p) => p());
  });
  await Promise.all(pagesProcesses);
  try {
    const pdfBytes = await pdfDoc.save();
    var blob = new File([pdfBytes], name, {type: "application/pdf"});
    saveEditedPdfFile(fileIdPdf, blob, setSavingLoader,paginationType,showSnackbar);
  } catch (e) {
    console.log('Failed to save PDF.');
    throw e;
  }
}
