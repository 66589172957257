import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Box,
    MenuItem,
    FormControl,
    TextField,
} from "@material-ui/core";
import CustomizedDialogs from 'features/CustomisedDialogbox/CustomisedDialogbox';
import BoldIcon from '../../images/Bold.svg';
import ItalicIcon from '../../images/Italic.svg';
import CenterAlignIcon from '../../images/AlignCenter.svg';
import DeleteIcon from '../../images/trash.svg';

const useStyles = makeStyles((theme) => ({
  toolbar:{
    background: 'white',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    border: '1px solid rgba(34,36,38,.15)',
    boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  selected: {
    paddingBottom: "0.2rem",
    cursor: 'pointer',
    fontSize: '1.2rem',
    fontFamily: 'Satoshi-Bold',
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  unselected: {
    fontSize: '1.2rem',
    paddingBottom: "0.2rem",
    cursor: "pointer",
  },
  colorsWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    padding: '8px',
    flexWrap: 'wrap',
    alignContent: 'flex-start'
  },
  colorContainer: {
    height: '1.4rem',
    width: '1.4rem',
    border: '3px solid white',
    borderRadius: '50%',
    cursor:'pointer'
  },
}));

export const CommentModal = ({ open, dismiss, addComments, attachmentData, updateAttachment }) => {

  const classes = useStyles();
  const [text, setText] = useState('');

	const [bold, setBold] = useState('');
  const [italic, setItalic] = useState('');
  const [textAlign, setTextAlign] = useState('');
	const [fontSize, setFontSize] = useState(14);

	const sizes = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32];

  useEffect(()=>{
    if(attachmentData){
      setText(attachmentData.text);
      setBold(attachmentData.bold);
      setItalic(attachmentData.italic);
      setFontSize(attachmentData.size);
    }
  },[attachmentData])

  const onClickCenterAlign = () => {
    if(textAlign === 'center'){
      setTextAlign('')
    }
    else{
      setTextAlign('center');
    }
  }

	const onClickBold = () => {
    if(bold === 'bold'){
      setBold('')
    }
    else{
      setBold('bold');
    }
  }

	const onClickItalic = () => {
    if(italic === 'italic'){
      setItalic('')
    }
    else{
      setItalic('italic');
    }
  }

  const ToolbarItems = [
    {
      title: 'bold',
      icon: BoldIcon,
      onClick: onClickBold,
    },
    {
      title: 'italic',
      icon: ItalicIcon,
      onClick: onClickItalic,
    },
    // {
    //   title: 'center',
    //   icon: CenterAlignIcon,
    //   onClick: onClickCenterAlign,
    // },
    {
      title: 'Delete',
      icon: DeleteIcon,
      onClick: ()=>{setText('')},
    },
  ]

  const handleDone = () =>{
    if(text === '' || text === 'Type here'){
      alert('Please add comment!');
      return;
    }
    if(attachmentData){
      const data = {
        ...attachmentData,
        lines: [text],
        text: text,
        textAlign: textAlign,
        bold: bold,
        italic: italic,
        size: fontSize,
      }
      updateAttachment(data);
    }
    else{
      addComments({
        text,
        textAlign: textAlign,
        bold: bold,
        italic: italic,
        size: fontSize,
      });
    }
    setText('');
    setBold('');
    setItalic('');
    setFontSize(14);
    closeModal();
  }

  const closeModal = () => {
    dismiss();
  };

  return (
    <CustomizedDialogs
      open={open}
      handleClose={closeModal}
      buttonText={'Done'}
      onSubmit={handleDone}
      title={'Add comments'}
    >
      <Box>
				<>
					<div style={{display:'flex',justifyContent:'center',border:'2px solid #E5E5E5',borderRadius:'6px',padding:'5px',width:'fit-content',gap:'8px',alignItems:'center'}}>
						{
							ToolbarItems.map((tool,item)=>{
								return (
									<div key={item} style={(tool.title===bold)||(tool.title===italic)||(tool.title===textAlign)?{padding:'5px',backgroundColor:'#4838ff24',height:'18px',cursor:'pointer'}:{padding:'5px',height:'18px',cursor:'pointer'}} onClick={tool.onClick}>
										<img src={tool.icon} alt={tool.title} height='18px' width='18px'/>
									</div>
								)
							})
						}
						<TextField
              value={fontSize}
              variant='outlined'
							size='small'
							select
            >
							{sizes.map((size,index) => (
								<MenuItem
									key={index}
									value={size}
									selected={size === fontSize}
									onClick={() => setFontSize(size)}
								>
									{size}
								</MenuItem>
							))}
            </TextField>
					</div>
					<Box height='2rem'></Box>
					<FormControl fullWidth>
						{/* <Typography style={{color:'black',marginBottom:'0.5rem'}}>Type</Typography> */}
						<textarea
							onChange={(e) =>setText(e.currentTarget.value)}
							value={text}
							placeholder='Add comments...'
							style={{padding:'10px',fontWeight:bold,fontStyle:italic,textAlign:textAlign,fontFamily:'TimesNewRoman',fontSize:fontSize,height:'150px'}}
						/>
					</FormControl>
				</>
      </Box>
    </CustomizedDialogs>
  );
};
