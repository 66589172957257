import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";

export const initialState = {
  loading: false,
  hasErrors: false,
  chatgroup: {},
};

const chatGroupSlice = createSlice({
  name: "chatgroup",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.chatgroup = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setError: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { setProgress, setSucessfull, setError } = chatGroupSlice.actions;

export default chatGroupSlice.reducer;

export function createChatGroup(dispute__id) {
  // console.log("inside createChatGroup");
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "put",
        url: "/api/chatgroup/" + dispute__id + "/",
      });

      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
}

export function createRvChatGroup(dispute__id) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "put",
        url: "/api/rvchatgroup/" + dispute__id + "/",
      });

      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
}
