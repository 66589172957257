import { InputAdornment, Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { isLocalhost } from "Routes/Routes";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { setTokenAsync } from "slices/loginSlice";
import * as Yup from "yup";
import React from "react";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
  password: Yup.string()
    .min(6, "Password Too Short!")
    .max(8, "Password Too Long!")
    .required("Password Required"),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  loginButton: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#000",
    color: "white",
    border: "1px solid transparent",

    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid #000",
      color: "#000",
    },
  },
  signupButton: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#E50014",
    color: "white",
    border: "1px solid transparent",

    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid #E50014",
      color: "#E50014",
    },
  },
  heading: {
    // fontSize: "3rem",
    // lineHeight: "5rem",
    textTransform: "capitalize",
    color: "#E50014",
    whiteSpace: "nowrap",
  },
  secondaryText: {
    color: "#000000",
    whiteSpace: "nowrap",
  },
  boldText: {
    fontWeight: "bold",
  },
  signinText: {
    fontSize: "1.5rem",
    lineHeight: "2.2rem",
    textTransform: "capitalize",
    color: "black",
  },
}));

const Signin = () => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  console.log("location", location);
  let ar = location.pathname.split("/");

  const { state: routeState } = useLocation();
  const apiErrors = useSelector((state) => state.login.errors);
  const hasErrors = useSelector((state) => state.login.hasErrors);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      let { email, password } = values;
      dispatch(setTokenAsync(email, password)).then((data) => {
        console.log("res", data);
        console.log("routeState", routeState);
        if (routeState) {
          history.push(`${routeState.from.pathname}`);
        } else {
          try {
            if (ar[1] !== "loginforstamping") {
              throw Error("dd");
            }
            console.log(ar[2]);
            console.log(ar[3]);
            console.log(ar[4]);
            if (isLocalhost) {
              window.location.href =
                "http://localhost:8000/linktodoc/?path=" +
                ar[2] +
                "/" +
                ar[3] +
                "/" +
                ar[4];
            } else {
              window.location.href =
                "https://dev-api-pdf.webnyay.in/linktodoc/?path=" +
                ar[2] +
                "/" +
                ar[3] +
                "/" +
                ar[4];
            }
          } catch {
            history.push("/");
          }
        }
      });
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const tooglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Paper className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          <FormattedMessage id="auth.login" />
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="filled"
            margin="normal"
            value={formik.email}
            required
            fullWidth
            id="email"
            label={<FormattedMessage id="user.email" />}
            name="email"
            autoComplete="email"
            autoFocus
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.email && formik.touched.email && (
            <Alert severity="error">{formik.errors.email}</Alert>
          )}
          {hasErrors && apiErrors.email && (
            <Alert severity="error">{apiErrors.email[0]}</Alert>
          )}
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label={<FormattedMessage id="auth.password" />}
            type={passwordType}
            id="password"
            value={formik.password}
            autoComplete="current-password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType === "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
          />
           {formik.errors.password && formik.touched.password && (
            <Alert severity="error">{formik.errors.password}</Alert>
          )}
          {hasErrors && apiErrors.password && (
            <Alert severity="error">{apiErrors.password[0]}</Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.loginButton}
          >
            {<FormattedMessage id="auth.login" />}
          </Button>
          {hasErrors && apiErrors?.data?.non_field_errors && (
            <Alert severity="error">{apiErrors?.data?.non_field_errors[0]}</Alert>
          )}
          <Box height="1rem"></Box>
          <Box justifyContent="center" display="flex">
            <Typography>OR</Typography>
          </Box>
          <Box height="2rem"></Box>
          <Grid container>
            <Grid item xs>
              <Link to="/forgot-password">
                <Typography>{<FormattedMessage id="auth.forgot" />}</Typography>
              </Link>
            </Grid>
          </Grid>
          <Box height="2rem"></Box>
          <Box textAlign="center">
            {" "}
            <Typography className={classes.secondaryText} variant="caption">
              {<FormattedMessage id="auth.noAccount" />}
            </Typography>
          </Box>
          <Button
            fullWidth
            variant="contained"
            className={classes.signupButton}
            onClick={() => history.push("/signup")}
          >
            {<FormattedMessage id="auth.signup" />}
          </Button>
        </form>
      </Paper>

      <Box height="2rem"></Box>
    </Container>
  );
};

export default Signin;
