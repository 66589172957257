import React, {useRef, useState, useEffect} from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Button,
  TextField,
  Typography
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import TableRowActionBtns from "./TableRowActionBtns";
import { deleteWorkspace, renameWorkspace, createWorkspace} from 'api/FileApi';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useSnackbar } from "contexts/SnackbarContext";
import {SUCCESS, WARNING, ERROR, ERROR_TITLE, SUCCESS_TITLE, WARNING_TITLE } from "constants/appConstants";
import {formatDate} from 'utils';
import Swal from "sweetalert2";
import { checkUserIsOwnerOrNot } from "utils/checkUserIsOwnerOrNot";
import { shallowEqual, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        background: "white",
        border: '1px solid #E5E5E5',
    },
    tableHead: {
        background: 'rgba(74, 59, 255, 0.05)',
    },
    tableHeadCell: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '100%',
    },
    tableBody: {

    },
    tableRow: {
        borderBottom: '1px solid #E5E5E5',
    }, 
    tableBodyCell: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '100%',
        wordBreak: 'break-word',
    },
    titleText: {
        color: '#121212',
        fontSize: '24px',
        fontWeight: '700',
    },
    subTitleText: {
        color: '#696871',
        fontSize: '14px',
        fontWeight: '400',
        marginTop: '7px',
        marginBottom: '32px'
    },
    nameWrap: {
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        width:' 30px',
        height: '30px',
        color: '#FFF',
        fontSize: '1rem',
        border: '2px solid #fafafa',
        marginRight: '-8px',
    },
    membersWrap: {
        display: 'flex'
    },
    workspaceTitle: {
        fontSize: '24px',
        fontWeight: '700',
        lineHeight:' 100%' /* 24px */
    },
    workspaceHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px'
    },
    tableRowcontainer: {
        position: 'relative',
    },
}));

function generateRowActionBtns(isThreeDotsClicked, threeDotsRef, handleThreeDotsClick, handleDeleteWorkspace, handleRenameWorkspace, classes) {
    return (
        <div className={classes.tableRowcontainer}>
            <TableRowActionBtns
                isThreeDotsClicked={isThreeDotsClicked}
                threeDotsRef={threeDotsRef}
                onThreeDotsClick={(e) => handleThreeDotsClick(e)}
            >
                <>
                    <div style={{display: 'flex', fontSize: '14px',fontWeight: '500', cursor: 'pointer', marginBottom: '15px'}} onClick={handleRenameWorkspace}>
                        <EditOutlinedIcon size="large" style={{marginRight: '10px'}}/> Rename
                    </div>
                    <div style={{display: 'flex', fontSize: '14px',fontWeight: '500', cursor: 'pointer'}} onClick={handleDeleteWorkspace}>
                        <DeleteOutlineIcon size="large" style={{marginRight: '10px'}}/> Delete
                    </div>
                </>
            </TableRowActionBtns>
        </div>
    );
}

function fileSize(size) {
    size = parseInt(size);
    if (size)
      if (size < 1024) return `${size.toFixed(2)} B`;
      else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
      else if (size < 1073741824) return `${(size / 1048576).toFixed(2)} MB`;
      else return `${(size / 1073741824).toFixed(2)} GB`;
    else return "0 B";
}

const WORKSPACE_HEADER_TITLES =[
        {
            "key": "name",
            "title": "Name",
        },
        {
            "key": "root_folder.size",
            "title": "Size",
        },
        {
            "key": "members",
            "title": "Members",
        },
        {
            "key": "owner.user.first_name",
            "title": "Owner",
        },
        {
            "key": "root_folder.modified",
            "title": "Modified on",
        },
];

function CustomTable({ headers, isEmptyData, iconImg, data, handleWorkspaceClick, getWorkspacesData, setWorkspaces, setWorkspaceList }) {
    const classes = useStyles();
    const threeDotsRef = useRef(null);
    const {showSnackbar,showDoneSnackbar} = useSnackbar();
    const profile = useSelector((state) => state.profile.profile, shallowEqual);

    const [idOfActiveThreeDots, setIdOfActiveThreeDots] = useState(null);
    const [renamingWorkspaceId, setRenamingWorkspaceId] = useState(null);
    const [newWorkspaceName, setNewWorkspaceName] = useState("New Workspace"); // Add state for the new workspace name
    const [isNewWorkspaceCreated, setIsNewWorkspaceCreated] = useState(false);
    const [renameError, setRenameError] = useState(false);

    function handleClickOutside(e) {
        if (threeDotsRef.current && !threeDotsRef.current.contains(e.target)) {
            setIdOfActiveThreeDots(null);
        }
    }

    const handleAddNewWorkspace = () => {
        // Set the edit state for the new row
        setIsNewWorkspaceCreated(true);
    };

    function handleThreeDotsClick(e, workspaceId) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setIdOfActiveThreeDots(workspaceId);
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        }
    }, []);

    // Define a function to render members
    const COLORS_ARRAY = ['#3498db', '#e74c3c', '#2ecc71', '#f1c40f', '#9b59b6'];
    function renderMembers(members) {
        if(members.length === 0){
            return (
                <div>Just you</div>
            )
        }
        return (
            <div className={classes.membersWrap}>
                {members?.slice(0, 4).map((item, index) => {
                    const { first_name, last_name } = item.user;
                    const firstNameInitial = first_name.charAt(0);
                    const lastNameInitial = last_name.charAt(0);
                
                    const colorIndex = index % COLORS_ARRAY.length;
                    return (
                        <div key={index} className={classes.nameWrap} style={{ backgroundColor: COLORS_ARRAY[colorIndex] }}>
                            <div>{firstNameInitial}</div>
                            <div>{lastNameInitial}</div>
                        </div>
                    );
                })}
                {members.length > 4 && (
                    <div className={classes.nameWrap} style={{ backgroundColor: 'green'}}>
                    <div>+{members?.length - 4}</div>
                    </div>
                )}
            </div>
        );

    }

    const handleDeleteWorkspace = (e,workspaceId,index) => {
      const owner = data.find((item) => item.id === workspaceId)?.owner;
      const isUserOwnerOfWorkspace = checkUserIsOwnerOrNot(owner,profile);
      if( !isUserOwnerOfWorkspace ){
        showSnackbar('Only Owner can delete the workspace.',ERROR,ERROR_TITLE);
        return;
      }
			Swal.fire({
				title: 'Are you sure?',
				text: "If you delete workspace you won't be able to revert this!",
				showCancelButton: true,
				confirmButtonText: 'Delete',
				focusCancel: true,
			}).then((result) => {
				if (result.isConfirmed) {
					deleteWorkspace(workspaceId).then(() => {
            showSnackbar('Workspace deleted successfully',SUCCESS,SUCCESS_TITLE);
            data.splice(index,1);
            setWorkspaces([...data]);
            setWorkspaceList([...data]);
					})
					.catch((error)=>{
						if((error?.response?.status === 424 || error?.response?.status === 400) && error?.response?.data?.error){
							showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
						}
					})
				}
			})
    }

    const handleRenameWorkspace = (e, workspaceId) => {
      const owner = data.find((item) => item.id === workspaceId)?.owner;
      const isUserOwnerOfWorkspace = checkUserIsOwnerOrNot(owner,profile);
      if( !isUserOwnerOfWorkspace ){
        showSnackbar('Only Owner can rename the workspace.',ERROR,ERROR_TITLE);
        return;
      }
      setRenamingWorkspaceId(workspaceId); // Set the ID of the workspace being renamed
      const workspaceToEdit = data.find((item) => item.id === workspaceId);
      setNewWorkspaceName(workspaceToEdit.name);
    }

    const handleChangeWorkspaceName = (e) => {
        const value = e.target.value;
        if(value?.length > 300){
          setRenameError('Max 300 characters are allowed');
        }
        else{
          setRenameError(false);
        }
        setNewWorkspaceName(e.target.value);
      }

    const handleSaveRenameWorkspace = (workspaceId) => {
        if (newWorkspaceName.trim() === "") {
          // Don't allow empty workspace names
          alert("Workspace name cannot be empty");
          return;
        }
        if(newWorkspaceName?.length > 300){
            return;
          }
    
        // Make an API call to rename the workspace
        renameWorkspace(workspaceId, { name: newWorkspaceName })
            .then((res) => {
                showSnackbar('Workspace Renamed successfully',SUCCESS,SUCCESS_TITLE);
                getWorkspacesData()
                setRenamingWorkspaceId(null); // Clear the renaming state
            })
            .catch((error) => {
                if ((error?.response?.status === 424 || error?.response?.status === 400) && error?.response?.data?.error) {
                showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
                setRenamingWorkspaceId(null); // Clear the renaming state
            }
        });
    };

    const handleEditInputKeyPress = (e, workspaceId) => {
        if (e.key === "Enter") {
          handleSaveRenameWorkspace(workspaceId);
        }
    };
    
    const handleEditInputBlur = (workspaceId) => {
        handleSaveRenameWorkspace(workspaceId);
    };

    const handleSaveNewWorkspace = () => {
        if (newWorkspaceName.trim() === "") {
            // Don't allow empty workspace names
            alert("Workspace name cannot be empty");
            return;
          }
        if(newWorkspaceName?.length > 300){
            return;
          }
      
          // Make an API call to rename the workspace
          createWorkspace({ name: newWorkspaceName })
              .then(() => {
                  showSnackbar('Created Workspace Successfully',SUCCESS,SUCCESS_TITLE);
                  getWorkspacesData();
              })
              .catch((error) => {
                    showSnackbar('User already has a workspace with the same name.',WARNING,WARNING_TITLE);
                if (error?.response?.status === 424 && error?.response?.data?.error) {
                    console.log('error')
                }
          });
         // Reset the input field and edit state
        setNewWorkspaceName("New Workspace");
        setIsNewWorkspaceCreated(false);
    };
    
    const handleSaveNewWorkspaceInputKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSaveNewWorkspace();
        }
    };

    const handleSaveNewWorkspaceInputBlur = () => {
        handleSaveNewWorkspace();
    };

    return (
        <>
         <div className={classes.workspaceHeader}>
            <Typography className={classes.workspaceTitle}>Workspaces</Typography>
            <Button
                color="primary"
                variant="contained"
                startIcon={<Add />}
                onClick={handleAddNewWorkspace}
                
            >
                New Workspace
            </Button>
        </div>
        
        <Table className={classes.tableWrapper}>

            <TableHead className={classes.tableHead}>
                <TableRow className={classes.tableRow}>
                    {WORKSPACE_HEADER_TITLES.map((header, index) => (
                        <TableCell className={classes.tableHeadCell} key={index}>{header.title}</TableCell>
                    ))}
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            {(data?.length === 0 && !isNewWorkspaceCreated)?
                <TableRow style={{
                    textAlign: 'center',
                  }}>
                    <TableCell colSpan={6} style={{textAlign: 'center'}}>
                        <div style={{width: 'fit-content',margin:'3rem auto 4rem'}}>
                            <img src={iconImg} alt="Empty Data" />
                            <div className={classes.titleText}>Create your first workspace</div>
                            <div className={classes.subTitleText}>Start with a legal case you are working on</div>
                            <Button
                                color="primary"
                                variant="outlined"
                                startIcon={<Add />}
                            size="large"
                            onClick={handleAddNewWorkspace}
                            >
                                New Workspace
                            </Button>
                        </div>
                    </TableCell>
                </TableRow>
                : 
                <TableBody className={classes.tableBody}>
                    {/* Add a new row for creating a workspace */}
                    {isNewWorkspaceCreated && (
                        <TableRow>
                            <TableCell className={classes.tableBodyCell}>
                                <TextField
                                    value={newWorkspaceName}
                                    onChange={handleChangeWorkspaceName}
                                    placeholder="New Workspace Name"
                                    onKeyPress={(e) => handleSaveNewWorkspaceInputKeyPress(e)}
                                    onBlur={() => handleSaveNewWorkspaceInputBlur()}
                                    error={renameError}
                                    helperText={renameError}
                                />
                            </TableCell>
                            <TableCell className={classes.tableBodyCell}>0.0</TableCell>
                            <TableCell className={classes.tableBodyCell}>Just you</TableCell>
                            <TableCell className={classes.tableBodyCell}>
                                <div style={{display:'flex',alignItems:'center',gap:12}}>
                                    {renderMembers([profile])}{`${profile?.user?.first_name} ${profile?.user?.last_name}`}
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableBodyCell}>{formatDate(new Date())}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    )}
                    {data?.map((item, rowIndex) => {
                        const isThreeDotsClicked = idOfActiveThreeDots === item.id;
                        const isRenaming = renamingWorkspaceId === item.id; // Check if this workspace is being renamed
                        return(
                            <TableRow 
                                key={rowIndex} 
                                className={classes.tableRow} 
                                onDoubleClick={(e) => {
                                    if (!isThreeDotsClicked || !isRenaming) {
                                      // Only call handleWorkspaceClick if the three dots were not clicked
                                      handleWorkspaceClick(item);
                                    }
                                }}
                            >
                                {WORKSPACE_HEADER_TITLES.map((header, colIndex) =>{
                                    const cellValue = header.key === 'owner.user.first_name'
                                      ? <div style={{display:'flex',alignItems:'center',gap:12}}>{renderMembers([item?.owner])} {getItemValueByNestedKey(item, 'owner.user.first_name')} {getItemValueByNestedKey(item, 'owner.user.last_name')}</div>
                                      : header.key.includes('.')
                                      ? getItemValueByNestedKey(item, header.key)
                                      : item[header.key];
                                    const displayValue = 
                                        header.key === 'root_folder.size' ? fileSize(cellValue) 
                                        : header.key === 'root_folder.modified' ? formatDate(cellValue) 
                                        : header.key === 'members' ? renderMembers(item?.members)
                                        : cellValue;
                                    return(
                                        <TableCell
                                            className={classes.tableBodyCell}
                                            key={colIndex}
                                            style={{ width: `${100 / WORKSPACE_HEADER_TITLES.length}%` }}
                                        >
                                        {colIndex === 0 && isRenaming ? (
                                            // Display the input field for editing
                                            <TextField
                                                value={newWorkspaceName}
                                                onChange={handleChangeWorkspaceName}
                                                placeholder="New Name"
                                                onKeyPress={(e) => handleEditInputKeyPress(e, item.id)}
                                                onBlur={() => handleEditInputBlur(item.id)}
                                                autoFocus
                                                error={renameError}
                                                helperText={renameError}
                                            />
                                        ) : (
                                            // Display the cell as text when not editing
                                            displayValue
                                        )}
                                        </TableCell>
                                    )
                                })}
                                <TableCell>
                                {generateRowActionBtns(isThreeDotsClicked, threeDotsRef, (e)=>handleThreeDotsClick(e,item.id), (e) =>handleDeleteWorkspace(e, item.id, rowIndex), (e) => handleRenameWorkspace(e, item.id),classes)}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                      
                </TableBody>
            }
        </Table>
        </>
    );
}

// Helper function to get nested values by key
function getItemValueByNestedKey(item, key) {
    const keys = key.split('.');
    let value = item;
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]];
      if (value === undefined) {
        return ''; // Return an empty string if the key doesn't exist
      }
    }
    return value;
}

export default CustomTable;