import React, { useState, createRef, useEffect } from 'react';
import {
    Typography,
    FormControl,
    TextField,
    Box,
    Grid,
    makeStyles,
  } from "@material-ui/core";
import TinyEditor from '../editing/Editor';
import CustomizedDialogs from 'features/CustomisedDialogbox/CustomisedDialogbox';

const useStyles = makeStyles((theme) => ({
  selected: {
    paddingBottom: "0.2rem",
    cursor: 'pointer',
    fontSize: '1.2rem',
    fontFamily: 'Satoshi-Bold',
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  unselected: {
    fontSize: '1.2rem',
    paddingBottom: "0.2rem",
    cursor: "pointer",
  },
  label: {
    color: 'black',
    marginBottom: '0.5rem',
    fontWeight: 600,
  }
}))

const Color = {
    RED : 'red',
    ORANGE : 'orange',
    YELLOW : 'yellow',
    OLIVE : 'olive',
    GREEN : 'green',
    TEAL : 'teal',
    BLUE : 'blue',
    VIOLOET : 'violet',
    PURPLE : 'purple',
    BROWN : 'brown',
    GREY : 'grey',
    BLACK : 'black',
  }

export const HyperLinkModalReference = ({ open, dismiss, updateText, drawing, stamp_type }) => {
  const svgRef = createRef();
  const [paths, setPaths] = useState([]);
  const [path, setPath] = useState((drawing && drawing.path) || '');
  const [svgX, setSvgX] = useState(0);
  const [svgY, setSvgY] = useState(0);
  const [minX, setMinX] = useState(Infinity);
  const [maxX, setMaxX] = useState(0);
  const [minY, setMinY] = useState(Infinity);
  const [maxY, setMaxY] = useState(0);
  const [mouseDown, setMouseDown] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState(5);
  const [stroke, setStroke] = useState(Color.BLACK);
  const [strokeDropdownOpen, setStrokeDropdownOpen] = useState(false);

  const [editorState, setEditorState] = useState('');
  const [text, setText] = useState();
  const [hyperlink,setHyperlink] = useState('');

  const [choice, setChoice] = useState('byText');
  const classes = useStyles();

  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;
    const { x, y } = svg.getBoundingClientRect();
    setSvgX(x);
    setSvgY(y);
  }, [svgRef]);

  const handleMouseDown = (event) => {
    event.preventDefault();
    setMouseDown(true);

    const x = event.clientX - svgX;
    const y = event.clientY - svgY;
    setMinX(Math.min(minX, x));
    setMaxX(Math.max(maxX, x));
    setMinY(Math.min(minY, y));
    setMaxY(Math.max(maxY, y));
    setPath(path + `M${x},${y}`);
    setPaths([...paths, ['M', x, y]]);
  };

  const handleMouseMove = (event) => {
    event.preventDefault();
    if (!mouseDown) return;

    const x = event.clientX - svgX;
    const y = event.clientY - svgY;
    setMinX(Math.min(minX, x));
    setMaxX(Math.max(maxX, x));
    setMinY(Math.min(minY, y));
    setMaxY(Math.max(maxY, y));
    setPath(path + `L${x},${y}`);
    setPaths([...paths, ['L', x, y]]);
  };

  const handleMouseUp = (event) => {
    event.preventDefault();
    setMouseDown(false);
  };

  const resetDrawingBoard = () => {
    setPaths([]);
    setPath('');
    setMinX(Infinity);
    setMaxX(0);
    setMinY(Infinity);
    setMaxY(0);
    setStrokeWidth(5);
    setStroke(Color.BLACK);
  };

  const isValidLink = (link) => {
    let url;
    try {
      url = new URL(link);
    } catch (_) {
      return false;
    }
    if(url.protocol !== "https:"){
      return false;
    }
    return true;
  }

  const handleDone = () => {
    // if (!paths.length) {
    //   confirm();
    //   return;
    // }

    // const boundingWidth = maxX - minX;
    // const boundingHeight = maxY - minY;

    // const dx = -(minX - 10);
    // const dy = -(minY - 10);

    // confirm({
    //   stroke,
    //   strokeWidth,
    //   width: boundingWidth + 20,
    //   height: boundingHeight + 20,
    //   path: paths.reduce(
    //     (fullPath, lineItem) =>
    //       `${fullPath}${lineItem[0]}${lineItem[1] + dx}, ${lineItem[2] + dy}`,
    //     ''
    //   ),
    // });
    let validLink = isValidLink(hyperlink);
    if(choice === 'byLink' && !validLink){
      alert('Link is not valid!');
      return;
    }
    updateText({
      path: editorState,
      height: 20,
      link: hyperlink,
    })

    closeModal();
  };

  const closeModal = () => {
    resetDrawingBoard();
    dismiss();
  };

  // TODO: Move to config
  const strokeSizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleStrokeSelect = (color) => () => {
    setStroke(color);
    setStrokeDropdownOpen(false);
  };

  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
    // const { blocks } = convertToRaw(editorState.getCurrentContent());
    // /*let text = blocks.reduce((acc, item) => {
    //   acc = acc + item.text;
    //   return acc;
    // }, "");*/
    // let text = editorState.getCurrentContent().getPlainText("\u0001");
    // setText(text);
    // console.log(text,'text', editorState,blocks)
    // setEditorState(a);
    // setText(editor.getContent({ format: "text" }));
    // console.log(editor, content);
  };
  const handleStateChange = (e) => {
    setEditorState(e.target.value)
  }
  const handleHyperlink = (e) => {
    setHyperlink(e.target.value)
  }
  
  const getPlaceholderAfterPagination = (stamp_type) => {
    let placeholderForHyperlink = 'Eg. ';
    
    switch (Number(stamp_type)) {
      case 1:
      case 2:
        placeholderForHyperlink += '[A/1/1]';
        break;
      case 3:
        placeholderForHyperlink += '[A/45444]';
        break;
      default:
        placeholderForHyperlink += '[p2]';
    }
    
    return placeholderForHyperlink;
  }
  
  return (
    <CustomizedDialogs
      open={open}
      handleClose={closeModal}
      title={'Add Hyperlink'}
      buttonText={'Done'}
      onSubmit={handleDone}
      isSubmitButtonDisabled={(editorState==='') || (choice==='byLink' && hyperlink==='')}
    >
      <Box style={{height:'197px'}}>
        <Grid container style={{gap:'2rem'}}>
          <Grid
            item
            className={
              choice === "byText" ? classes.selected : classes.unselected
            }
            onClick={() => {
              setChoice("byText");
            }}
          >
            By Text
          </Grid>
          <Grid
            item
            className={
              choice === "byLink" ? classes.selected : classes.unselected
            }
            onClick={() => {
              setChoice("byLink");
            }}
          >
            By link
          </Grid>
        </Grid>
        <Box style={{height:'2.3rem'}}></Box>
        {
          choice === 'byText' ? (
            <Box>
              <Typography style={{color:'#696871'}}>Hyperlink your document set by adding the relevant<br/>document reference in square brackets.</Typography>
              <Box style={{height:'1rem'}}></Box>
              <FormControl fullWidth>
                <Typography className={classes.label}>Add by Text</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  onChange={(e) =>handleStateChange(e)}
                  placeholder={getPlaceholderAfterPagination(stamp_type)}
                  value={editorState}
                />
              </FormControl>
            </Box>
            
          ) : (
            <Box>
              <FormControl fullWidth>
                <Typography className={classes.label}>Name</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  onChange={(e) =>handleStateChange(e)}
                  placeholder='Add name here...'
                  value={editorState}
                />
              </FormControl>
              <Box style={{height:'2rem'}}></Box>
              <FormControl fullWidth>
                <Typography className={classes.label}>Give hyperlink</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  onChange={(e) =>handleHyperlink(e)}
                  placeholder='Paste hyperlink here...'
                  value={hyperlink}
                />
              </FormControl>
            </Box>
          )
        }
      </Box>
    </CustomizedDialogs>
  );
};
