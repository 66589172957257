import React, { useEffect, useRef, useState } from 'react';
import {
    makeStyles,
    MenuItem,
    Typography,
    Button,
    IconButton,
    Grid,
    Popover,
} from "@material-ui/core";
import { KeyboardBackspace } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    editorBtn: {
     fontSize: '16px',
     border: '1px solid black',
     width: '90%',
     padding: '10px',
     cursor: 'pointer',
     "&:hover": {
        backgroundColor: "grey",
      },
    },
    btnWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      // width: '20%',
      gap: '1rem',
    },
    btnStyle:{
      '&:hover': {
        backgroundColor: "#D3D3D3",
      },
    },
    fileNameWrap: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      width: '45%',
    },
    fileName: {
      fontFamily: 'Satoshi-bold',
      color: 'black',
      fontSize: '1.2rem',
    }
  }));

export const PdfSidebar = ({addSignature, addComments, addHyperLinkReference, updateEditedFile, updateDeleteFileIndex, name, pageIndex, pages}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setEnchorEl] = useState(false);
  const history = useHistory();

  useEffect(() => {
    document.addEventListener('click', handleRequestClose);
    return () => {
        document.removeEventListener('click', handleRequestClose);
    }
}, []);


  function handleClick(event){
    setOpen(true)
    setEnchorEl(event.currentTarget)
  }
  function handleRequestClose(){
    setOpen(false)
  }

  return (
    <>
      <div className={classes.fileNameWrap} >
        {/* <Link to='/'> */}
          <IconButton onClick={()=>history.goBack()}>
            <KeyboardBackspace/>
          </IconButton>
        {/* </Link> */}
        <Typography className={classes.fileName}>{name}</Typography>
      </div>
      <div>
        <Typography style={{color:'black',margin:'0 20px'}}><span className={classes.fileName} style={{border:'1px solid #4A3BFF',padding:'4px 8px'}}>{pageIndex+1}</span> of {pages.length}</Typography>
      </div>
      <Grid item xs></Grid>
      <div className={classes.btnWrap}>
        <Button variant='outlined' style={{border:'1.5px solid black'}} onClick={updateDeleteFileIndex}>Delete</Button>
        <Button
          variant='outlined'
          color='primary'
          aria-owns={open ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={((event)=>handleClick(event))}
          onMouseOver={(event)=>handleClick(event)}
          style={{border:'1.5px solid #4A3BFF'}}
        >
          Edit
        </Button>
        <Popover
          id="simple-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleRequestClose}
          onRequestClose={handleRequestClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={addComments}>Add Comment</MenuItem>
          <MenuItem onClick={addSignature}>Add Signature</MenuItem>
          <MenuItem onClick={addHyperLinkReference}>Add Hyperlink Reference</MenuItem>
        </Popover>
        <Button variant='contained' color='primary' onClick={updateEditedFile}>Save</Button>
      </div>
    </>
  );
};
