import { Box, Typography, Grid, FormHelperText } from "@material-ui/core";
import { Button, makeStyles, FormControl, OutlinedInput, InputAdornment } from "@material-ui/core";
import React, {useState, useEffect} from "react";
import EnvelopeSimple from "images/EnvelopeSimple.svg";
import Lock from "images/Lock.svg";
import User from "images/User.svg";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {VisibilityOutlined, VisibilityOffOutlined} from '@material-ui/icons';
import { Link} from "react-router-dom";
import OtpInput from 'react-otp-input';
import { CREATE_USER, ERROR, ERROR_TITLE, LOGIN, PASSWORD_HELPER_TEXT, VERIFY_EMAIL, VERIFY_PASSWORD } from "constants/appConstants";
import { Alert } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { register } from "slices/userSlice";
import { setTokenAsync } from "slices/loginSlice";
import { useSnackbar } from "contexts/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  sub_title: {
    color: 'black',
    textAlign: 'center',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  color_gray: {
    color: '#5A5A5A',
    fontWeight: 400,
  },
  label: {
    color: '#121212',
    // fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem', 
  },
  input_field: {
    margin: '0.5rem 0',
    borderRadius: 0,
  },
  main_button: {
    color: 'white',
    backgroundColor: '#4A3BFF',
    textTransform: 'none',
    border: '1px solid #4A3BFF',
    borderRadius: 0,
    padding: '1.2rem',
    boxShadow: 'none',
    fontSize: '1.125rem',
    fontWeight: 700,
    
    "&:hover": {
      color: '#4A3BFF',
      backgroundColor: 'transparent',
      textTransform: 'none',
      border: '1px solid #4A3BFF',
      borderRadius: 0,
      boxShadow: 'none',
      fontSize: '1.125rem',
      // fontWeight: 700,
      padding: '1.2rem',

    },
  },
  back_button: {
    width: '100%',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  forgot_button: {
    color: '#4A3BFF',
    fontSize: '1rem',
    fontWeight: 500,
    textAlign: 'right',
    margin: '0.6rem 0',
    cursor: 'pointer',

  },
  otp_inputStyle: {
    padding: '1.2rem',
    borderRadius: 0,
    border: '1px solid #c9caca',
    backgroundColor: 'transparent',
    "&:hover": {
      borderColor: 'black',
      borderRadius: 0,
    },
    "&:focus": {
      outline: 'none',
      border: '2px solid #4A3BFF',
      borderRadius: 0,
    }
  },
  resend_button: {
    color: '#4A3BFF',
    fontWeight: 700,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  space_between_input_fields: {
    height: '1rem',
  },
  space_between_buttons: {
    height: '2.6rem',
  },
  helperText: {
    margin: 'auto',
    fontSize: '0.85rem',
    lineHeight: '1.2',
  },

}))

function GetDetails({page,setPage,formik,sendOtp,formStates}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");
  const [otp, setOtp] = useState('');
  const [minutes, setMinutes] = useState(4);
  const [seconds, setSeconds] = useState(59);
  const [timmerRunning, setTimmerRunning] = useState(false);

  const {showSnackbar} = useSnackbar();

  useEffect(()=>{
    if(page === VERIFY_EMAIL){
      setTimmerRunning(true);
    }
  },[page])

  useEffect(()=>{
    if(timmerRunning){
      setMinutes(4);
      setSeconds(59);
    }
  },[timmerRunning]);

  useEffect(() => {
    if(!timmerRunning) return;
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
  
      if (seconds === 0) {
        if (minutes === 0) {
          setTimmerRunning(false);
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, [seconds,timmerRunning]);

  const tooglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  
  const resendOtp = () => {
    sendOtp(formik.values.email,formik.values.name);
    setTimmerRunning(true);
  }

  const handleClickOnVerifyEmail = async () => {
    const signupData = {
      email: formik.values.email,
      name: formik.values.name,
      password: formik.values.create_password,
      otp: otp,
    }
    let response = await dispatch(register(signupData))
    console.log(response);
		if(response?.id){
			console.log(response);
			// window.location.reload(false);
      await dispatch(setTokenAsync(signupData.email, signupData.password));
		}
		else if(response?.data?.error){
			let error = response?.data?.error;
			showSnackbar(error,ERROR,ERROR_TITLE);
		}
  }

  const go_back = (page) => {
    formik.resetForm({values: formStates});
    if(page === CREATE_USER){
      setTimmerRunning(false);
      formik.setFieldValue('create_password','');
    }
    setPage(page);

  }

  switch(page) {
    case LOGIN :
      return (
        <> 
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" fullWidth>
            <Typography id="email" className={classes.label}>Email address</Typography>
            <OutlinedInput
              fullWidth
              id="email"
              className={classes.input_field}
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="Eg - myemail@mail.com"
              startAdornment={<InputAdornment position="start"><img src={EnvelopeSimple} alt="Envelope" /></InputAdornment>}
            />
          </FormControl>
          {formik.errors.email && (
            <Alert severity="error">{formik.errors.email}</Alert>
          )}
          <Box className={classes.space_between_buttons}></Box>
          <Button type="submit" variant="contained" className={classes.main_button} fullWidth >Continue</Button>
        </>
      )
    case VERIFY_PASSWORD :
      return (
        <>     
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" fullWidth>
            <Typography id="password" className={classes.label}>Password</Typography>
            <OutlinedInput
              fullWidth
              type={passwordType}
              id="password"
              className={classes.input_field}
              placeholder="At least 8 characters"
              value={formik.values.password}
              onChange={formik.handleChange}
              startAdornment={<InputAdornment position="start"><img src={Lock} alt="Lock" /></InputAdornment>}
              endAdornment={
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer", color: '#5a5a5a' }}
                >
                  {passwordType === "password" ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
          {formik.errors.password && (
            <Alert severity="error">{formik.errors.password}</Alert>
          )}
          <Grid container style={{justifyContent:"end",}}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Link to="/forgot-password">
                <Typography className={classes.forgot_button}>Forgot Password?</Typography>
              </Link>
            </Grid>
          </Grid>
          <Box className={classes.space_between_buttons}></Box>
          <Button type="submit" variant="contained" className={classes.main_button} fullWidth>Log in</Button>
          <Box className={classes.space_between_buttons}></Box>
          <Button className={classes.back_button} startIcon={<KeyboardBackspaceIcon/>} onClick={()=> go_back(LOGIN)}>Back</Button>  
        </>
      )
    case CREATE_USER :
      return (
        <>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" fullWidth>
            <Typography id="name" className={classes.label}>Name</Typography>
            <OutlinedInput
              fullWidth
              id="name"
              className={classes.input_field}
              placeholder="Full name"
              value={formik.values.name}
              onChange={formik.handleChange}
              startAdornment={<InputAdornment position="start"><img src={User} alt="User" /></InputAdornment>}
            />
          </FormControl>
          {formik.errors.name && (
            <Alert severity="error">{formik.errors.name}</Alert>
          )}
          <Box className={classes.space_between_input_fields}></Box>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" fullWidth>
            <Typography id="create_password" className={classes.label}>Create password</Typography>
            <FormHelperText className={classes.helperText}>{PASSWORD_HELPER_TEXT}</FormHelperText>
            <OutlinedInput
              fullWidth
              type={passwordType}
              id="create_password"
              className={classes.input_field}
              placeholder="At least 6 characters"
              value={formik.values.create_password}
              onChange={formik.handleChange}
              startAdornment={<InputAdornment position="start"><img src={Lock} alt="Lock" /></InputAdornment>}
              endAdornment={
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer", color: '#5a5a5a' }}
                >
                  {passwordType === "password" ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
          {formik.errors.create_password && (
            <Alert severity="error">{formik.errors.create_password}</Alert>
          )}
          <Box className={classes.space_between_buttons}></Box>
          <Button type="submit" variant="contained" className={classes.main_button} fullWidth>Next</Button>
          <Box className={classes.space_between_buttons}></Box>
          <Button className={classes.back_button} startIcon={<KeyboardBackspaceIcon/>} onClick={()=>go_back(LOGIN)}>Back</Button>
        </>
      )
    case VERIFY_EMAIL :
      return (
        <>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            inputType="number"
            containerStyle={{justifyContent:'center',gap:'1.5rem'}}
            inputStyle={classes.otp_inputStyle}
            renderInput={(props) => <input {...props} />}
          />
          <Box className={classes.space_between_buttons}></Box>
          <Button variant="contained" className={classes.main_button} fullWidth onClick={handleClickOnVerifyEmail}>Verify</Button>
          <Box className={classes.space_between_input_fields} ></Box>
          <Typography className={`${classes.sub_title} ${classes.color_gray}`} style={{fontSize:'1rem'}}>
            Didn't receive the OTP? 
            {seconds > 0 || minutes > 0 ? (
              <span style={{color: 'red', marginLeft: '0.5rem'}}>
                Please wait: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </span>
            ) : (
              <span className={classes.resend_button} style={{marginLeft:'0.5rem'}} onClick={resendOtp}>Click to resend</span>
            )}
          </Typography>
          <Box className={classes.space_between_buttons}></Box>
          <Button className={classes.back_button} startIcon={<KeyboardBackspaceIcon/>} onClick={()=>go_back(CREATE_USER)}>Back</Button>
        </>
      )
    default :
  }
}

export default GetDetails;