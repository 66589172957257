import React from "react";
import { Tooltip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: 'rgb(0,0,0,0.9)',
  },
  tooltip: {
    backgroundColor: 'rgb(0,0,0,0.9)',
		fontSize: '0.9rem',
		padding: '0.4rem 0.6rem',
    borderRadius: 0,
    fontFamily: 'Satoshi-Regular',
  },
}));

export default function CustomisedTooltip(props) {
  const { children, ...other } = props;
	const classes = useStyles();
  return (
		<Tooltip classes={classes} {...props}>
			{children}
		</Tooltip>
	)
}