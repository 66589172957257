import React from 'react';

const TextMode ={
    INSERT : 'insert',
    COMMAND : 'command',
  }

export const TextComponent = ({
  text,
  width,
  height,
  inputRef,
  mode,
  size,
  fontFamily,
  positionTop,
  positionLeft,
  onChangeText,
  toggleEditMode,
  handleMouseDown,
  handleMouseMove,
  handleMouseOut,
  handleMouseUp,
  lineHeight,
  onSingleClick,
  textAreaHeight,
  deleteIndex,
  index,
  editComment,
  bold,
  italic,
  textAlign,
}) => {
  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseOut={handleMouseOut}
      onDoubleClick={toggleEditMode}
      onClick={onSingleClick}
      style={{
        width,
        height: textAreaHeight,
        fontFamily,
        fontSize: size,
        lineHeight,
        cursor: mode === TextMode.COMMAND ? 'move' : 'default',
        top: positionTop,
        left: positionLeft,
        wordWrap: 'break-word',
        padding: 0,
        position: 'absolute',
        border: deleteIndex === index ? '2px solid blue': '1.5px solid #E5E5E5',
      }}
    >
      <textarea
        type="text"
        ref={inputRef}
        onChange={onChangeText}
        readOnly={mode === TextMode.COMMAND}
        style={{
          width: '100%',
          borderWidth: 0,
          fontFamily:'TimeNewRoman',
          fontSize: size,
          outline: 'none',
          padding: 0,
          boxSizing: 'border-box',
          lineHeight,
          height: textAreaHeight,
          margin: 0,
          cursor: mode === TextMode.COMMAND ? 'move' : 'text',
          textAlign: textAlign,
          fontWeight: bold,
          fontStyle: italic,
          resize: 'horizontal',
        }}
        value={text}
      />
    </div>
  );
};
