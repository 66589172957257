import instance from "api/globalaxios";

export async function DownloadFile(file_id) {
  return await instance.get(`file-presigned-url/${file_id}/`);
}

export async function deleteFile(file_id) {
  return await instance.delete(`file-delete-update/${file_id}/`);
}

export async function getFiles(parent_folder_id,page_no,page_size,filter,sorting) {
  return await instance.get(
    `get-files/${parent_folder_id === 0 ? null : parent_folder_id}/?page=${page_no}&page_size=${page_size}&file_type=${filter}&sort_by=${sorting}`
  );
}

export async function UploadFiles(parent_folder_id, file) {
  const data = new FormData();
  data.append("file", file);
  data.append("name", file.name);
  data.append("path", "sd");
  data.append("type", file.type === "" ? "text/plain" : file.type);
  console.log('file.size', file.size, typeof file.size)
  data.append("size", file.size);
  if (parent_folder_id !== 0) data.append("parent_folder", parent_folder_id);
  return await instance.post(`upload-file/`, data, {
    headers: {
      "content-type": "multipart/form-data",
    },
    timeout: 600000,
  });
}

export async function UpdateFile(file_id, data) {
  return await instance.put(`file-delete-update/${file_id}/`, data);
}

export async function getFolder(folder_id) {
  return await instance.get(`get-folder-by-id/${folder_id}/`);
}

export async function deleteFolder(folder_id) {
  return await instance.delete(`/folder-delete-update/${folder_id}/`);
}

export async function getFolders(parent_folder_id,page_no,page_size,sorting) {
  return await instance.get(
    `get-folders/${parent_folder_id === 0 ? null : parent_folder_id}/?page=${page_no}&page_size=${page_size}&sort_by=${sorting}`
  );
}

export async function UploadFolders(folder_name, parent_folder_id) {
  return await instance.post(`create-folder/`, {
    name: folder_name,
    parent_folder: parent_folder_id === 0 ? null : parent_folder_id,
  });
}

export async function UpdateFolder(folder_id, data) {
  return await instance.put(`folder-delete-update/${folder_id}/`, data);
}

export async function paginate(pagination_type, workspace_id) {
  return await instance({
    method: 'get',
    url: `paginate-workspace/${workspace_id}/${pagination_type}/`,
  })
}

export async function hyperlinkFile(file_id) {
  return await instance({
    method: 'get',
    url: `hyperlink-file/${file_id}/`,
  })
}

export async function deleteAllFiles(workspace_id) {
  return await instance({
    method: 'delete',
    url: `delete-all/${workspace_id}/`,
  })
}

export async function getNumberofPaginatedFiles(workspace_id) {
  return await instance({
    method: 'get',
    url: `get-number-of-paginated-files/${workspace_id}/`
  })
}

export async function importFromDrives(data) {
  return await instance.post(`import/`, data, {
    headers: {
      "content-type": "application/json",
    },
  });
}

export async function findSearchByFileName(data, startPageNumber, endPageNumber, workspace_id) {
  return await instance({
    method: 'get',
    url: `find-by-file-name/${workspace_id}/${data}/${startPageNumber}/${endPageNumber}/`,
  })
}

export async function findSearchByPagination(data, workspace_id) {
  return await instance({
    method: 'get',
    url: `find-by-pagination/${workspace_id}/${data}/`,
  })
}

export async function findSearchInFiles(data, startPageNumber, endPageNumber, workspace_id) {
  return await instance({
    method: 'get',
    url: `search-in-files/${workspace_id}/${data}/${startPageNumber}/${endPageNumber}/`,
  })
}

export async function getOCRableFiles(workspace_id) {
  return await instance({
    method: 'get',
    url: `get-ocrable-files-list/${workspace_id}/`,
  })
}

export async function OCR_files(workspace_id) {
  return await instance({
    method: 'get',
    url: `ocr-files-for-workspace/${workspace_id}/`,
  })
}

export async function DownloadSelectedFiles(data) {
  return await instance({
    method: 'post',
    url: `download/`,
    responseType: 'blob',
    timeout: 600000,
    data: data,
  })
}

export async function getDocumentSetForAutoRefresh(workspace_id) {
  return await instance({
    method: 'get',
    url: `api/workspace/${workspace_id}/`,
  })
}

export async function saveEditedPdf(fileId, file) {
  const data = new FormData();
  data.append("file", file);
  data.append("name", file.name);
  data.append("type", file.type === "" ? "text/plain" : file.type);
  data.append("size", file.size);
  return await instance.put(`save-edited-pdf/${fileId}/`, data, {
    headers: {
      "content-type": "application/pdf",
    },
  })
}

export async function generateIndexFile(workspace_id) {
  return await instance({
    method: 'get',
    url: `generate-index-file/${workspace_id}/`,
  })
}

export async function bookmarkIndexFile(id) {
  return await instance({
    method: 'get',
    url: `generate-bookmarked-index-file/${id}/`,
  })
}

export async function downloadFromS3() {
  return await instance({
    method: 'get',
    url: `save-folder-structure-in-s3/`,
    timeout: 1800000,
  })
}

export async function getFolderAndFilesById(workspace_id) {
  return await instance.get(
    `get-folder-structure/${workspace_id}/`
  );
}


export async function getWorkspaces(){
  return await instance.get(
    'api/workspace/'
  )
}

export async function deleteWorkspace(workspace_id) {
  const data = {id: workspace_id}
  return await instance.delete(`api/workspace/`, { data });
}

export async function renameWorkspace(workspace_id, data) {
  return await instance.patch(`api/workspace/${workspace_id}/`, data);
}

export async function createWorkspace(data) {
  return await instance.post(`api/workspace/`, data);
}

export async function getIndividualWorkspace(workspace_id){
  return await instance.get(
    `api/workspace/${workspace_id}/`
  )
}

export const RemoveCollaborator = async (data) => {
  return await instance.delete('/invite-or-remove-user-to-workspace/',{data});
}

export async function GetAllowedMembersForWorkspace(workspaceId) {
  return await instance.get(`api/allowed-members-for-workspace/${workspaceId}/`);
}

export async function deleteSelectedFiles(data) {
  return await instance({
    method: 'delete',
    url: `delete-files-and-folders/`,
    data: data
  })
}