import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { auth, signInWithLinkedin } from "app/firebase";
import qs from "query-string";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getLinkedinCustomToken, socialAuth } from "slices/loginSlice";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function LinkedinCallback() {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const { code, state } = qs.parse(search);
    if (code) {
      setLoading(true);
      getLinkedinCustomToken({ code, state }).then((data) => {
        signInWithLinkedin(data.custom_token).then(() => {
          auth.currentUser.getIdToken(true).then((idToken) => {
            dispatch(socialAuth({ idToken })).then(() => {
              setLoading(false);
              history.push("/");
            });
          });
        });
      });
    }
  }, [search, dispatch, history]);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
