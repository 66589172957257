import React from 'react';

const TextMode ={
    INSERT : 'insert',
    COMMAND : 'command',
  }

export const SignTextComponent = ({
  text,
  width,
  height,
  inputRef,
  mode,
  size,
  fontFamily,
  color,
  positionTop,
  positionLeft,
  onChangeText,
  toggleEditMode,
  handleMouseDown,
  handleMouseMove,
  handleMouseOut,
  handleMouseUp,
  lineHeight,
  onSingleClick,
  textAreaHeight,
  deleteIndex,
  index,
}) => {
  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseOut={handleMouseOut}
      // onDoubleClick={toggleEditMode}
      onClick={onSingleClick}
      style={{
        width,
        // height: textAreaHeight,
        fontFamily,
        fontSize: size,
        color: color,
        lineHeight,
        // cursor: mode === TextMode.COMMAND ? 'move' : 'default',
        cursor: 'move',
        top: positionTop,
        left: positionLeft,
        wordWrap: 'break-word',
        padding: '3px 8px',
        position: 'absolute',
        border: deleteIndex === index ? '2px solid blue': '1.5px solid #E5E5E5',
      }}
    >
      {/* <textarea
        type="text"
        ref={inputRef}
        onChange={onChangeText}
        readOnly={mode === TextMode.COMMAND}
        style={{
          width: '100%',
          borderStyle: 'none',
          padding: '5px 10px',
          borderWidth: 0,
          fontFamily,
          color,
          fontSize: size,
          outline: 'none',
          padding: 0,
          boxSizing: 'border-box',
          lineHeight,
          height: textAreaHeight,
          margin: 0,
          // backgroundColor: 'rgb(254, 255, 156)',
          cursor: mode === TextMode.COMMAND ? 'move' : 'text',
        }}
        value={text}
      /> */}
      <div dangerouslySetInnerHTML={{__html: text}}></div>
    </div>
  );
};
