import { Box, Container, makeStyles } from "@material-ui/core";
import { LOCAL_AUTH } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import ProfileInfo from "./ProfileInfo";
import ProfileMenu from "./ProfileMenu";
import ProfileSecurity from "./ProfileSecurity";

const useStyles = makeStyles(() => ({
  root: {
    padding: "5rem 0",
  },
  th: {
    fontStyle: "normal",
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    fontWeight: "500",
    margin: "0",
  },
  td: {
    fontStyle: "normal",
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    fontWeight: "normal",
    margin: "0",
  },
}));

const localRenderData = [
  <FormattedMessage id="pages.profile.contactInfo" />,
  <FormattedMessage id="pages.profile.security" />,
];

const socialRenderData = [<FormattedMessage id="pages.profile.contactInfo" />];

const getStepContent = (step, profile, classes) => {
  switch (step) {
    case 0:
      return <ProfileInfo profile={profile} classes={classes} />;
    case 1:
    default:
      return <ProfileSecurity />;
  }
};

export default function Profile({ profile }) {
  const [selected, setSelected] = React.useState(0);
  const classes = useStyles();

  const isSocialLogin = profile.auth_type !== LOCAL_AUTH;

  const renderData = localRenderData;
  console.log(renderData);
  return (
    <Container>
      <Box display="flex" className={classes.root}>
        <ProfileMenu
          data={renderData}
          selected={selected}
          setSelected={setSelected}
        />
        <Box width="2rem"></Box>
        {getStepContent(selected, profile, classes)}
      </Box>
    </Container>
  );
}
