import * as React from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
  } from "@material-ui/core";

export default function SimpleDialog({open, title, subTitle, handleAgreeBtn, closeModal}) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeModal}
      >
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {subTitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAgreeBtn}>Yes</Button>
          <Button onClick={closeModal} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}