import React, { useState, useRef, useEffect } from 'react';
import { TextComponent } from './TextingComponent';
import { getMovePosition } from '../../utils/getMovePosition';

const DragActions = {
    MOVE : 'MOVE',
    SCALE : 'SCALE',
    NO_MOVEMENT : 'NO_MOVEMENT',
}

const TextMode ={
    INSERT : 'insert',
    COMMAND : 'command',
  }
  

export const Text = ({
  removeText,
  x,
  y,
  text,
  width,
  height,
  lineHeight,
  size,
  fontFamily,
  pageHeight,
  pageWidth,
  updateTextAttachment,
  setDeleteIndex,
  deleteIndex,
  index,
  editComment,
  textAlign,
  bold,
  italic,
}) => {
  const inputRef = useRef(null);
  const [content, setContent] = useState(text || '');
  const [mouseDown, setMouseDown] = useState(false);
  const [positionTop, setPositionTop] = useState(y);
  const [positionLeft, setPositionLeft] = useState(x);
  const [operation, setOperation] = useState(
    DragActions.NO_MOVEMENT
  );
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const [parentHeight, setParentHeight] = useState("auto");

  const [textMode, setTextMode] = useState(TextMode.COMMAND);

  useEffect(() => {
    setParentHeight(`${inputRef.current.scrollHeight}px`);
		setTextAreaHeight(`${inputRef.current.scrollHeight}px`);
  },[content])

  useEffect(() => {
		inputRef.current.focus();
	},[])

  useEffect(()=>{
    setContent(text)
  },[text])

  const handleMouseMove = (event) => {
    event.preventDefault();

    if (mouseDown) {
      const { top, left } = getMovePosition(
        positionLeft,
        positionTop,
        event.movementX,
        event.movementY,
        width,
        height,
        pageWidth,
        pageHeight
      );

      setPositionTop(top);
      setPositionLeft(left);
    }
  };

  const handleMousedown = (event) => {
    if (textMode !== TextMode.COMMAND) {
      return;
    }

    setMouseDown(true);
    setOperation(DragActions.MOVE);
  };

  const handleMouseUp = (event) => {
    event.preventDefault();

    if (textMode !== TextMode.COMMAND) {
      return;
    }

    setMouseDown(false);

    if (operation === DragActions.MOVE) {
      const { top, left } = getMovePosition(
        positionLeft,
        positionTop,
        event.movementX,
        event.movementY,
        width,
        height,
        pageWidth,
        pageHeight
      );

      updateTextAttachment({
        x: left,
        y: top,
        pageWidth: pageWidth,
      });
    }

    // if (operation === DragActions.SCALE) {
    //     updateTextObject({
    //         x: positionLeft,
    //         y: positionTop,
    //     });

    // }

    setOperation(DragActions.NO_MOVEMENT);
  };

  const handleMouseOut = (event) => {
    if (operation === DragActions.MOVE) {
      handleMouseUp(event);
    }

    if (textMode === TextMode.INSERT) {
      setTextMode(TextMode.COMMAND);
      prepareTextAndUpdate();
    }
  };

  const prepareTextAndUpdate = () => {
    // Deselect any selection when returning to command mode
    //document?.getSelection()?.removeAllRanges();

    const lines = [content];
    updateTextAttachment({
      lines,
      text: content,
      ref: inputRef
    });
  };

  const toggleEditMode = () => {
    const input = inputRef.current;
    const mode =
      textMode === TextMode.COMMAND ? TextMode.INSERT : TextMode.COMMAND;

    setTextMode(mode);

    if (input && mode === TextMode.INSERT) {
      // input.focus();
      // input.select();
      editComment(index);
    } 
    else {
      prepareTextAndUpdate();
    }
  };

  const onChangeText = (event) => {
    const value = event.currentTarget.value;
    setContent(value);
  };

  const deleteText = () => {
    removeText();
  };

  const handleClick = () => setDeleteIndex();

  return (
    <TextComponent
      text={content}
      width={width}
      height={height}
      mode={textMode}
      size={size}
      lineHeight={lineHeight}
      inputRef={inputRef}
      fontFamily={fontFamily}
      positionTop={positionTop}
      onChangeText={onChangeText}
      positionLeft={positionLeft}
      handleMouseUp={handleMouseUp}
      toggleEditMode={toggleEditMode}
      handleMouseOut={handleMouseOut}
      handleMouseDown={handleMousedown}
      handleMouseMove={handleMouseMove}
      onSingleClick={handleClick}
      textAreaHeight={textAreaHeight}
      deleteIndex={deleteIndex}
      index={index}
      editComment={editComment}
      bold={bold}
      italic={italic}
      textAlign={textAlign}
    />
  );
};
