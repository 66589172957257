import { Box, Typography, Grid, Card, makeStyles, withStyles,Hidden, CardMedia, CardContent } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const useStyles = makeStyles((theme)=>({
	borderRadius: {
		borderRadius: 0,
	},
	paper: {
		padding: '16px',
		border: `1px solid ${theme.palette.border}`,
		height: '100%',
		boxShadow: 'none',
	},
	borderBottom: {
    padding: "16px",
		borderBottom: `1px solid ${theme.palette.border}`,
		'&:first-child': {
			backgroundColor: '#F7F8FA',
		}
	}
}))

export const StyledSkeleton = withStyles((theme) => ({
  root: {
    background: 'linear-gradient(90deg, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%)',
  },
	text: {
		borderRadius: 0,
	}
}))(Skeleton);

export default function WorkspaceLoadingScreen({view}){
  const classes = useStyles();

	const count = [1,2,3,4,5,6];

	return(
		<>
      <div style={{display:'flex',justifyContent:'space-between'}}>
        <Typography variant="h2">
          <StyledSkeleton width='8rem' animation='wave' />
        </Typography>
        <div>
          <StyledSkeleton width='9rem' height='5rem' animation='wave' />
        </div>
      </div>
			<Box style={{height:'1rem'}}></Box>
      <Card style={{border: `1px solid #E5E5E5`,boxShadow:'none'}}>
        {
          count.map((num)=>{
            return (
              <Grid container className={classes.borderBottom} key={num} spacing={2}>
                <Grid item lg={5} md={5} sm={5} xs={7}>
                  <Typography component='div'><StyledSkeleton width='80%' animation='wave' /></Typography>
                </Grid>
                <Hidden xsDown>
                  <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Typography component='div'><StyledSkeleton width='80%' animation='wave' /></Typography>
                  </Grid>
                </Hidden>
                <Grid item lg={2} md={2} sm={2} xs={4}>
                  <Typography component='div'><StyledSkeleton width='80%' animation='wave' /></Typography>
                </Grid>
                <Hidden xsDown>
                  <Grid item lg={2} md={2} sm={2} xs={3}>
                    <Typography component='div'><StyledSkeleton width='80%' animation='wave' /></Typography>
                  </Grid>
                </Hidden>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <Typography component='div'><StyledSkeleton width='80%' animation='wave' /></Typography>
                </Grid>
              </Grid>
            )
          })
        }
      </Card>
		</>
	)
}