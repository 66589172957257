import {
    makeStyles,
    Modal,
    Box,
    Grid,
    Button,
    IconButton,
  } from "@material-ui/core";
import React, { useEffect, useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { readAsPDF, readAsDataURL, readAsImage } from '../utils/asyncReader';
import { usePdf } from '../hooks/usePdf';
import { useAttachments } from '../hooks/useAttachments';
import { DownloadFile } from "../api/FileApi";
import { ggID } from '../utils/helpers';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Page } from "./PdfPage";
import { PdfSidebar } from "./PdfSidebar";
import {SignatureModal} from './editModals/SignatureModal';
import {HyperLinkModalReference} from './editModals/HyperLinkModalReference';
import {Attachments} from './Attachments';
import FullPageSpinner from "features/common/FullPageSpinner";
import { CommentModal } from "./editModals/CommentModal";

const AttachmentTypes =  {
  IMAGE : 'image',
  DRAWING : 'drawing',
  TEXT : 'text',
  EDITOR : 'editorText',
  SIGN_Text: 'signText',
}


const useStyles = makeStyles((theme) => ({
  sideBtns: {
    display: "flex",
    alignContent: "center",
  },
  wrap: {
    display: "flex",
    justifyContent: 'center',
    width: '80%'
  },
  sideSection:{
    background: 'white',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    border: '1px solid rgba(34,36,38,.15)',
    boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 20px',
  },
  pageWrap: {
    position: 'relative',
  },
  containerPdf: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  sideWidth:{
    width: '20%',
    overflow: 'scroll',
    height: '100vh',
    borderLeft: `2px solid ${theme.palette.border}`
  }
}));
  
export default function PdfViewer({
}) {
  let { file_id, page_number, stamp_type } = useParams();
  const classes = useStyles();
  const [fileBlobData, setFileBlobData] = useState();
  const [resultingPdf, setResultingPdf]= useState();
  const [error, setError] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const [hyperlinkModal, setHyperLinkModal] = useState(false);
  const [deleteIndex, setDeleteIndex]= useState();
  const [commentModal, setCommentModal] = useState(false);
  const [editCommentIndex, setEditCommentIndex] = useState('');
  
  const { file, pages, setPageIndex2, initialize, pageIndex, isMultiPage, isFirstPage, isLastPage, currentPage, isSaving, savePdf, previousPage, nextPage, setDimensions, name, dimensions, savingLoader, setIsFirstPage, setIsLastPage } = usePdf();
  const { add: addAttachment, allPageAttachments, pageAttachments, reset: resetAttachments, update, remove, setPageIndex } = useAttachments();

  useLayoutEffect(() => setPageIndex(pageIndex), [pageIndex, setPageIndex]);

  useEffect(() =>{
    const handlePdfClick = async () => {
      try{
        const res = await DownloadFile(file_id)
        setPdfUrl(res.data.url+`#page=${page_number}`);
      }catch(err){
        console.log(err)
      }
    };

    if(file_id){
      handlePdfClick();
    }
  },[])

  useEffect(() => {
      const getPdfBlobData = async () => {
        const data = await fetch(pdfUrl);
        let blobDataAfterFetching = await data.blob();
        let metadata = {
          type: 'application/pdf'
        };
        const file_name = localStorage.getItem('fileName');
        let file = new File([blobDataAfterFetching], file_name, metadata);
        setFileBlobData(file);
      };
    
      if(pdfUrl){
        getPdfBlobData();
      }
      return () => {
        // this now gets called when the component unmounts
      };
  }, [pdfUrl]);

  const handlers = {
    pdf: async (file) => {
      try {
        const pdf = await readAsPDF(file);
        return {
          file,
          name: file.name,
          pages: Array(pdf.numPages)
            .fill(0)
            .map((_, index) => pdf.getPage(index + 1)),
        };
      } catch (error) {
        setError('Not an Pdf');
      }
    },
  };

  async function getPagesFromPdf() {
    const result = await handlers.pdf(fileBlobData);
    setResultingPdf(result)
  }

  useEffect(() => {
    if(fileBlobData){
      getPagesFromPdf();
    }
    
  },[fileBlobData])

  useEffect(() => {
    if(resultingPdf){
      initializePageAndAttachments(resultingPdf, file_id, stamp_type);
    }
  },[resultingPdf])

  const initializePageAndAttachments = (pdfDetails, file_id, stamp_type) => {
    initialize(pdfDetails,file_id,stamp_type);
    const numberOfPages = pdfDetails.pages.length;
    resetAttachments(numberOfPages);
  };

  const addSignature = (drawing) => {
    if (!drawing) return;
    const newDrawingAttachment = {
        id: ggID(),
        type: AttachmentTypes.DRAWING,
        ...drawing,
        x: 0,
        y: 0,
        scale: 1,
    }
    const newTextAttachment = {
      id: ggID(),
      ...drawing,
      x: 0,
      y: 0,
      backgroundColor: 'transparent',
      // height: 20,
      // lineHeight: 1.5,
      // width: 120,
    }
    addAttachment(drawing?.type === 'signText' ? newTextAttachment : newDrawingAttachment);
}

const addComments = (styledText) => {
  const newTextAttachment = {
      id: ggID(),
      type: AttachmentTypes.TEXT,
      x: 0,
      y: 0,
      height: 25,
      lineHeight: 'normal',
      ...styledText,
  };
  addAttachment(newTextAttachment);
};

const addHyperLinkText = (text) => {
  const newEditorAttachment = {
    id: ggID(),
    type: AttachmentTypes.EDITOR,
    ...text,
    x: 0,
    y: 0,
    size: 16,
    lineHeight: 1.4,
    // width: 120,
    height: 25,
}
  addAttachment(newEditorAttachment);
};

const handleSavePdf = () => savePdf(allPageAttachments);

function handlePageScroll(index){
  const newPageIndex = index;
  setPageIndex2(index);
  setIsFirstPage(newPageIndex === 0);
  setIsLastPage(newPageIndex === pages.length - 1);
}

const editComment = (index) => {
  setCommentModal(true); 
  setEditCommentIndex(index);
}

const updateAttachment = (data) => {
  update(editCommentIndex,data);
  setEditCommentIndex('');
}

return (
    <>
      {!file && 
        <div>
          <FullPageSpinner 
            isTextMessage={true}
            textLoaderMessage={'Loading Pdf...'}
          />
        </div>
      }
      {error && <div>{error}</div>}
      {file && currentPage &&
        <Grid container direction="row" justifyContent="center"> 
          <div className={classes.sideSection}>
            <PdfSidebar
              addSignature={() => setSignatureModalOpen(true)}
              addComments={() => setCommentModal(true)}
              addHyperLinkReference={()=> setHyperLinkModal(true)}
              updateEditedFile={handleSavePdf}
              updateDeleteFileIndex={() => remove(deleteIndex)}
              name={name}
              pageIndex={pageIndex}
              pages={pages}
            />
          </div>
          {savingLoader ? 
            <FullPageSpinner
              isTextMessage={true}
              textLoaderMessage={'Saving Pdf...'}
            /> 
            :
            <div className={classes.containerPdf}>
              {/* <div className={classes.sideWidth}>
              </div> */}
              <div className={classes.wrap}>
                <div className={classes.sideBtns}>
                    {isMultiPage && !isFirstPage && (
                      <IconButton
                        fontsize="large"
                        onClick={previousPage}
                      >
                        <ChevronLeftIcon/>
                      </IconButton>
                  )}
                </div>
                <div
                data-testid="page"
                compact
                stacked={isMultiPage && !isLastPage}
                className={classes.pageWrap}
              >
                <Page
                  dimensions={dimensions}
                  updateDimensions={setDimensions}
                  page={currentPage} 
                  scale={1}
                />
                  { dimensions && (
                    <Attachments
                      pdfName={name}
                      removeAttachment={remove}
                      updateAttachment={update}
                      pageDimensions={dimensions}
                      attachments={pageAttachments}
                      setDeleteIndex={setDeleteIndex}
                      deleteIndex={deleteIndex}
                      editComment={editComment}
                    /> 
                  )}
                </div>
                <div className={classes.sideBtns}>
                    {isMultiPage && !isLastPage && (
                      <IconButton
                        fontSize="large"
                        onClick={nextPage}
                      >
                        <ChevronRightIcon/>
                      </IconButton>
                  )}
                </div>
              </div>
              <div className={classes.sideWidth} style={{backgroundColor:'white'}}>
              {
                  pages?.map((pageCurr, index)=> {
                    return(
                      <div key={index} onClick={() => handlePageScroll(index)}>
                        <Page
                          dimensions={dimensions}
                          updateDimensions={setDimensions}
                          page={pageCurr} 
                          scale={0.25}
                          isPageOpen={index === pageIndex}
                          index={index}
                        />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          }
        </Grid>
      }
      {file && 
        <SignatureModal
          open={signatureModalOpen} 
          dismiss={() => setSignatureModalOpen(false)}
          confirm={addSignature}
        />
      }
      {file &&
        <HyperLinkModalReference 
          open={hyperlinkModal} 
          dismiss={() => setHyperLinkModal(false)}
          updateText={addHyperLinkText}
          stamp_type={stamp_type}
        />
      }
      {file &&
        <CommentModal 
          open={commentModal} 
          dismiss={() => setCommentModal(false)}
          addComments={addComments}
          attachmentData={pageAttachments[editCommentIndex]}
          updateAttachment={updateAttachment}
        />
      }
    </>
  );
}
  