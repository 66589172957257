import {
  Box,
  Button,
  Grid,
  makeStyles,
  Menu,
  Popover,
  Snackbar,
  Typography,
  Hidden,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  TextField
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import InfoIcon from '@material-ui/icons/Info';
import FolderIcon from "images/folderIcon.svg";
import React, { useEffect, useRef, useState } from "react";
import { formatDate } from "utils";
import {getOCRableFiles, OCR_files , UploadFolders, DownloadSelectedFiles, getDocumentSetForAutoRefresh, generateIndexFile, renameWorkspace, deleteSelectedFiles } from "../api/FileApi";
import FileView from "./FileView";
import FolderView from "./FolderView";
import UploadFile from "./UploadFile";
import CollabaratorFile from "./CollabaratorFile";
import UploadFolder from "./UploadFolder";
import FilledAlert from "shared/FilledAlert";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { paginate,deleteAllFiles,getNumberofPaginatedFiles } from "../api/FileApi";
import DonePopup from "features/popup/DonePopup";
import ImportModal from "./ImportModal";
import { current } from "@reduxjs/toolkit";
import POPUP from "features/popup/Popup";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useHistory } from "react-router-dom";
import { OBJECT_NOT_FOUND, FAILED, NA, APPLICATION_JSON, SUCCESS, WARNING, ERROR, ERROR_TITLE, SUCCESS_TITLE, WARNING_TITLE } from "constants/appConstants";
import LinearProgressWithLabel from "shared/LinearProgressWithLabel";
import MenusPopover from "shared/MenusPopover";
import ButtonCard from "features/ButtonCard/ButtonCard";
import Avatar from '@material-ui/core/Avatar';
import {LinearProgress }from "@material-ui/core";
import ListViewIcon from 'images/ListViewIcon.svg';
import GridViewIcon from 'images/GridViewIcon.svg';
import DownloadIcon from 'images/DownloadIcon.svg';
import { Add, Close } from "@material-ui/icons";
import CustomizedDialogs from "features/CustomisedDialogbox/CustomisedDialogbox";
import FilterSearchIcon from 'images/filter-search.svg';
import PaginationChildModal from "shared/PaginationChildModal";
import Popup from "reactjs-popup";
import { useSnackbar } from "contexts/SnackbarContext";
import DonePopupInvite from "features/popup/DonePopupInvite";
import PencilIcon from 'images/Pencil.svg';
import { checkUserIsOwnerOrNot } from "utils/checkUserIsOwnerOrNot";
// import { event } from "cypress/types/jquery";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: "2rem",
  },
  heading: {
    color: "#000",
    fontSize: "24px",
    fontWeight: "700",
    display: 'flex',
    wordBreak: 'break-word',
  },
  uploadbtn: {
    background: "#E0E0E0",
    padding: "1rem 2rem",
    cursor: "pointer",
    "&:disabled": {
      background: '#6e6e6e', color: 'white'
    }
  },
  uploadbtntext: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    textTransform: "none",
  },
  menuwidth: {
    'min-width': "150px",
    [theme.breakpoints.down("sm")]: {
      'min-width': "130px",
    },
    [theme.breakpoints.down("xs")]: {
      'min-width': "150px",
    },
  },
  files: {
    padding: "0.6rem 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  filesHover: {
    padding: "0.6rem 1rem",
    cursor: "pointer",
    background: "white",
    alignItems: 'center',
    "&:hover": {
      background: 'rgba(74, 59, 255, 0.05)',
      "& $visibilityHidden": {
        visibility: 'visible'
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  gridFilesHover: {
    padding: "0.6rem 1rem",
    cursor: "pointer",
    //background: "white",
    "&:hover": {
      background: 'rgba(74, 59, 255, 0.05)',
      "& $visibilityHidden": {
        visibility: 'visible'
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.3rem 0.5rem",
    },
  },
  filesandfolder: {
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    padding: "0.6rem 1.5rem",
    "&:hover": {
      background: "#E0E0E0",
    },
  },
  disableBtn: {
    background: '#6e6e6e', color: 'white',
    cursor: 'not-allowed',
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    padding: "0.6rem 1.5rem",
  },
  stamped: {
    background: "green",
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageWrapper: {
    width: '80%'
  },
  headerWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  infoWrap: {
    position: 'absolute',
    right: 0, 
    transform: 'translate(-50%, -50%)',
    width: 400,
    [theme.breakpoints.down("xs")]: {
      'width': "200px",
    },
    background: 'white',
    padding: '4px 6px',
    borderRadius: '4px'
  },
  info: {
    cursor: 'pointer'
  },
  iconButton: {
    backgroundColor: 'white',
    borderRadius: 0,
  },
  button: {
    backgroundColor: 'white',
    fontWeight: 700,
  },
  iconImage: {
    height: 20,
    width: 20,
  },
  hov: {
    cursor: 'pointer',
    "&:hover": {
      color: "black",
    },
  },
  nameWrap: {
    display: 'flex',
    marginTop: '12px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    width:' 30px',
    height: '30px',
    color: '#FFF',
    fontSize: '1rem',
    border: '2px solid #fafafa',
    marginRight: '-8px',
  },
  collabWrap: {
    display: 'flex'
  },
  progress_bar: {
    borderRadius: '4px',
    height: '5px',
  },
  visibilityHidden: {
    visibility: 'hidden',
  },
  visibility: {
    visibility: 'visible'
  },
  tableContent: {
    color: 'black',
    lineHeight: '100%',
    wordBreak: 'break-word',
  }
}));

export default function Files({
  folder_id,
  allFiles,
  filteredFiles,
  allFolders,
  reload,
  profile,
  parent_folder,
  allRoutes,
  totalPages,
  currentPage,
  handlePageChange,
  pagesList,
  pageSizeOptions,
  handleSizeChange,
  pageSize,
  allPagesList,
  isRootFolderStamped,
  rootFolder,
  openDonePopup,
  setOpenDonePopup,
  currentSorting,
  setcurrentSorting,
  currentView,
  setcurrentView,
  currentFilter,
  setcurrentFilter,
  states,
  paginationProgress,
  setPaginationProgress,
  OCRableFiles,
  setOCRableFiles,
  documentSetData,
  workspace_id,
  workspaceNameGiven,
  refreshDirectories,
  refreshFilesAndFolders,
}) {
  const classes = useStyles();
  const history = useHistory()
  const {showSnackbar,showDoneSnackbar} = useSnackbar();
  const [showNewFolderInput, setShowNewFolderInput] = useState(false);
  const [folderName, setFolderName] = useState("New Folder");
  const [isHovering, setIsHovering] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(currentFilter);
  const [selectedSort, setSelectedSort] = useState(currentSorting);
  const [paginationModal, setPaginationModal] = useState(false);
  const [workspaceName, setWorkspaceName] = useState(workspaceNameGiven);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [renameError, setRenameError] = useState(false);

  const allRoutesLength= allRoutes.length;
  const isAnyEmptyFolder = paginationProgress?.any_folder_empty;

  const handleFolderNameChange = (e) => {
    setFolderName(e.target.value);
  };
  const inputRef = useRef(null);

  const handleOnBlur = () => {
    if(folderName?.length > 300){
      showSnackbar('Maximum 300 characters are allowed',ERROR,ERROR_TITLE)
      return;
    }
    if (folderName === "New Folder") {
      setShowNewFolderInput(false);
      setFolderName("New Folder");
    } else {
      UploadFolders(folderName, folder_id).then(() => {
        setShowNewFolderInput(false);
        setFolderName("New Folder");
        reload();
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [snackBarForImport, setsnackBarForImport] = useState(false);
  const handleOpenSnackBarImport = () => {
    setsnackBarForImport(true);
  };
  const handleCloseSnackBarImport = () => {
    setsnackBarForImport(false);
  };

  function isLessThanHalfHourAgo(date) {
    const inMilliseconds = 1 * 60 * 1000;
    const oneHourAgo = Date.now() - inMilliseconds;
    return date > oneHourAgo;
  }

  let paginationStatus= documentSetData?.pagination_status;
  let paginationEndDate= formatDate(rootFolder.pagination_end_date);

  const [isStampFolderButtonDisabled, setIsStampFolderButtonDisabled] =
    useState(paginationStatus === 2);

  const [isStampButtonClicked,setIsStampButtonClicked] = useState(false);
  const [isDeleteButtonClicked,setIsDeleteButtonClicked] = useState(false);

  useEffect(() => {
    if (parent_folder.sent_for_stamping) {
      let date = new Date(parent_folder.sent_for_stamping);
      if (isLessThanHalfHourAgo(date)) {
        if (!parent_folder.is_stamped)
        setIsStampFolderButtonDisabled(true);
      }
    }
  }, [parent_folder]);
  const handleClickStampFolder = async (type) => {
    // setTimeout(() => {
    //   UpdateFoldersSize();
    // }, 10);
    setIsStampButtonClicked(true);
    if (isStampFolderButtonDisabled) {
      
    } else {
      paginate(type, workspace_id)
        .then((res) => {
          if (res?.data?.status && res.data.status === "sent") {
            reload();
            if(!rootFolder.is_stamped){
              autoRefresh();
            }
          }
        })
        .catch((error) => {
          console.log("error inside folder", error);
          // console.log(error.request.status);
          // console.log(
          //   JSON.parse(error.request.response),
          //   typeof JSON.parse(error.request.response)
          // );

          // if (
          //   error?.request?.response 
          //   // &&
          //   // JSON.parse(error.request.response).error === "All files are stamped"
          // ) {
          //   handleOpenSnackBarForStampingCompleteInFolder();
          // }
          setIsStampButtonClicked(false);
          if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
            showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
          }
        });
    }
  };

  const [anchorElSort, setanchorElSort] = useState(null);
  const handleClickonSort = (event) => {
    setanchorElSort(event.currentTarget);
  };
  const handleCloseSort = () => {
    setanchorElSort(null);
  };

  const [anchorElFilter, setanchorElFilter] = useState(null);
  const handleClickonFilter = (event) => {
    setanchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setanchorElFilter(null);
  };

  const [importStatus,setImportStatus] = useState(documentSetData?.import_status);
  const [permissions, setPermissions] = useState(documentSetData?.members);
  const [owner, setOwner] = useState(documentSetData?.owner);

  const paginationStatusList= ['No pagination','Pagination is in progress',`Paginated on ${paginationEndDate}`,`Files added since pagination on ${paginationEndDate}`]

  const stampTypeList = ['Not selected','Stamp ( as [A/4/5] )','All files starting from page 1','Continuous per bundle','Continuous across all bundles'];
  const [stampType,setStampType] = useState(documentSetData?.pagination_type);

  const [anchorElStamp, setanchorElStamp] = useState(null);
  const handleClickStamp = (event) => {
    if(isAnyEmptyFolder){
      showSnackbar('There are some empty folders. Please either delete or add some files to them.',WARNING,WARNING_TITLE);
      return;
    }
    setanchorElStamp(event.currentTarget);
  }
  const handleCloseStamp = () =>{
    setanchorElStamp(null);
  }

  const handleClickRestamp = () => {
    if(isAnyEmptyFolder){
      showSnackbar('There are some empty folders. Please either delete or add some files to them.',WARNING,WARNING_TITLE);
      return;
    }
    handleClickStampFolder(stampType);
  }

  const handleStamp_stamp = () =>{
    handleCloseStamp();
    setStampType(1);
    handleClickStampFolder(1);
  }
  const handleStamp_paginate_from_1 = () =>{
    handleCloseStamp();
    setStampType(2);
    handleClickStampFolder(2)
  }
  const handleStamp_continuous_per_bundle = () =>{
    handleCloseStamp();
    setStampType(3);
    handleClickStampFolder(3)
  }
  const handleStamp_continuous_across_all_bundles = () =>{
    handleCloseStamp();
    setStampType(4);
    handleClickStampFolder(4);
  }

  const createNewFolder = () => {
    setShowNewFolderInput(true);
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 100);
  };
  const [openmodalUploadFolder, setOpenmodalUploadFolder] = useState(false);
  const handleOpenUploadFolderModal = () => {
    setOpenmodalUploadFolder(true);
  };
  const handleCloseUploadFolderModalAndRefresh = () => {
    reload();
    setOpenmodalUploadFolder(false);
    setAnchorEl(null);
  };
  const handleCloseUploadFolderModal = () => {
    setOpenmodalUploadFolder(false);
    setAnchorEl(null);
  };
  const [openmodalUploadFile, setOpenmodalUploadFile] = useState(false);
  const handleOpenUploadFileModal = () => {
    setOpenmodalUploadFile(true);
  };

  const [collabaratorModal, setCollabaratorModal]= useState(false);

  const handleCollabaratorModalOpen = () => {
    setCollabaratorModal(!collabaratorModal);
  }

  const handleCloseUploadFileModalAndRefresh = () => {
    reload();
    setOpenmodalUploadFile(false);
    setAnchorEl(null);
  };
  const handleCloseUploadFileModal = () => {
    setOpenmodalUploadFile(false);
    setAnchorEl(null);
  };

  const handleFilter = (filterType,sortingType) => {
    handleCloseFilter();
    states.filter = filterType;
    states.sorting = sortingType;
    window.sessionStorage.setItem('states', JSON.stringify(states));
    setcurrentFilter(filterType);
    setcurrentSorting(sortingType);
    refreshFilesAndFolders(filterType,sortingType);
  }

  const clearAllFilter = () => {
    handleFilter('allType','name');
    setSelectedFilter('allType');
    setSelectedSort('name');
  }

  const listView = () => {
    states.view = 'list';
    window.sessionStorage.setItem('states', JSON.stringify(states));
    setcurrentView("list");
  };
  const gridView = () => {
    states.view = 'grid';
    window.sessionStorage.setItem('states', JSON.stringify(states));
    setcurrentView("grid");
  };

  const handleDeleteFilesAndFolders= () => {
    // eslint-disable-next-line no-restricted-globals
    var check = confirm('Are you want to delete selected files and folder?');
    if(!check)
      return;
    setIsDeleteButtonClicked(true);
    const data = {
      file_ids: selectedFiles,
      folder_ids: selectedFolders,
      workspace_id: workspace_id,
    }
    deleteSelectedFiles(data)
    .then((res)=>{
      showSnackbar('Selected files and folders are deleted...',SUCCESS,SUCCESS_TITLE);
      reload();
    })
    .catch((error)=>{
      setIsDeleteButtonClicked(false);
      if((error?.response?.status === 424 || error?.response?.status === 400) && error?.response?.data?.error){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    })
  }

  const autoRefresh = () => {
    getNumberofPaginatedFiles(workspace_id)
    .then((res)=>{
      setPaginationProgress(res?.data);
      if(res.data.number_of_pages_paginated === res.data.total_number_of_files){
          setTimeout(()=>{
            // handleDonePopupOnPaginate();
            showDoneSnackbar('Files paginated successfully','Paginate');
            reload();
          },1000)
      }
      else{
        setTimeout(() => {
        autoRefresh(); 
        },1000);
      }
    })
    .catch((err)=>{
      console.log(err);
    })
  };

  useEffect(()=>{
    if(paginationStatus === 1){
      autoRefresh();
    }
    if(OCRableFiles?.status === "OCR in progress"){
      autoRefreshOnOCR();
    }
    if(importStatus === 'in_progress'){
      autoRefreshOnImport();
    }
  },[])

  const handleDonePopupOnPaginate = () => {
    setOpenDonePopup(true);
  }
  const handleCloseDonePopup = () => {
    setOpenDonePopup(false);
    reload();
  }

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const [openModalforImport, setOpenModalforImport] = useState(false);
  const handleOpenImportModal = () => {
    setOpenModalforImport(true);
  };
  const handleCloseImportModal = () => {
    setOpenModalforImport(false);
  };

  const [OCRPopupOpener,setOCRPopupOpener] = useState(false);
  const handleClickOnOCR = () => {
    setOCRPopupOpener(true);
  }
  const handleCloseOnOCR = () => {
    setOCRPopupOpener(false);
  }
  const handleProceedOCR = () => {
    OCR_files(workspace_id)
    .then((res)=>{
      showSnackbar(res.data?.success,SUCCESS,SUCCESS_TITLE);
      setOCRableFiles(res.data?.files_list);
      handleCloseOnOCR();
      autoRefreshOnOCR();
    })
    .catch((error)=>{
      console.log(error);
      if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    })
  }

  const OCRableFileRow = (files) => {
    let i= -1;
    return (
      <Grid container style={{display:'grid','overflow-y':'scroll', maxHeight: '60vh'}}>
        <Grid item>
          <Typography style={{fontSize:'0.8rem'}}>{OCRableFiles.total_number_of_files} files found</Typography>
        </Grid>
        {files.map((file)=>{
          ++i;
          return <Grid item key={file.id} style={{borderTop:'1px solid #eeeeee'}}>
            <b style={{color:'black'}}>{file.name}</b> in <b style={{color:'black'}}>{OCRableFiles.parent_names[i]}</b>
          </Grid>
        })}
        {/* {OCRableFiles.total_number_of_files > 10 &&
          <Grid item>
            <Typography>{OCRableFiles.total_number_of_files-10} more files...</Typography>
          </Grid>
        } */}
      </Grid>
    )
  }

  const autoRefreshOnOCR = () => {
    getOCRableFiles(workspace_id)
    .then((res)=>{
      setOCRableFiles(res?.data);
      if(res.data?.ocrable_files.length === 0){
        showDoneSnackbar('OCR is done.','OCR');
        reload();
      }
      else{
        setTimeout(() => {
          autoRefreshOnOCR(); 
        },1000);
      }
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  const autoRefreshOnImport = () => {
    getDocumentSetForAutoRefresh(workspace_id)
    .then((res)=>{
      console.log(res);
      if(res?.data?.import_status === NA){
        showSnackbar('Import is done',SUCCESS,SUCCESS_TITLE);
        reload();
      }
      else if(res?.data?.import_status === OBJECT_NOT_FOUND){
        showSnackbar('No files or folders found at this link.',ERROR,ERROR_TITLE);
        reload();
      }
      else if(res?.data?.import_status === FAILED){
        showSnackbar('We apologize for the hiccup in the Importing process. While we investigate the issue, we kindly request you to click the button below to return to the dashboard.',ERROR,ERROR_TITLE);
        reload();
      }
      else{
        setTimeout(() => {
          autoRefreshOnImport(); 
        },1000);
      }
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  const handleDownloadZipFiles = () => {
    const data = {
      folder_ids: selectedFolders,
      file_ids: selectedFiles,
      workspace_id: workspace_id,
    }
    DownloadSelectedFiles(data)
    .then((res)=>{
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "application/zip" })
       );
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("saveas", "generate.zip");
       document.body.appendChild(link);
       link.click();
    })
    .catch(async (error)=>{
      console.log(error);
      if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
        // Parsing the blob data into json
        const isJsonBlob = (data) => data instanceof Blob && data.type === APPLICATION_JSON;
        const errorResponseData = isJsonBlob(error?.response?.data) ? await (error?.response?.data)?.text() : error?.response?.data || {};
        const errorResponseJson = (typeof errorResponseData === "string") ? JSON.parse(errorResponseData) : errorResponseData;
        showSnackbar(errorResponseJson?.error,ERROR,ERROR_TITLE);
      }
    })
  }

  const permissionToImportFolder = () => {
    if((((isStampFolderButtonDisabled || paginationStatus === 3) && (stampType === 4 || stampType === 3) && allRoutesLength === 1 && (rootFolder.pagination_end_date > parent_folder.created)))){
      return false;
    }
    else{
      return true;
    }
  }

  const handleClickIndex = () => {
    generateIndexFile(workspace_id)
    .then((res)=>{
      if(res?.data?.success){
        showSnackbar(res.data.success,SUCCESS,SUCCESS_TITLE);
        reload();
      }
    })
    .catch((error)=>{
      if(error?.response?.status !== 0 && error?.response?.status !== 404 && error?.response?.status !== 500 && error?.response?.status !== 401){
        showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
      }
    })
  }

  const uploadButtonMenus = {
    className: classes.filesandfolder,
    menuItems: [
      {
        item: 'files',
        title: 'Files',
        onClick: handleOpenUploadFileModal,
        style: {}
      },
      {
        item: 'folder',
        title: 'Folder',
        onClick: handleOpenUploadFolderModal,
        style:  ((isStampFolderButtonDisabled || paginationStatus === 3) && (stampType === 4 || stampType === 3) && allRoutesLength === 1) ? {display:'none'} : null,
      }
    ]
  }

  const paginateButtonMenus = {
    className: classes.filesandfolder,
    menuItems: [
      {
        item: 'stamp',
        title: 'Stamp',
        onClick: handleStamp_stamp,
        style: {},
      },
      {
        item: 'paginate_from_1',
        title: 'Paginate from 1',
        onClick: handleStamp_paginate_from_1,
        style: {},
      },
      {
        item: 'continuous_per_bundle',
        title: 'Continuous per bundle',
        onClick: handleStamp_continuous_per_bundle,
        style: {},
      },
      {
        item: 'continuous_across_all_bundles',
        title: 'Continuous across all bundles',
        onClick: handleStamp_continuous_across_all_bundles,
        style: {},
      },
    ]
  }

  const Paginate_Button_Card_Content = () => {
    let paginatedFiles = paginationProgress.number_of_pages_paginated;
    let totalFiles = paginationProgress.total_number_of_files;
    let percent = paginatedFiles  * 100 / totalFiles;
    return (
      <Box>
        {
          (rootFolder.file_count  === 0 && rootFolder.folder_count === 0) ?
          <Typography>Upload files to Paginate</Typography> :
          <>
            <Typography>{paginatedFiles} / {totalFiles} paginated</Typography>
            <Typography>Type - {stampTypeList[stampType]}</Typography>
            <Box  style={{width: '90%', padding: '16px 0'}}>
              <LinearProgress variant="determinate" color='secondary' value={percent} className={classes.progress_bar} />
            </Box>
          </>
        }
      </Box>
    )
  }

  const OCR_Button_Card_Content = () => {
    let ocredFiles = OCRableFiles?.number_of_ocred_files;
    let totalOcrableFiles = OCRableFiles?.number_of_ocred_files + OCRableFiles?.number_of_ocrable_files;
    let percent = ocredFiles * 100 / totalOcrableFiles;
    const COLORS_ARRAY = ['#3498db', '#e74c3c', '#2ecc71', '#f1c40f', '#9b59b6'];
    return (
      <Box>
        {
          (rootFolder.file_count  === 0 && rootFolder.folder_count === 0) ?
          <Typography>Upload files to OCR</Typography> :
          <>
            <Typography>{ocredFiles} / {totalOcrableFiles} converted</Typography>
            <Box style={{height:'1.5rem'}}></Box>
            <Box  style={{width: '90%', padding: '16px 0'}}>
              <LinearProgress variant="determinate" color="primary" value={percent} className={classes.progress_bar} />
            </Box>
          </>
        }
      </Box>
    )
  }

  const Add_Collaborator_Button_Content = () => {
    const COLORS_ARRAY = ['#3498db', '#e74c3c', '#2ecc71', '#f1c40f', '#9b59b6'];
    const addButtonStyles = {
      backgroundColor: 'rgba(74, 59, 255, 0.10)',
      border: '2px dashed #4A3BFF',
      color: '#4A3BFF',
      marginLeft: '12px',
      cursor: 'pointer',
    }
    return (
      <Box>
        <Typography style={{color:'#808080'}}>Add Case Collaborators</Typography>
        <Grid className={classes.collabWrap}>
          {permissions?.slice(0,4).map((item, index) => {
            const { first_name, last_name } = item.user;
            const firstNameInitial = first_name.charAt(0);
            const lastNameInitial = last_name.charAt(0);

            const colorIndex = index % COLORS_ARRAY.length;
            return (
              <div key={index} className={classes.nameWrap} style={{ backgroundColor: COLORS_ARRAY[colorIndex] }}>
                <div>{firstNameInitial}</div>
                <div>{lastNameInitial}</div>
              </div>
            );
          })}
          {
            (permissions?.length > 4) && (
              <div className={classes.nameWrap} style={{backgroundColor: 'green',cursor: 'pointer' }} onClick={handleCollabaratorModalOpen}>
                <div>+{permissions?.length-4}</div>
              </div>
            )
          }
          <div className={classes.nameWrap} style={addButtonStyles} onClick={handleCollabaratorModalOpen}>
            <Add/>
          </div>
        </Grid>
      </Box>
    )
  }

  function handlePaginateModalOpen(){
    //handleClickStamp
    if(isAnyEmptyFolder){
      showSnackbar('There are some empty folders. Please either delete or add some files to them.',WARNING,WARNING_TITLE);
      return;
    }
    setPaginationModal(!paginationModal);
  }

  const ButtonCards = [
    {
      title: 'Paginate',
      buttonText: (paginationStatus === 3 ? 'Re-paginate' 
                  : paginationStatus === 2 ? 'All files paginated' 
                  : paginationStatus === 1 ? `Paginating...` 
                  : 'Paginate'),
      onSubmit: (stampType === 0) ? handlePaginateModalOpen : handleClickRestamp,
      isButtonDisabled: isStampFolderButtonDisabled || (allFiles.length  === 0 && allFolders.length === 0) || isStampButtonClicked || paginationStatus === 1 || importStatus === "in_progress" || paginationStatus === 2 || OCRableFiles?.status === "OCR in progress",
      content: <Paginate_Button_Card_Content/>
    },
    {
      title: 'OCR',
      buttonText: OCRableFiles?.status === "OCR in progress" ? 'OCR in progress' : 'OCR',
      onSubmit: handleClickOnOCR,
      isButtonDisabled: OCRableFiles?.ocrable_files.length === 0 || OCRableFiles?.status === "OCR in progress" ||  paginationStatus === 1,
      content: <OCR_Button_Card_Content/>
    },
    {
      title: 'Complete Bundle',
      buttonText: 'Index',
      onSubmit: handleClickIndex,
      isButtonDisabled: paginationStatus !== 2,
      content: <Typography style={{color:'#696871'}}>Create a comprehensive index or table of contents to quickly find specific sections or information within a large document set</Typography>
    },
    {
      avatar: <Avatar>{`${owner?.user.first_name.charAt(0)}${owner?.user.last_name.charAt(0)}`}</Avatar>,
      title: `${owner?.user.first_name} ${owner?.user.last_name}`,
      subTitle: 'Owner',
      content: <Add_Collaborator_Button_Content/>
    }
  ]

  const [isUploadFileMenuOpen, setUploadFileMenuOpen] = useState(false);
  const handleClickOnUploadFile = () => {
    setUploadFileMenuOpen(true);
  }
  const onCloseUploadFileMenu = () => {
    setUploadFileMenuOpen(false);
  }

  const [snackBarForAssigned, setsnackBarForAssigned] = useState(false);
  const handleOpenSnackBarAssigned = () => {
    setsnackBarForAssigned(true);
  };
  const handleCloseSnackBarAssigned = () => {
    setsnackBarForAssigned(false);
  };

  const handleEditClick = () => {
    const isUserOwnerOfWorkspace = checkUserIsOwnerOrNot(owner,profile);
    if( !isUserOwnerOfWorkspace ){
      showSnackbar('Only Owner can rename the workspace.',ERROR,ERROR_TITLE);
      return;
    }
    setIsEditing(true);
  };

  const handleChangeWorkspaceName = (e) => {
    const value = e.target.value;
    if(value?.length > 300){
      setRenameError('Max 300 characters are allowed');
    }
    else{
      setRenameError(false);
    }
    setWorkspaceName(value);
  }

  const handleRenameWorkspaceOnClick = () => {
    if (workspaceName.trim() === "") {
      // Don't allow empty workspace names
      alert("Workspace name cannot be empty");
      return;
    }
    if(workspaceName?.length > 300){
      return;
    }
    // Make an API call to rename the workspace
    renameWorkspace(workspace_id, { name: workspaceName })
      .then((res) => {
        setIsEditing(false);
        refreshDirectories();
        showSnackbar('Workspace Renamed successfully',SUCCESS,SUCCESS_TITLE);
      })
      .catch((error) => {
        if ((error?.response?.status === 424 || error?.response?.status === 400) && error?.response?.data?.error) {
          showSnackbar(error?.response?.data?.error,ERROR,ERROR_TITLE);
          setIsEditing(false);
          setWorkspaceName(workspaceNameGiven);
      }
    });
  };

  const handleChangeOnFileCheckbox= (event) => {
    if(event.target.checked){
      selectedFiles.push(event.target.id);
      setSelectedFiles([...selectedFiles]);
    }
    else{
      setSelectedFiles(()=>{
        return selectedFiles.filter((x)=>{
          return x !== event.target.id
        })
      })
    }
  }

  const handleChangeOnFolderCheckbox= (event) => {
    if(event.target.checked){
      selectedFolders.push(event.target.id);
      setSelectedFolders([...selectedFolders]);
    }
    else{
      setSelectedFolders(()=>{
        return selectedFolders.filter((x)=>{
          return x !== event.target.id
        })
      })
    }
  }

  const unSelectAll = () => {
    setSelectedFiles([]);
    setSelectedFolders([]);
  }

  return (
    <Box className={classes.root}>
      <div className={classes.headerWrap}>
        <Typography className={classes.heading}>
          {isEditing ? (
            <TextField
                className={classes.heading}
                type="text"
                value={workspaceName}
                onChange={handleChangeWorkspaceName}
                onBlur={handleRenameWorkspaceOnClick}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleRenameWorkspaceOnClick();
                  }
                }}
                error={renameError}
                helperText={renameError}
              />
          ) : (
            <>
              {workspaceName}
              <img
                style={{ marginLeft: '16px', cursor: 'pointer' }}
                src={PencilIcon}
                onClick={handleEditClick}
              />
            </>
          )}
        </Typography>
        {/* <Typography className={classes.heading}>{workspaceName}<img style={{marginLeft: '16px', cursor: 'pointer'}} src={PencilIcon}/></Typography> */}
        {/* { (allFolders.length !== 0 || allFiles.length !== 0) && 
          <Grid container style={{justifyContent:"end",width:'50%'}}>
            <div className={classes.info}>
              <InfoIcon 
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                style={{color:'#656565'}}
              />
            </div>
            {isHovering ? 
            <div className={classes.infoWrap}>
              <Grid item>
                <Typography className={classes.uploadbtntext}>Pagination status: <b>{paginationStatusList[paginationStatus]}</b></Typography>
                <Typography className={classes.uploadbtntext}>Pagination type:  <b> {stampTypeList[stampType]}</b></Typography>
                <Typography className={classes.uploadbtntext}><FiberManualRecordIcon style={{color:'#2f80ed',fontSize:'small'}}/> -  <b> File that can be OCRd</b></Typography>
              </Grid>
            </div>
              : null
            }
          </Grid>
          
        } */}
      </div>
      <Box height="2rem" />
      <Grid container spacing={2}>
        {
          ButtonCards.map((card,index)=>{
            return (
              <Grid item lg={3} md={3} sm={3} xs={6} key={index}>
                <ButtonCard
                  avatar={card?.avatar}
                  title={card?.title}
                  subTitle={card?.subTitle}
                  buttonText={card?.buttonText}
                  onSubmit={card?.onSubmit}
                  isButtonDisabled={card?.isButtonDisabled}
                >
                  {card?.content}
                </ButtonCard>
              </Grid>
            )
          })
        }
      </Grid>
      <Box height="1.5rem" />
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        disableTouchRipple
        style={{ backgroundColor: "transparent", cursor: "default", paddingLeft:'0px' }}
      >
        <Grid container spacing={1}>
          <Grid item>
            <Typography
              className={`${classes.hov}`}
              style={allRoutesLength === 0 ? { color: '#4A3BFF' } : null}
              onClick={() => {
                history.push(`/folder/${documentSetData?.root_folder?.id}/${workspace_id}`);
                handlePageChange(1);
              }}
            >
              Files
            </Typography>
            
          </Grid>
          {allRoutes.map((route,i) => {
            return (
              <React.Fragment key={route.id}>
                <Grid item>
                  <KeyboardArrowRightIcon style={{ color: "black" }} />
                </Grid>
                <Grid item>
                  <Typography
                    className={`${classes.hov}`}
                    style={i === allRoutesLength-1 ? { color: '#4A3BFF' }:null}
                    onClick={() => {
                      history.push(`/folder/${route.id}/${workspace_id}`);
                      handlePageChange(1);
                    }}
                  >
                    {route.nameToShowUser}
                  </Typography>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Button>
      <Box height="1rem" />
      <Grid container spacing={2} alignItems="center">
        {
          selectedFiles.length === 0 && selectedFolders.length === 0 ? (
            <>
              <Grid item>
                <Button
                  onClick={handleOpenUploadFileModal}
                  color="primary"
                  variant="contained"
                  startIcon={<Add />}
                  style={ ((isStampFolderButtonDisabled || paginationStatus === 3) && ((stampType === 3 && allRoutesLength > 1 && (rootFolder.pagination_end_date > parent_folder.created)) ||
                            (stampType === 4 && allRoutesLength > 0 && (rootFolder.pagination_end_date > parent_folder.created)))) ||
                            (paginationStatus === 1) ?
                            {display:'none'} : null
                  }
                >
                  Upload file
                </Button>
              </Grid>
              <Grid item xs></Grid>
              <Grid item>
                <Button
                  onClick={createNewFolder}
                  className={classes.button}
                  color="primary"
                  startIcon={<Add />}
                  style={ ((isStampFolderButtonDisabled || paginationStatus === 3) && ((stampType === 3 && allRoutesLength > 0 && (rootFolder.pagination_end_date > parent_folder.created)) ||
                            (stampType === 4 && allRoutesLength > 0 && (rootFolder.pagination_end_date > parent_folder.created)))) ||
                            (paginationStatus === 1) ?
                            {display:'none'} : null
                  }
                >
                  Create folder
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Typography style={{fontSize:'1.2rem',color:'black'}}>
                <IconButton onClick={unSelectAll}>
                  <Close/>
                </IconButton>
                {selectedFiles.length+selectedFolders.length} Selected
              </Typography>
              <Grid item xs></Grid>
              <Grid item>
                <IconButton
                  onClick={handleDownloadZipFiles} 
                  disabled={(allFiles.length  === 0 && allFolders.length === 0)}
                  style={
                    paginationStatus === 1 ? {display:'none'} : null
                  } 
                >
                  <img src={DownloadIcon} alt='download' className={classes.iconImage}/>
                </IconButton>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleDeleteFilesAndFolders}
                  className={classes.button}
                  disabled={(allFiles.length  === 0 && allFolders.length === 0) || isDeleteButtonClicked}
                  style={
                    paginationStatus === 1 ? {display:'none'} : null
                  }
                >
                  <span style={{lineHeight: '20px',}}>Delete</span>
                </Button>
              </Grid>
            </>
          )
        }
        <Grid item>
          <IconButton
            aria-controls="filter-menu"
            aria-haspopup="true"
            className={classes.iconButton}
            onClick={handleClickonFilter}
          >
            <img src={FilterSearchIcon} className={classes.iconImage} alt="Filter icon"></img>
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            className={classes.iconButton}
            onClick={currentView === 'list' ? gridView : listView}
          >
            <img src={currentView === 'list' ? GridViewIcon : ListViewIcon} className={classes.iconImage} alt="View icon"></img>
          </IconButton>
        </Grid>
        
      </Grid>
      <MenusPopover
        id="paginate-menu"
        anchorEl={anchorElStamp}
        open={Boolean(anchorElStamp)}
        onClose={handleCloseStamp}
        menus={paginateButtonMenus}
      />
      <Popover
        id="view-menu"
        anchorEl={anchorElFilter}
        keepMounted
        open={Boolean(anchorElFilter)}
        onClose={handleCloseFilter}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box >
          <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={12} style={{padding: '1rem'}}>
              <FormLabel style={{color: '#B0B7C3',lineHeight: '1.32rem'}}>Filter</FormLabel>
              <RadioGroup aria-label="filter" name="filter">
                <FormControlLabel value="pdf" control={<Checkbox color='primary' checked={selectedFilter === 'pdf'} onChange={(event)=>{setSelectedFilter(event.target.value)}}/>} label="Pdf" />
                <FormControlLabel value="word" control={<Checkbox color='primary' checked={selectedFilter === 'word'} onChange={(event)=>{setSelectedFilter(event.target.value)}}/>} label="Word" />
                <FormControlLabel value="excel" control={<Checkbox color='primary' checked={selectedFilter === 'excel'} onChange={(event)=>{setSelectedFilter(event.target.value)}}/>} label="Excel" />
                <FormControlLabel value="image" control={<Checkbox color='primary' checked={selectedFilter === 'image'} onChange={(event)=>{setSelectedFilter(event.target.value)}}/>} label="Image" />
                <FormControlLabel value="allType" control={<Checkbox color='primary' checked={selectedFilter === 'allType'} onChange={(event)=>{setSelectedFilter(event.target.value)}}/>} label="All Type" />
              </RadioGroup>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} style={{padding: '1rem'}}>
              <FormLabel style={{color: '#B0B7C3',lineHeight: '1.32rem'}}>Sort by</FormLabel>
              <RadioGroup aria-label="sort" name="sort" >
                <FormControlLabel value="name" control={<Checkbox color='primary' checked={selectedSort === 'name'} onChange={(event)=>{setSelectedSort(event.target.value)}}/>} label="Name" />
                <FormControlLabel value="size" control={<Checkbox color='primary' checked={selectedSort === 'size'} onChange={(event)=>{setSelectedSort(event.target.value)}}/>} label="Size" />
                <FormControlLabel value="date_modified" control={<Checkbox color='primary' checked={selectedSort === 'date_modified'} onChange={(event)=>{setSelectedSort(event.target.value)}}/>} label="Date modified" />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container style={{borderTop: '1px solid #F3F3F3'}} >
            <Grid item lg={6} md={6} sm={6} xs={6} style={{borderRight: '1px solid #F3F3F3'}}>
              <Button fullWidth style={{padding: '1rem',color:'#7C7C7D'}} onClick={clearAllFilter}>
                Clear All
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button color="primary" fullWidth style={{padding: '1rem', fontWeight: 700}} onClick={()=>{handleFilter(selectedFilter,selectedSort)}}>
                APPLY
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
      <Box height="1.5rem" />
      <Box height='1.5rem'></Box>
      {currentView === "list" ? ( // List view
        <>
          <Grid
            container
            style={{ background: "rgba(74, 59, 255, 0.05)" }}
            className={classes.files}
            spacing={1}
          >
            <Grid item lg={4} md={4} sm={4} xs={7}>
              <div style={{display:'flex',verticalAlign:'center'}}>
                <Checkbox 
                  color="primary"
                  style={{padding:'5px',visibility:'hidden'}}
                  // onChange={handleSelectAll}
                />
                <Typography
                  style={{
                    color: '#000',
                    fontSize: '16px',
                    fontWeight: '700'
                  }}
                >
                  <FiberManualRecordIcon style={{visibility:'hidden',fontSize:'small'}}/>
                  Name
                </Typography>
              </div>
            </Grid>
            <Hidden xsDown>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography
                  style={{
                    color: '#000',
                    fontSize: '16px',
                    fontWeight: '700'
                  }}
                >
                  File prefix
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography
                  style={{
                    color: '#000',
                    fontSize: '16px',
                    fontWeight: '700',
                    wordBreak: "break-all",
                  }}
                >
                  Pages
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography
                  style={{
                    color: '#000',
                    fontSize: '16px',
                    fontWeight: '700',
                    wordBreak: "break-all",
                  }}
                >
                  Size
                </Typography>
              </Grid>
            </Hidden>
            <Grid item lg={2} md={2} sm={2} xs={4}>
              <Typography
                style={{
                  color: '#000',
                  fontSize: '16px',
                  fontWeight: '700',
                  wordBreak: "break-all",
                }}
              >
                Uploaded by
              </Typography>
            </Grid>
            <Hidden xsDown>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography
                  style={{
                    color: '#000',
                    fontSize: '16px',
                    fontWeight: '700',
                    wordBreak: "break-all",
                  }}
                >
                  Status
                </Typography>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={3}>
                <Typography
                  style={{
                    color: '#000',
                    fontSize: '16px',
                    fontWeight: '700',
                    wordBreak: "break-all",
                  }}
                >
                  Modified on
                </Typography>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
            </Hidden>
          </Grid>
          {/* folders and files */}
          {showNewFolderInput && (
            <Grid container className={classes.filesHover} spacing={1}>
              <Grid item lg={4} md={4} sm={4} xs={7}>
                <div style={{display:'flex',alignItems:'center'}}>
                  <Checkbox 
                    color="primary"
                    style={{padding:'5px',visibility:'hidden'}}
                  />
                  <span>
                    <FiberManualRecordIcon style={{visibility:'hidden',fontSize:'small'}}/>
                    <img src={FolderIcon} alt="folder" style={{width: '20px', height: '16px'}}/>
                  </span>
                  <Typography
                    className={classes.tableContent}
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <input
                      ref={inputRef}
                      value={folderName}
                      style={{ width: "70%" }}
                      onChange={handleFolderNameChange}
                      onBlur={handleOnBlur}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleOnBlur();
                        }
                      }}
                    />
                  </Typography>
                </div>
              </Grid>
              <Hidden xsDown>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <Typography
                    className={classes.tableContent}
                  ></Typography>
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <Typography
                    className={classes.tableContent}
                  ></Typography>
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <Typography
                    className={classes.tableContent}
                  ></Typography>
                </Grid>
              </Hidden>
              <Grid item lg={2} md={2} sm={2} xs={4}>
                <Typography
                  className={classes.tableContent}
                >
                  <div style={{display:'flex',gap:6,alignItems:'center'}}>
                    <Avatar style={{height:30,width:30,backgroundColor:'#FFAB00'}}>
                      {profile.user.first_name.charAt(0)}{profile.user.last_name.charAt(0)}
                    </Avatar>
                    {profile.user.first_name} {profile.user.last_name}
                  </div>
                </Typography>
              </Grid>
              <Hidden xsDown>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={3}>
                  <Typography
                    className={classes.tableContent}
                  >
                    {formatDate(new Date())}
                  </Typography>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              </Hidden>
            </Grid>
          )}
          {allFolders.map((folder) => {
            return (
              <FolderView
                folder={folder}
                key={folder.id}
                classes={classes}
                reload={reload}
                profile={profile}
                parent_folder={parent_folder}
                view={currentView}
                handlePageChange={handlePageChange}
                paginationStatus={paginationStatus}
                currentPage={currentPage}
                pageSize={pageSize}
                workspace_id={workspace_id}
                handleChangeOnFolderCheckbox={handleChangeOnFolderCheckbox}
                selectedFolders={selectedFolders}
              />
            );
          })}
          {filteredFiles.map((file) => {
            return (
              <FileView
                file={file}
                key={file.id}
                classes={classes}
                reload={reload}
                profile={profile}
                parent_folder={parent_folder}
                allRoutes={allRoutes}
                view={currentView}
                paginationStatus={paginationStatus}
                OCRableFiles={OCRableFiles}
                isRootFolderStamped={isRootFolderStamped}
                stampType={documentSetData?.pagination_type}
                handleChangeOnFileCheckbox={handleChangeOnFileCheckbox}
                selectedFiles={selectedFiles}
              />
            );
          })}
        </>
      ) : (
        //Grid view.................
        <Grid container spacing={3}>
          {showNewFolderInput && (
            <Grid
              item
              lg={2}
              md={3}
              sm={3}
              xs={4}
              className={classes.gridFilesHover}
            >
              <Card>
                <CardMedia
                  component={"img"}
                  height=""
                  image={FolderIcon}
                  style={{
                    width: "40%",
                    margin: "auto",
                    padding: '12%'
                  }}
                />
                <CardContent
                  style={{ padding: "15px", borderTop: "1px solid #eeeeee" }}
                >
                  <Grid container style={{height:'100%'}}>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <Typography
                        style={{
                          color: "#4F4F4F",
                          fontWeigt: "500",
                          fontFamily: `'Inter',sans-serif`,
                          fontSize: "14px",
                          wordBreak: "break-all",
                        }}
                      >
                        {/* <span>
                          <img src={FolderImage} alt="folder" />
                        </span> */}
                        <span style={{ marginLeft: "1rem" }}>
                          <input
                            ref={inputRef}
                            value={folderName}
                            style={{ width: "70%" }}
                            onChange={handleFolderNameChange}
                            onBlur={handleOnBlur}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleOnBlur();
                              }
                            }}
                          />
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {allFolders.map((folder) => {
            return (
              <FolderView
                folder={folder}
                key={folder.id}
                classes={classes}
                reload={reload}
                profile={profile}
                parent_folder={parent_folder}
                view={currentView}
                handlePageChange={handlePageChange}
                paginationStatus={paginationStatus}
                currentPage={currentPage}
                pageSize={pageSize}
                workspace_id={workspace_id}
                handleChangeOnFolderCheckbox={handleChangeOnFolderCheckbox}
                selectedFolders={selectedFolders}
              />
            );
          })}
          {filteredFiles.map((file) => {
            return (
              <FileView
                file={file}
                key={file.id}
                classes={classes}
                reload={reload}
                profile={profile}
                parent_folder={parent_folder}
                allRoutes={allRoutes}
                view={currentView}
                paginationStatus={paginationStatus}
                OCRableFiles={OCRableFiles}
                isRootFolderStamped={isRootFolderStamped}
                stampType={documentSetData?.pagination_type}
                handleChangeOnFileCheckbox={handleChangeOnFileCheckbox}
                selectedFiles={selectedFiles}
              />
            );
          })}
        </Grid>
      )}
      <Box height="4rem" />
      <div className={classes.paginationWrapper}>
        <div>
          <IconButton
            onClick={() => {
              handlePageChange(currentPage - 1);
            }}
            disabled={currentPage === 1}
          >
            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
          </IconButton>
          {pagesList.map((page_no,index) => {
            return (
              <Button
                key={index}
                size="small"
                onClick={() => {
                  handlePageChange(page_no);
                }}
                disabled={currentPage === page_no}
                style={
                  currentPage === page_no
                    ? {
                        background: "#6e6e6e",
                        color:'white',
                        borderRadius: "40px",
                      }
                    : { borderRadius: "40px" }
                }
              >
                <Typography style={currentPage === page_no? { lineHeight: "24px",color: 'white' }:{ lineHeight: '24px' }}>{page_no}</Typography>
              </Button>
            );
          })}
          <IconButton
            onClick={() => {
              handlePageChange(currentPage + 1);
            }}
            disabled={currentPage === totalPages}
          >
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </IconButton>
        </div>
        <Box height="1.5rem" />
        
        <div className={classes.pageWrapper}>
          <Grid container style={{justifyContent:'end'}}>
            <Grid item xs={3} sm={2} style={{ marginLeft: "20px" }}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="demo-simple-select-label">
                  No. of files displayed
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageSize}
                  label="Page Size"
                  onChange={(e) => {
                    handleSizeChange(e.target.value);
                  }}
                >
                  {pageSizeOptions.map((page,index) => (
                    <MenuItem key={index} value={page}>
                      <Typography>{page}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} sm={2} style={{ marginLeft: "20px" }}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="demo-simple-select-label-2">Go to Page</InputLabel>
                <Select
                  labelId="demo-simple-select-label-2"
                  id="demo-simple-select"
                  value={currentPage}
                  label="Go to Page"
                  onChange={(e) => {
                    handlePageChange(e.target.value);
                  }}
                >
                  {allPagesList.map((page,index) => (
                    <MenuItem key={index} value={page}>
                      <Typography>{page}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>
      <Box height="3rem" />

      {/* folder and files atop */}
      {openmodalUploadFolder && (
        <UploadFolder
          openmodal={openmodalUploadFolder}
          handleCloseModal={handleCloseUploadFolderModal}
          folder_id={folder_id}
          handleCloseModalRefresh={handleCloseUploadFolderModalAndRefresh}
          parent_folder={parent_folder}
        />
      )}
      {openmodalUploadFile && (
        <UploadFile
          openmodal={openmodalUploadFile}
          handleCloseModal={handleCloseUploadFileModal}
          folder_id={folder_id}
          handleCloseModalRefresh={handleCloseUploadFileModalAndRefresh}
          parent_folder={parent_folder}
          setImportStatus={setImportStatus}
          // handleOpenSnackBarImport={handleOpenSnackBarImport}
          autoRefreshOnImport={autoRefreshOnImport}
          permissionToImportFolder={permissionToImportFolder}
        />
      )}
      {collabaratorModal && (
        <CollabaratorFile
          openmodal={collabaratorModal}
          handleCloseModal={handleCollabaratorModalOpen}
          folder_id={folder_id}
          handleCloseModalRefresh={handleCollabaratorModalOpen}
          parent_folder={parent_folder}
          setImportStatus={setImportStatus}
          handleOpenSnackBarAssigned={handleOpenSnackBarAssigned}
          autoRefreshOnImport={autoRefreshOnImport}
          permissionToImportFolder={permissionToImportFolder}
          permissions={permissions}
          setPermissions={setPermissions}
          showSnackbar={showSnackbar}
          owner={owner}
          userProfile={profile}
        />
      )}
      {paginationModal ?
        <Popup
          open={paginationModal}
          onClose={handlePaginateModalOpen}
          position="center center"
          contentStyle={{width:'75%',padding:'20px',}}
        >
          <PaginationChildModal
            title={'Paginate'}
            subTitle={'Automatically number pages for properly formatted court filings, briefs, and exhibits.'}
            handleStamp_stamp={handleStamp_stamp}
            handleStamp_paginate_from_1={handleStamp_paginate_from_1}
            handleStamp_continuous_per_bundle={handleStamp_continuous_per_bundle}
            handleStamp_continuous_across_all_bundles={handleStamp_continuous_across_all_bundles}
          />
        </Popup>
       : null}
      {openModalforImport && (
        <ImportModal 
          openModal={openModalforImport}
          handleCloseModal={handleCloseImportModal}
          parent_folder={parent_folder}
          setImportStatus={setImportStatus}
          handleOpenSnackBarImport={handleOpenSnackBarImport}
          autoRefreshOnImport={autoRefreshOnImport}
          permissionToImportFolder={permissionToImportFolder}
        />
      )}
      <POPUP
        opener={OCRPopupOpener}
        onClose={handleCloseOnOCR}
        heading="OCRable files:"
        message={OCRableFiles?.ocrable_files ? OCRableFileRow(OCRableFiles?.ocrable_files) : <CircularCenteredLoader/>}
        mainButton="PROCEED"
        onMainButton={handleProceedOCR}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={snackBarForImport}
        onClose={handleCloseSnackBarImport}
      >
        <FilledAlert onClose={handleCloseSnackBarImport} severity="info">
          Import is in progress. Please wait for 5-10 minutes
        </FilledAlert>
      </Snackbar>
      {snackBarForAssigned && (
        <DonePopupInvite 
          opener={snackBarForAssigned}
          onClose={handleCloseSnackBarAssigned}
          heading={'Collaborator added successfully'}
          content={'Discover the perfect environment for your creativity and productivity.'}
        />
      )}
      {/* <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        open={snackBarForAssigned}
        onClose={handleCloseSnackBarAssigned}
      >
        <FilledAlert onClose={handleCloseSnackBarAssigned} severity="success">
          Collaborator Added
        </FilledAlert>
      </Snackbar> */}
      <DonePopup
        opener={openDonePopup}
        onClose={handleCloseDonePopup}
        text="All files and folders are paginated!"
      />
      <CustomizedDialogs
        open={isUploadFileMenuOpen}
        handleClose={onCloseUploadFileMenu}
        title={'Upload or Import'}
      >
        <Grid container spacing={2} justifyContent="center">
          {
            <Grid item>
              <Button
                aria-controls="upload-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={`${classes.uploadbtn} ${classes.menuwidth}`}
                endIcon={<KeyboardArrowDownIcon />}
                style={ ((isStampFolderButtonDisabled || paginationStatus === 3) && ((stampType === 3 && allRoutesLength > 1) ||
                        (stampType === 4 && allRoutesLength > 0 && (rootFolder.pagination_end_date > parent_folder.created)))) ||
                        (paginationStatus === 1) ?
                        {display:'none'} : null
                }
              >
                <Typography className={classes.uploadbtntext}>Upload</Typography>
              </Button>
            </Grid>
          }
          {
            <Grid item>
              <Button
                disabled={importStatus === "in_progress"}
                onClick={handleOpenImportModal}
                className={`${classes.uploadbtn} ${classes.menuwidth}`}
                style={
                  ((isStampFolderButtonDisabled || paginationStatus === 3) && ((stampType === 3 && allRoutesLength > 1) ||
                  (stampType === 4 && allRoutesLength > 0 && (rootFolder.pagination_end_date > parent_folder.created)))) ||
                  (paginationStatus === 1) ?
                  {display:'none'} : null
                }
              >
                <Typography className={classes.uploadbtntext} style={importStatus === "in_progress" ? {color: 'white'} : null}>
                  {importStatus === "in_progress" ? 'Importing...' : 'Import'}
                </Typography>
              </Button>
            </Grid>
          }
          <MenusPopover
            id= 'upload-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            menus={uploadButtonMenus}
          />
        </Grid>
      </CustomizedDialogs>
    </Box>
  );
}
