import React, { useState } from 'react';
import {makeStyles } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const useStyles = makeStyles((theme) => ({
    ellipsisContainer: {
        position: 'absolute',
        zIndex: 1,
    }
}));


function Ellipsis({
    isThreeDotsClicked,
    activeEllipsisColor = "#00B5CE",
    containerClassName,
    ariaLabel,
    children,
    threeDotsRef,
    onEllipsisClick,
}) {
    const classes = useStyles();
    const [menuPosition, setMenuPosition] = useState({});

    function getPosition(position) {
        if (position === "unset") {
            return position;
        }

        return position + "px";
    }

    function handleEllipsisClick(e) {
        let topPosition = e.clientY;
        let leftPosition= e.clientX;
        let rightPosition= "unset";
        let bottomPosition= "unset";

        const windowWidth= Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        const windowHeight= Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        const menuHeight= (document.getElementById('menuContainer') || {}).offsetHeight || 170;
        const menuWidth= (document.getElementById('menuContainer') || {}).offsetWidth || 160;

        if (!(parseInt(leftPosition.toString()) + menuWidth <= windowWidth)) {
            leftPosition = "unset";
            rightPosition = 5;
        }

        if (!(parseInt(topPosition.toString()) + menuHeight <= windowHeight)) {
            topPosition = "unset";
            bottomPosition = 5;
        }

        setMenuPosition({
            topPosition: getPosition(topPosition),
            leftPosition: getPosition(leftPosition),
            rightPosition: getPosition(rightPosition),
            bottomPosition: getPosition(bottomPosition),
        });

        onEllipsisClick && onEllipsisClick(e);
    }

    return (
        <div className={containerClassName}>
            <MoreHorizIcon
                onClick={handleEllipsisClick}
                style={{cursor:'pointer'}}
            />
            {
                isThreeDotsClicked ?
                    <div
                        style={{
                            "--topPosition": menuPosition.topPosition,
                            "--leftPosition": menuPosition.leftPosition,
                            "--rightPosition": menuPosition.rightPosition,
                            "--bottomPosition": menuPosition.bottomPosition,
                        }}
                        ref={threeDotsRef}
                        id="menuContainer"
                        className={classes.ellipsisContainer}
                        >
                        {children}
                    </div>
                    : null
            }
        </div>
    );
}

export default Ellipsis;