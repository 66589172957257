import React from 'react';
import { Drawing } from './editing/Drawing';
import { Text } from './editing/Text';
import { EditorText } from './editing/EditorText';
import { SignText } from './editing/SignText';

const AttachmentTypes =  {
    IMAGE : 'image',
    DRAWING : 'drawing',
    TEXT : 'text',
    EDITOR : 'editorText',
    SIGN_Text: 'signText',
}

export const Attachments= ({
  attachments,
  pdfName,
  pageDimensions,
  removeAttachment,
  updateAttachment,
  setDeleteIndex,
  deleteIndex,
  editComment,
}) => {
  const handleAttachmentUpdate = (index) => (
    attachment
  ) => updateAttachment(index, attachment);
  return attachments ? (
    <>
      {attachments.length
        ? attachments.map((attachment, index) => {
            const key = `${pdfName}-${index}`;
            // if (attachment.type === AttachmentTypes.IMAGE) {
            //   return (
            //     <Image
            //       key={key}
            //       pageWidth={pageDimensions.width}
            //       pageHeight={pageDimensions.height}
            //       removeImage={() => removeAttachment(index)}
            //       updateImageAttachment={handleAttachmentUpdate(index)}
            //       {...(attachment)}
            //     />
            //   );
            // }

            if (attachment.type === AttachmentTypes.DRAWING) {
              return (
                <Drawing
                  key={key}
                  pageWidth={pageDimensions.width}
                  pageHeight={pageDimensions.height}
                  removeDrawing={() => removeAttachment(index)}
                  updateDrawingAttachment={handleAttachmentUpdate(index)}
                  setDeleteIndex={() => setDeleteIndex(index)}
                  deleteIndex={deleteIndex}
                  index={index}
                  {...(attachment)}
                />
              );
            }

            if (attachment.type === AttachmentTypes.TEXT) {
              return (
                <Text
                  key={key}
                  pageWidth={pageDimensions.width}
                  pageHeight={pageDimensions.height}
                  updateTextAttachment={handleAttachmentUpdate(index)}
                  removeText={() => removeAttachment(index)}
                  setDeleteIndex={() => setDeleteIndex(index)}
                  deleteIndex={deleteIndex}
                  index={index}
                  editComment={editComment}
                  {...(attachment)}
                />
              );
            }
            if (attachment.type === AttachmentTypes.EDITOR) {
              return (
                <EditorText
                  key={key}
                  pageWidth={pageDimensions.width}
                  pageHeight={pageDimensions.height}
                  updateTextAttachment={handleAttachmentUpdate(index)}
                  removeHyperlink={() => removeAttachment(index)}
                  setDeleteIndex={() => setDeleteIndex(index)}
                  deleteIndex={deleteIndex}
                  index={index}
                  {...(attachment)}
                />
              );
            }
            if (attachment.type === AttachmentTypes.SIGN_Text) {
              return (
                <SignText
                  key={key}
                  pageWidth={pageDimensions.width}
                  pageHeight={pageDimensions.height}
                  updateTextAttachment={handleAttachmentUpdate(index)}
                  removeText={() => removeAttachment(index)}
                  setDeleteIndex={() => setDeleteIndex(index)}
                  deleteIndex={deleteIndex}
                  index={index}
                  {...(attachment)}
                />
              );
            }
            return null;
          })
        : null}
    </>
  ) : null;
};
