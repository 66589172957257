import React from "react";
import { useSnackbar } from "contexts/SnackbarContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import SuccessIcon from '../../images/SuccessIcon.svg';
import InfoIcon from '../../images/InfoIcon.svg';
import WarningIcon from '../../images/WarningIcon.svg';
import ErrorIcon from '../../images/ErrorIcon.svg';
import { Grid, IconButton, Typography, Box } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import DoneIcon from '../../images/TickWithGreenBackground.svg';

export function Alert(props) {
  return <MuiAlert elevation={6} variant="standard" {...props} />;
}

function SnackbarAlert() {
  const { open, message, severity, title, isDoneSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    closeSnackbar();
  };

  const styles = {
    success: {
      boxShadow: 'none',  
      borderRadius: 0,
      textWrap: 'wrap',
      color: '#696871',
      border: '1px solid #C6E4CA',
      background: '#F1F8F4',
    },
    info: {
      boxShadow: 'none',
      borderRadius: 0,
      textWrap: 'wrap',
      color: '#696871',
      border: '1px solid #AAC6ED',
      background: '#E7EFFA',
    },
    warning: {
      boxShadow: 'none',
      borderRadius: 0,
      textWrap: 'wrap',
      color: '#696871',
      border: '1px solid #F3DAAB',
      background: '#FEF8EB',
    },
    error: {
      boxShadow: 'none',
      borderRadius: 0,
      textWrap: 'wrap',
      color: '#696871',
      border: '1px solid #EEC6BA',
      background: '#FBEFEB',
    }
  }
  
  const iconMapping= { 
    success: <img src={SuccessIcon} alt="success" style={{height:48,width:48}}/>,
    info: <img src={InfoIcon} alt="info" style={{height:48,width:48}}/>,
    warning: <img src={WarningIcon} alt="warning" style={{height:48,width:48}} />,
    error: <img src={ErrorIcon} alt="error" style={{height:48,width:48}}/>,

  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      autoHideDuration={5000}
    >
      <pre>
        {!isDoneSnackbar ? (
          <Alert onClose={handleClose} severity={severity} style={styles[severity]} iconMapping={iconMapping}>
            {title && (
              <AlertTitle style={{color:'black',fontFamily:'Satoshi-Bold'}}>{title}</AlertTitle>
            )}
            {message}
          </Alert>
        ):(
          <Box style={{background:'white',position:'relative',width:'22.75rem',boxShadow:'2px 4px 20px 4px rgba(0, 0, 0, 0.12)'}}>
            <Box style={{padding:16,borderBottom:'1px solid #E5E5E5'}}>
              <IconButton style={{position:'absolute',top:8,right:8}} onClick={handleClose}><Close/></IconButton>
              <Typography style={{color:'black',fontFamily:'Satoshi-Bold',fontSize:'1.25rem'}}>{title}</Typography>
            </Box>
            <Box style={{padding:16}}>
              <Grid container style={{width:'18rem',margin:'auto'}}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <img src={DoneIcon} alt="done" />
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <Typography style={{color:'black',fontFamily:'Satoshi-Bold',fontSize:'1.5rem'}}>All done!</Typography>
                  <Typography style={{color:'black',fontSize:'0.9rem'}}>{message}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </pre>
    </Snackbar>
  );
}

export default SnackbarAlert;
