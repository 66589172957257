import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { getProfiles } from "slices/profileSlice";
import DetailsForm from "./DetailsForm";
import Invite from "./Invite";
import { Button, Snackbar } from "@material-ui/core";
import FilledAlert from "shared/FilledAlert";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";

function InviteContainer() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [step, setStep] = useState(1);
  // 1 - list of users page
  // 2 - enter details page
  const [selectedProfile, setSelectedProfile] = useState({});

  const [snackBarForAssigned, setsnackBarForAssigned] = useState(false);
  const handleOpenSnackBarAssigned = () => {
    setsnackBarForAssigned(true);
  };
  const handleCloseSnackBarAssigned = () => {
    setsnackBarForAssigned(false);
  };
  const [snackBarForError, setsnackBarForError] = useState(false);
  const [snackText, setSnackText] = useState("");
  const handleOpenSnackBarError = () => {
    setsnackBarForError(true);
  };
  const handleCloseSnackBarError = () => {
    setsnackBarForError(false);
  };

  const refresh = () => {
    setLoading(true);
    getProfiles().then((data) => {
      setProfiles(data);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    refresh();
  }, []);

  const invProps = {
    profiles,
    setStep,
    setSelectedProfile,
    selectedProfile,
  };

  const DetailsProps = {
    profileData: selectedProfile,
    empty: !selectedProfile?.id ? true : false,
    setStep,
    refresh,
    handleOpenSnackBarAssigned,
    handleOpenSnackBarError,
    setSnackText,
  };

  return (
    <>
      <Button
        onClick={() => {
          history.push("/");
        }}
        // className={classes.backbtn}
        startIcon={<ArrowBackIosIcon />}
      >
        Back
      </Button>
      {loading ? (
        <CircularCenteredLoader />
      ) : step == 1 ? (
        <Invite {...invProps} />
      ) : (
        <DetailsForm {...DetailsProps} />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={snackBarForAssigned}
        onClose={handleCloseSnackBarAssigned}
      >
        <FilledAlert onClose={handleCloseSnackBarAssigned} severity="success">
          Added
        </FilledAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={snackBarForError}
        onClose={handleCloseSnackBarError}
      >
        <FilledAlert onClose={handleCloseSnackBarError} severity="error">
          {snackText}
        </FilledAlert>
      </Snackbar>
    </>
  );
}

export default InviteContainer;
