import React from "react";
import { Box, Modal, Typography, IconButton } from "@material-ui/core";
import TickIcon from "../../images/TickWithBlueBackground.svg";
import Popup from "reactjs-popup";
import Close from "@material-ui/icons/Close";

export default function DonePopupInvite({ opener, onClose, heading, content }) {
  React.useEffect(() => {
    if (opener) {
      setTimeout(() => {
        onClose();
      }, 4000);
    }
  }, [opener]);
  return (
    <Popup
      open={opener}
      onClose={onClose}
      position="center center"
      modal
      nested
      contentStyle={{
        background: "white",
        width: "29.25rem",
				position:'relative',
				backgroundColor: 'white',
      }}
      overlayStyle={{ background: "rgba(51, 51, 51, 0.45)" }}
    >
      <Box style={{ padding: "1.3rem 2rem", textAlign: "center" }}>
        <IconButton aria-label="close" style={{position:'absolute', top:8, right:8, color:'#B0B7C3'}} onClick={onClose} >
          <Close />
        </IconButton>
				<Box style={{height:'5rem'}} />
        <Box>
					<img src={TickIcon} alt="done" />
				</Box>
				<Box style={{height:'2.25rem'}} />
				<Typography style={{color:'black',fontFamily:'Satoshi-Bold',fontSize:'1.5rem',lineHeight:'1.875rem'}}>
					{heading || 'Successfull'}
				</Typography>
				{content && (
					<Typography style={{color:'#696871',fontSize:'0.9rem'}}>
						{content}
					</Typography>
				)}
				<Box style={{height:'5rem'}} />
      </Box>
    </Popup>
  );
}
