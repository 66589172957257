import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    fontSize: '2rem',
    fontWeight: 700,
  },
  content: {
    color: 'white',
    fontSize: '1.25rem',
    lineHeight: '1.93rem',
    fontWeight: 500,
  },
  CarouselDots_Container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    left: '22%',
    bottom: '5%',
  },
  dot: {
    fontSize: 'small',
    color: '#888888',
    cursor: 'pointer',
  },
  activeDot: {
    fontSize: 'medium',
    color: 'white',
    cursor: 'pointer',
  }
}))

export default function CustomCarousel({GraphicsCollection,style,className,delay}){
  const classes = useStyles();
  const carouselRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, 
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (carouselRef.current) {
      observer.observe(carouselRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);


  useEffect(()=>{
    let timer;
    if (isVisible) {
      timer = setTimeout(
        () => {
          setActiveSlide((prevSlide) =>
            prevSlide === GraphicsCollection.length - 1 ? 0 : prevSlide + 1
          )
        },
        delay || 6000
      );
    }

    return () => {
      clearTimeout(timer);
    };
  },[isVisible, activeSlide])

  const CarouselDots = (number_of_dots) => {
    const arr = [];
    for(let i=0 ; i<number_of_dots ; i++){
      const activeDot = activeSlide === i;
      arr.push(<Box onClick={()=>{setActiveSlide(i)}} key={i}><FiberManualRecordIcon className={activeDot ? classes.activeDot : classes.dot}/></Box>);
    }
    return arr;
  }

  return (
    <Box ref={carouselRef} style={style} className={className}>
      <Box>
        <Typography className={classes.title}>
          {GraphicsCollection[activeSlide].title}
        </Typography>
        <Typography className={classes.content} dangerouslySetInnerHTML={{ __html: GraphicsCollection[activeSlide].content }}>
        </Typography>
      </Box>
      <Box style={{height:'2rem'}}></Box>
      <Box style={{width:'30rem',margin:'auto'}}>
        <img src={GraphicsCollection[activeSlide].image_src} alt={activeSlide} width='100%'></img>
      </Box>
      <Box style={{height:'2rem'}}></Box>
      <Box className={classes.CarouselDots_Container}>
        {CarouselDots(GraphicsCollection.length)}
      </Box>
    </Box>
  )
}