import {
    Box,
    Grid,
    makeStyles,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
  } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {useParams} from 'react-router-dom';
import debounce from "lodash/debounce";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { GetAllowedMembersForWorkspace, RemoveCollaborator, getWorkspaces } from "../api/FileApi";
import CustomizedDialogs from "features/CustomisedDialogbox/CustomisedDialogbox";
import { useFormik } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormattedMessage from "features/common/TranslatedMessage";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createProfileIfNot } from "slices/profileSlice";
import { ERROR, ERROR_TITLE, SUCCESS, SUCCESS_TITLE, WARNING, WARNING_TITLE } from "constants/appConstants";
import { checkUserIsOwnerOrNot } from "utils/checkUserIsOwnerOrNot";

const DetailsSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
  firstName: Yup.string().required("First name is required"),
  password: Yup.string()
      .min(6, "Password Too Short!")
      .max(20, "Password Too Long!")
      .required("Password Required"),
  confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password Required"),
});

const NewCollabarator = ({
    profileData: {
      user: { email = "", first_name = "", last_name = ""} = {},
    }, 
  onSubmitNewCollabarator,
  empty: profileEmpty
}) => {
  // const [phoneError, setPhoneError] = useState();
  // const onPhoneChange = (e) => {
  //   setPhoneError();
  //   validatePhone(e.target.value);
  //   formik.setFieldValue("phoneNumber", e.target.value);
  // };
  // const validatePhone = (v) => {
  //   let ph = v;
  //   let countspace = (ph.match(/ /g) || []).length;
  //   if (countspace > 0) {
  //     if (countspace > 1) {
  //       setPhoneError("Invalid Mobile Number. Please only enter numbers.");
  //       return false;
  //     } else {
  //       if (/^\d+$/.test(ph.split(" ")[1])) {
  //         return true;
  //       } else {
  //         setPhoneError("Invalid Mobile Number. Please only enter numbers.");
  //         return false;
  //       }
  //     }
  //   } else {
  //     if (/^\d+$/.test(ph)) {
  //       return true;
  //     } else {
  //       setPhoneError("Invalid Mobile Number. Please only enter numbers.");
  //       return false;
  //     }
  //   }
  // };
  
  const formik = useFormik({
    initialValues: {
      email: email,
      firstName: `${first_name} ${last_name}`,
      password: "Test@123",
      confirmPassword: "Test@123",
    },
    onSubmit: (values) => {
      const isFullname = values?.firstName.trim()?.split(' ')?.length > 1;
      if(!isFullname){
        formik.setFieldError('firstName','Full Name Required');
        return;
      }
      onSubmitNewCollabarator(values);
    },
    validationSchema: DetailsSchema,
    validateOnBlur: false,
  });

    return(
      (
        <Box>
           <form onSubmit={formik.handleSubmit}>
            <TextField
                variant="outlined"
                margin="normal"
                value={formik.values.email}
                required
                fullWidth
                id="email"
                label={<FormattedMessage id="user.email" />}
                name="email"
                autoComplete="email"
                autoFocus
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={!profileEmpty}
            />
            {formik.errors.email && formik.touched.email && (
                <Alert severity="error">{formik.errors.email}</Alert>
            )}
            <TextField
                variant="outlined"
                margin="normal"
                value={formik.values.firstName}
                required
                fullWidth
                id="firstName"
                label='Name'
                name="firstName"
                autoComplete="firstName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={!profileEmpty}
            />
            {formik.errors.firstName && formik.touched.firstName && (
                <Alert severity="error">{formik.errors.firstName}</Alert>
            )}
            {/* <TextField
                variant="outlined"
                margin="normal"
                value={formik.values.lastName}
                required
                fullWidth
                id="lastName"
                label={<FormattedMessage id="user.lastName" />}
                name="lastName"
                autoComplete="lastName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={!profileEmpty}
            />
            {formik.errors.lastName && formik.touched.lastName && (
                <Alert severity="error">{formik.errors.lastName}</Alert>
            )}

            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="phoneNumber"
                label={<FormattedMessage id="user.phoneNumber" />}
                id="phoneNumber"
                value={formik.values.phoneNumber}
                onBlur={onPhoneChange}
                onChange={onPhoneChange}
                disabled={!profileEmpty || !!phone_number}
                />
                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                    <Alert severity="error">{formik.errors.phoneNumber}</Alert>
                )}
            {phoneError && <Alert severity="error">{phoneError}</Alert>} */}

            <Box height="1rem"></Box>
            <Box display="flex" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Invite
                </Button>
              </Box>
            </form>
        </Box>
      )  
    )
}
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      width: "50%",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      outline: "none",
      borderRadius: "0.4rem",
      overflowY: "scroll",
      position: "absolute",
      // maxHeight:'20rem',
      left: "50%",
      top: "50%",
      "-webkit-transform": "translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("sm")]: {
        padding: "1rem",
      },
    },
    firstText: {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: `'Inter',sans-serif`,
      color: "#000000",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    selected: {
      padding: "0.3rem 1.3rem",
      cursor: 'pointer',
      fontSize: '1.3rem',
      fontFamily: 'Satoshi-Bold',
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    unselected: {
      fontSize: '1.3rem',
      padding: "0.3rem 1.3rem",
      cursor: "pointer",
    },
    choiceText: {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: `'Inter',sans-serif`,
      color: "#4F4F4F",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    fieldsText: {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: `'Inter',sans-serif`,
      color: "#000000",
      marginTop: "0.7rem",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0.3rem",
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    box: {
      padding: "0.6rem 1rem",
      [theme.breakpoints.down("xs")]: {
        padding: "0.3rem 0.5rem",
      },
    },
    container: {
      padding: "0 2rem",
      [theme.breakpoints.down("xs")]: {
        padding: "0",
      },
    },
    headlineAccess: {
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    accessTitle: {
        color: '#000',
        fontSize: '16px',
        fontWeight: '700',
    },
    addNewTitle: {
        color: '#4A3BFF',
        fontSize: '16px',
        fontWeight: '700',
        cursor: 'pointer',
    },
    nameWrap: {
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        width:' 48px',
        height: '48px',
        color: '#FFF',
        fontSize: '16px'
      },
    collabWrap: {
        display: 'flex'
    },
    collabContainer: {
        display: 'flex',
        marginTop: '20px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    collabName: {
        marginLeft: '10px',

    },
    collabEmail: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#808080'
    },
    collabFullName: {
        fontSize: '16px',
        fontWeight: 700,
    }
  }));
  
  export default function CollabaratorFile({
    openmodal,
    handleCloseModal,
    folder_id,
    handleCloseModalRefresh,
    parent_folder,
    setImportStatus,
    handleOpenSnackBarAssigned,
    autoRefreshOnImport,
    permissionToImportFolder,
    permissions,
    setPermissions,
    showSnackbar,
    owner,
    userProfile,
  }) {
    const classes = useStyles();
    let { workspace_id } = useParams();
    workspace_id= parseInt(workspace_id);
    const isUserOwnerOfWorkspace = checkUserIsOwnerOrNot(owner,userProfile);

    const [collab, setCollab] = useState('');
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState({});
    
    const [createNewCollabarator, setCreateNewCollabarator] = useState(false);

    const handleSelectCollabarator = (event) => {
        setCollab(event.target.value);
    }
    const dispatch = useDispatch();
    const documentSet = useSelector((state) => state.documentSet, shallowEqual);
  
    const onSubmitNewCollabarator = debounce(async (values) => {
      const fullName = values.firstName.trim();
      var first_name = fullName.substring(0, fullName.lastIndexOf(' '));
      var last_name = fullName.substring(fullName.lastIndexOf(' ') + 1, fullName.length);
      const profileBody = {
        email: values.email,
        firstName: first_name,
        lastName: last_name,
        password: values.password,
        doc_set_id: documentSet?.documentSet?.results?.[0]?.id,
        workspace_id: workspace_id,
        inviter_name: `${userProfile?.user?.first_name} ${userProfile?.user?.last_name}`,
      };
      if(values.email === owner.user.email || permissions.filter((member)=> values.email === member.user.email).length !== 0){
        showSnackbar('User already exists in this workspace.',WARNING,WARNING_TITLE);
        return;
      }
      try {
        dispatch(createProfileIfNot(profileBody))
          .then((resp) => {
            console.log("resp", resp);
            handleOpenSnackBarAssigned();
            refresh();
            handleCloseModal();
            // setStep(1);
          })
          .catch((err) => {
            console.error("err.response.data", err?.response?.data);
            if (err?.response?.status === 409 || err?.response?.status === 424 || err?.response?.status === 400) {
              showSnackbar(err?.response?.data?.error,ERROR,ERROR_TITLE);
            //   setSnackText(err?.response?.data?.error);
            //   handleOpenSnackBarError();
            //   setStep(1);
            }
          });
      } catch (err) {
        console.log(err);
      }
    }, 1000);

    const onSubmitSearchCollabarator = () => {
      setCreateNewCollabarator(true);
    }

    const refresh = () => {
        //setLoading(true);
        GetAllowedMembersForWorkspace(workspace_id).then((res) => {
            setProfiles(res.data);
            //setLoading(false);
        });
      getWorkspaces()
      .then((response)=>{
        setPermissions(response?.data?.results?.filter((workspace)=> workspace.id===workspace_id)?.[0]?.members);
      })
    };

    React.useEffect(() => {
        refresh();
    }, []);
  
    const COLORS_ARRAY = ['#3498db', '#e74c3c', '#2ecc71', '#f1c40f', '#9b59b6'];

    function handleCreateNewCollabarator(){
        setCreateNewCollabarator(true);
    }

    const removeCollaborator = (index) => {
      const data = {
        profile_id: permissions[index].id,
        "workspace_id" : workspace_id,
      }
      RemoveCollaborator(data)
      .then((res)=>{
        console.log(res);
        if(res.status === 200){
          permissions.splice(index,1);
          setPermissions([...permissions]); 
          showSnackbar('Collaborator removed successfully!',SUCCESS,SUCCESS_TITLE);
        }
      })
      .catch((err)=>{
        showSnackbar(err?.response?.data?.error,WARNING,WARNING_TITLE);
      })
    }

    const body = (
      <Box>
        <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <Autocomplete
                    id="combo-box-demo"
                    fullWidth
                    autoComplete={true}
                    options={profiles}
                    onChange={(event, value) => {
                      if(value){
                        setSelectedProfile(value);
                      }
                      else{
                        setSelectedProfile({})
                      }
                    }}
                    onInputChange={(event) => {}}
                    getOptionLabel={(option) => option.user.first_name}
                    renderOption={(option, state) => {
                        return (
                        <React.Fragment>
                            {option.user.first_name} {option.user.last_name}
                            <Box display="inline-block" width="1rem"></Box>
                            <Typography variant="caption">
                            {option.user.email}
                            </Typography>
                        </React.Fragment>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label={<FormattedMessage id="pages.intro.search" />}
                        variant="outlined"
                        />
                    )}
                    />
              </FormControl>
            </Grid>
        </Grid>
        <Grid className={classes.headlineAccess}>
            <Typography className={classes.accessTitle}>People With Access</Typography>
            <Typography onClick={handleCreateNewCollabarator} className={classes.addNewTitle}>+ Add New</Typography>
        </Grid>
        <Grid>
          <div className={classes.collabContainer}>
            <div style={{display:'flex'}}>
              <div className={classes.nameWrap} style={{ backgroundColor: '#ffab00' }}>
                <div>{`${owner.user.first_name.charAt(0)}${owner.user.last_name.charAt(0)}`}</div>
              </div>
              <div className={classes.collabName}>
                <div className={classes.collabFullName}>{owner?.user?.first_name} {owner?.user?.last_name}</div>
                <div className={classes.collabEmail}>{owner?.user?.email}</div>
              </div>
            </div>
            <div>
              <div style={{color:'#2BB879',fontFamily:'Satoshi-Bold',fontSize:'0.9rem',background:'rgba(43, 184, 121, 0.10)',padding:'0.38rem 0.75rem'}}>Owner</div>
            </div>
          </div>
            {permissions?.map((item, index) => {
                const { first_name, last_name, email } = item.user;
                const firstNameInitial = first_name.charAt(0);
                const lastNameInitial = last_name.charAt(0);

                const colorIndex = index % COLORS_ARRAY.length;
                return (
                  <>
                    <div className={classes.collabContainer}>
                      <div style={{display:'flex'}}>
                        <div key={index} className={classes.nameWrap} style={{ backgroundColor: COLORS_ARRAY[colorIndex] }}>
                            <div>{firstNameInitial}</div>
                            <div>{lastNameInitial}</div>
                        </div>
                        <div className={classes.collabName}>
                            <div className={classes.collabFullName}>{first_name} {last_name}</div>
                            <div className={classes.collabEmail}>{email}</div>
                        </div>
                      </div>
                      <div>
                        { isUserOwnerOfWorkspace && (
                          <div style={{color:'#808080',fontSize:'0.9rem',cursor:'pointer',padding:'0.38rem 0.75rem'}} onClick={()=>removeCollaborator(index)}>Remove</div>
                        )}
                      </div>
                    </div>
                  </>
                );
            })}
        </Grid>
      </Box>
    ); 
  
    return (
        <>
        {createNewCollabarator ?
            <CustomizedDialogs
                open={openmodal}
                handleClose={handleCloseModal}
                title={'Add Collaborators'}
                subTitle={'Collaborators on this case will have access to all the files. They will be able to add, edit, modify and delete files.'}
            >
                <NewCollabarator
                  profileData={selectedProfile}
                  onSubmitNewCollabarator={onSubmitNewCollabarator}
                  empty={!selectedProfile?.id ? true : false}
                />
            </CustomizedDialogs>
            :
            <CustomizedDialogs
                open={openmodal}
                handleClose={handleCloseModal}
                title={'Add Collaborators'}
                subTitle={'Invite your team to review collaborate on this workspace'}
                buttonText={'Search'}
                onSubmit={onSubmitSearchCollabarator}
                isSubmitButtonDisabled={loading}
                disableBackDropClick={loading}
            >
                {body}
            </CustomizedDialogs>
        }
        </>
        
    );
  }
  