import {

    makeStyles,
    IconButton,
    useMediaQuery,
		Typography
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import FolderIcon from "images/folderyellow.svg";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PdfViewingModal } from "./editModals/PdfViewingModal";
import {DownloadFile} from "../api/FileApi";
import { StyledSkeleton } from "features/LoadingScreen/LoadingScreenDashboard";
import { useSnackbar } from "contexts/SnackbarContext";
import PdfFileIcon from "images/fileIcon.svg";
import WordFileIcon from "images/word_file_icon.png";
import ExcelFileIcon from "images/excel_file_icon.png";
import ImageFileIcon from "images/image_file_icon.png";
import PowerpointFileIcon from "images/powerpoint_file_icon.png";
import TxtFileIcon from "images/txt_file_icon.png";
import { Close } from "@material-ui/icons";
import CustomisedTooltip from "features/CustomisedToolTip/CustomisedToolTip";
import { useWorkspace } from "contexts/WorkspaceContext";

const useStyles = makeStyles((theme) => ({
    fileExplorerContainer: {
			padding: '16px 0 0 0',
			borderRight: `1px solid ${theme.palette.border}`,
			backgroundColor: 'white',
			height: '97vh',
			overflowY: 'auto',
			zIndex: 2,
			[theme.breakpoints.down('sm')]: {
				width: '22rem',
			},
			[theme.breakpoints.down('xs')]: {
				width: '15rem',
			}
    },
    fileName: {
        // maxWidth: '150px', // Adjust the max width as needed
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
				'&:hover': {
					textDecoration: 'underline',
				}
    },
    mobileMenuButton: {
        display: 'none', // Initially hidden on larger screens
        [theme.breakpoints.down('sm')]: {
            display: 'block', // Display on screens smaller than 'sm' breakpoint
						zIndex: 2,
        },
    },
    mobileMenu: {
        display: 'none', // Initially hidden on larger screens
        [theme.breakpoints.down('sm')]: {
            display: 'block', // Display on screens smaller than 'sm' breakpoint
						zIndex: 2,
						position:'relative',
        },
    },
    folderFileView: {
			display: 'flex',
			height: '1.5rem',
			padding: '0.62rem',
			alignItems: 'center',
			gap: '0.75rem',
			fontSize: '1rem',
			cursor: 'pointer',
			"&:hover": {
				background: '#F6F5FF',
				// color: '#fff',
			}
    },
    level1: {
        marginLeft: '20px',
    },
    level2: {
        marginLeft: '20px' 
    },
    fileView: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
				padding: '0.75rem 0.62rem',
        maxWidth: '150px', // Adjust the max width as needed
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        marginLeft: '40px',
    },
    folderLine: {
        position: 'absolute',
        left: '10px', // Adjust the left position as needed
        top: '0',
        width: '1px', // Adjust the width as needed
        height: '100%',
        background: 'gray', // Adjust the color as needed
        zIndex: '-1', // Place the lines behind the folder icons
      },
    
      curvedLine: {
        position: 'absolute',
        left: '10px', // Adjust the left position as needed
        top: '0',
        width: '20px', // Adjust the width as needed
        height: '100%',
        borderBottomLeftRadius: '50%', // Create a curved effect
        borderTopLeftRadius: '50%', // Create a curved effect
        background: 'gray', // Adjust the color as needed
        zIndex: '-1', // Place the lines behind the folder icons
      },
	levels: {
		marginLeft: '12px',
		borderLeft:'1.5px solid #CBCBD7',
		"&:first-child": {
			borderLeft: 'none',
      marginLeft: '16px',
		},
	}
    
}));

const Directory = ({ files, level, folderId, handleOpenFolder }) => {
    const [isExpanded, toggleExpanded] = useState(false);
    const classes = useStyles();
		const { workspace } = useWorkspace();
		const history = useHistory();
		const paginationType = workspace?.pagination_type;

    const [pdfUrl, setPdfUrl] = useState('')
    const [showPdfModal, setShowPdfModal] = useState(false);

    const folderClass = level === 1 ? classes.level1 : level === 2 ? classes.level2 : '';

		const isWorkspaceName = files?.workspace_id;

    const handlePdfIdClick = async (id) => {
        try{
            const res = await DownloadFile(id)
            setPdfUrl(res.data.url+`#page=${1}`);
            setShowPdfModal(true)
          }catch(err){
            console.log(err)
          }
    }

		function handlePdfEdit(){
			localStorage.setItem('fileName', files.name);
			history.push(`/view-pdf/${files.id}/${1}/${paginationType}`);
		}

    if (files.type === 'folder') {
        return (
            <div className={level>0 ? classes.levels : {}}>
                {/* {level > 0 && <div className={classes.folderLine}></div>} */}
                <div className={classes.folderFileView} onClick={() => toggleExpanded(!isExpanded)} style={((folderId === files.id) && level>0)?{color:'white',backgroundColor:`#4A3BFF`}:null}>
                    {isExpanded ? <ExpandMoreIcon/> : <ChevronRightIcon/>}
                    <img
                        src={FolderIcon}
                        alt="folder"
                        style={isWorkspaceName?{display:'none'}:{ width: '20px', height: '20px' }}
                    />
										<CustomisedTooltip title={files.name} enterDelay={100}>
                    	<div className={classes.fileName} style={isWorkspaceName?{fontSize:'1.2rem'}:null} onClick={()=>{handleOpenFolder(files.id)}}>{files.name}</div>
										</CustomisedTooltip>
                </div>
                {
                    isExpanded && files.children.map((item, index) => <Directory  key={index} files={item} level={level + 1} folderId={folderId} handleOpenFolder={handleOpenFolder}/>)
                }
            </div>
        )
    }
    return (
        <>
					<div className={classes.levels}>
            {/* {level > 0 && <div className={classes.curvedLine}></div>} */}
            <div className={classes.folderFileView} onClick={() => handlePdfIdClick(files.id)}>
							<img
									src={files.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? WordFileIcon 
                  : files.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? ExcelFileIcon 
                  : (files.type === "image/jpeg" || files.type === "image/png" || files.type === "image/webp") ? ImageFileIcon 
                  : files.type === "application/vnd.ms-powerpoint" ? PowerpointFileIcon 
                  : files.type === "application/pdf" ? PdfFileIcon : TxtFileIcon}
									alt="file"
									style={{ width: '20px', height: '20px' }}
							/>
							<CustomisedTooltip title={files.name}>
								<Typography className={classes.fileName}>{files.name}</Typography>
							</CustomisedTooltip>
						</div>
					</div>
					{showPdfModal &&
						<PdfViewingModal  
							open={showPdfModal} 
							dismiss={() => setShowPdfModal(false)}
							pdfUrl={pdfUrl}
							handlePdfClick={handlePdfEdit}
							showEditBtn={true}
							// disableEditBtn={!file?.is_searchable}
						/>
					}
        </>
    )
} 

const DirectorySkeleton = () => {
	return (
		<div style={{display:'grid',gap:'10px',width:'10rem',marginLeft:'16px',marginTop:'16px'}}>
			<div style={{display:'flex',width:'100%'}}>
				<ExpandMoreIcon/><StyledSkeleton variant="rect" width='8rem' height='2rem' animation='wave' />
			</div>
			<div style={{display:'flex',width:'100%',marginLeft:'15px'}} >
				<ChevronRightIcon/><StyledSkeleton variant="rect" width='8rem' height='2rem' animation='wave' />
			</div>
			<div style={{display:'flex',width:'100%',marginLeft:'15px'}}>
				<ExpandMoreIcon/><StyledSkeleton variant="rect" width='8rem' height='2rem' animation='wave' />
			</div>
			<div style={{display:'flex',width:'100%',marginLeft:'30px'}}>
				<ChevronRightIcon/><StyledSkeleton variant="rect" width='8rem' height='2rem' animation='wave' />
			</div>
			<div style={{display:'flex',width:'100%',marginLeft:'30px'}}>
				<ChevronRightIcon style={{color:'transparent'}}/><StyledSkeleton variant="rect" width='8rem' height='2rem' animation='wave' />
			</div>
		</div>
	)
}

export default function FileExplorer({filesAndFoldersData,handlePageChange}) {
    let { folder_id , workspace_id} = useParams();
    folder_id = parseInt(folder_id);
    workspace_id = parseInt(workspace_id);
    const classes = useStyles();
    const {showSnackbar} = useSnackbar();
		const [folderId,setFolderId] = useState(folder_id);
		const history = useHistory();

		useEffect(()=>{
			setFolderId(folder_id);
		},[folder_id])
    
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    // Define state for the mobile menu
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    // Toggle the mobile menu
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

		const handleOpenFolder = (id) => {
			history.push(`/folder/${id}/${workspace_id}`);
			handlePageChange(1);
		};

    return (
        <div >
            <IconButton
                className={classes.mobileMenuButton}
                onClick={toggleMobileMenu}
								style={mobileMenuOpen?{display:'none'}:{margin:'1rem auto'}}
            >
                <MenuIcon />
            </IconButton>
            
             {/* Conditionally render the mobile menu based on the state */}
             {mobileMenuOpen && filesAndFoldersData.data  && (
                <div className={`${classes.mobileMenu} ${classes.fileExplorerContainer}`}>
									<IconButton onClick={toggleMobileMenu} style={{position:'absolute',right:'2px',top:'2px',padding:'5px'}}><Close/></IconButton>
                  <Directory files={filesAndFoldersData.data} level={0} folderId={folderId} handleOpenFolder={handleOpenFolder}/>
                </div>
            )}

            {/* Conditionally render the directory based on screen size */}
            {!isSmallScreen ?
						 	filesAndFoldersData.data ? (
                <div className={classes.fileExplorerContainer}>
                    <Directory files={filesAndFoldersData.data} level={0} folderId={folderId} handleOpenFolder={handleOpenFolder}/>
                </div>
            	):(
                <DirectorySkeleton/>
            	)
							: null
						}
        </div>
    );
}
