import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";

export const initialState = {
  loading: false,
  hasErrors: false,
  cases: [],
  activeCase: {},
};

const casesSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.cases = payload.results;
      state.loading = false;
      state.hasErrors = false;
    },
    setError: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    setSucessfullCase: (state, { payload }) => {
      const index = state.cases.findIndex((cse) => cse.id === payload.id);
      if (index !== -1) {
        state.cases[index] = payload;
      } else {
        state.cases.push(payload);
      }
      state.activeCase = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setSucessfullGet: (state, { payload }) => {
      state.activeCase = payload;
      state.loading = false;
      state.hasErrors = false;
    },
  },
});

export const {
  setProgress,
  setSucessfull,
  setError,
  setSucessfullCase,
  setSucessfullGet,
} = casesSlice.actions;

export default casesSlice.reducer;

export function getCasesForPagination(pageNo) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url: `/api/casefile/?page=${pageNo}`,
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}

export function getCases(params = {}) {
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: "get",
      url: "/api/casefile/",
      params: params,
    }).then(
      (response) => {
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);
        // console.log(error.request.status);
        // console.log(error.message)
        // console.log(error.request.response)
        dispatch(setError());
      }
    );
  };
}

export function getCaseAnonymous(dispute, email) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "get",
        url: "/api/casefileanonymous/",
        params: {
          dispute__id: dispute,
          email: email,
        },
      });

      dispatch(setSucessfullGet(response.data.results[0]));
      return response.data.results[0];
    } catch (error) {
      console.log(error);
      dispatch(setError());
      throw error;
    }
  };
}

export function getCase(dispute) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "get",
        url: "/api/casefile/",
        params: {
          dispute__id: dispute,
        },
      });

      dispatch(setSucessfullGet(response.data.results[0]));
      return response.data.results[0];
    } catch (error) {
      console.log(error);
      dispatch(setError());
      throw error;
    }
  };
}

export function updateCaseAnonymous(casePayload, email) {
  console.log("casePayload before:", casePayload);
  if (
    casePayload.proposed_flow &&
    casePayload.proposed_flow === "grievance redressal"
  ) {
    casePayload.proposed_flow = "arbitration";
  }
  console.log("casePayload after:", casePayload);
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "patch",
        url: "/api/casefileanonymous/" + casePayload.id + "/",
        data: casePayload,
        params: {
          email: email,
        },
      });
      dispatch(setSucessfullCase(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function updateCase(casePayload) {
  console.log("casePayload before:", casePayload);
  if (
    casePayload.proposed_flow &&
    casePayload.proposed_flow === "grievance redressal"
  ) {
    casePayload.proposed_flow = "arbitration";
  }
  console.log("casePayload after:", casePayload);
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "patch",
        url: "/api/casefile/" + casePayload.id + "/",
        data: casePayload,
      });
      dispatch(setSucessfullCase(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function createCase(createCaseBody) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "/api/casefile/",
        data: createCaseBody,
      });

      // dispatch(setSucessfullCase(response.data));
      dispatch(getCase(createCaseBody.dispute));

      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(setError());
      throw error;
    }
  };
}

export function createCaseAnonymous(createCaseBody, email) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "/api/casefileanonymous/",
        data: createCaseBody,
        params: {
          email: email,
        },
      });

      // dispatch(setSucessfullCase(response.data));
      dispatch(getCaseAnonymous(createCaseBody.dispute, email));

      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(setError());
      throw error;
    }
  };
}
